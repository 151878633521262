<template>
  <div>
    <van-form @submit="saveFn">
      <van-field
        v-model="infoForm.userName"
        placeholder="请输入姓名"
        name="userName"
        label="姓名"
        input-align="right"
      />
      <van-field
        v-model.trim.lazy="infoForm.phone"
        name="手机"
        label="手机 (+86)"
        placeholder="手机"
        :rules="[
          {
            required: true,
            message: '请填写正确的手机号码',
            validator: phoneRequired,
          },
        ]"
        input-align="right"
      />
      <van-field
        v-model.trim.lazy="infoForm.code"
        name="手机验证码"
        label="手机验证码"
        placeholder="手机验证码"
        maxlength="6"
        input-align="right"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click="sendCode"
            :disabled="type == 4 || sendCodIng || codeCountIng"
            class="codebtn"
            :loading="sendCodIng"
            loading-text="发送中..."
          >
            <template v-if="!codeCountIng">发送验证码</template>
            <van-count-down
              style="color: #ffffff;"
              ref="countDown"
              millisecond
              :time="60000"
              :auto-start="false"
              format="ss 秒后重新获取"
              @finish="onFinish"
              v-show="codeCountIng"
            />
          </van-button>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="picker"
        v-model="sexCn"
        label="性别"
        placeholder="点击选择性别"
        @click="showSex = true"
        right-icon="arrow"
        input-align="right"
      />
      <van-popup v-model:show="showSex" position="bottom">
        <van-picker
          show-toolbar
          :columns="[
            { value: '男', zhName: '男' },
            { value: '女', zhName: '女' },
          ]"
          value-key="zhName"
          @confirm="onSexConfirm"
          @cancel="showSex = false"
        />
      </van-popup>
      <van-field
        v-model="infoForm.birthday"
        placeholder="请选择生日"
        name="birthday"
        label="生日"
        is-link
        readonly="true"
        @click="show = true"
        input-align="right" />
      <van-field
        v-model="infoForm.height"
        placeholder="请输入身高"
        name="height"
        label="身高"
        input-align="right"
      >
        <template #button>
          厘米
        </template>
      </van-field>
      <van-field
        v-model="infoForm.weight"
        placeholder="请输入体重"
        name="weight"
        label="体重"
        input-align="right"
      >
        <template #button>
          公斤
        </template>
      </van-field>
      <van-button class="submit-btn" type="primary" block round native-type="submit"
        >提交</van-button>
    </van-form>

    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="dateConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { addMember, smsCode, checkSmsCode } from "@/api/member";
import { Toast } from "vant";
import axios from "axios";
import moment from "moment";
import wx from "wx";

export default {
  name: "userInfo",
  data() {
    // 限制生日选择的最大时间，不能选择小于一周岁的日期
    const birthdayMaxDate = new Date();
    birthdayMaxDate.setFullYear(birthdayMaxDate.getFullYear()-1)

    return {
      infoForm: {
        userName: "",
        sex: "",
        birthday: "",
        height: "",
        weight: "",
        userId: "",
        phone: "",
        code: "",
        relationship: "",
      },
      show: false,
      minDate: new Date(1900, 0, 1),
      maxDate: birthdayMaxDate,
      currentDate: new Date(1960, 5, 15),
      showSex: false,
      sendCodIng: false,
      codeCountIng: false,
    };
  },
  computed: {
    currentDateStr: function() {
      let year = "";
      let month = "";
      let day = "";
      if (this.currentDate) {
        year = this.currentDate.getFullYear();
        month = this.currentDate.getMonth() + 1;
        day = this.currentDate.getDate();
      }
      return year + "/" + month + "/" + day;
    },
    sexCn: function() {
      return this.infoForm.sex
    },
    heightCheck() {
      return this.infoForm.height >= 50 && this.infoForm.height <= 300;
    },
    weightCheck() {
      return this.infoForm.weight >= 10 && this.infoForm.weight <= 300;
    },
  },
  methods: {
    dateConfirm(value) {
      this.show = false;
      this.currentDate = value;
      this.infoForm.birthday = moment(this.currentDate).format("YYYY-MM-DD");
    },
    submit() {
      axios
        .post("/api/member/updateUserInfo", this.infoForm)
        .catch(function(error) {
          console.log(error);
        });

      Toast.success({
        message: "保存成功",
        onOpened: function() {
          wx.miniProgram.navigateBack();
        },
      });
    },
    saveFn() {
      console.log("saveFn",this);
      if (!this.infoForm.userName) {
        Toast("请填写姓名");
        return;
      }
      if (
        this.infoForm.userName.replace(/[^\\u0000-\u00ff]/, "aa").length > 10
      ) {
        Toast("姓名过长");
        return;
      }

      
      if (this.infoForm.phone === "" || this.infoForm.phone === null) {
        Toast("请输入手机号");
        return;
      }

      
      if (this.infoForm.code === "" || this.infoForm.code === null) {
        Toast("请输入验证码");
        return;
      }


      if (this.infoForm.sex === "" || this.infoForm.sex === null) {
        Toast("请选择性别");
        return;
      }
      if (!this.infoForm.birthday) {
        Toast("请选择生日");
        return;
      }
      if (!this.infoForm.height) {
        Toast("请填写身高");
        return;
      }
      if (this.infoForm.height.toString().indexOf(".") != -1) {
        if (
          this.infoForm.height.toString().length -
            this.infoForm.height.toString().indexOf(".") >
          2
        ) {
          Toast("身高最多支持一位小数");
          return;
        }
      }
      if (!this.heightCheck) {
        Toast("身高信息范围阈值应在50cm~300cm之间");
        return;
      }
      if (!this.infoForm.weight) {
        Toast("请填写体重");
        return;
      }
      if (this.infoForm.weight.toString().indexOf(".") != -1) {
        if (
          this.infoForm.weight.toString().length -
            this.infoForm.weight.toString().indexOf(".") >
          2
        ) {
          Toast("体重最多支持一位小数");
          return;
        }
      }
      if (!this.weightCheck) {
        Toast("体重信息范围阈值应在10kg~300kg之间");
        return;
      }

      checkSmsCode(this.infoForm.phone,this.infoForm.code).then(data => {
          return data === 1 ? Promise.resolve(true): Promise.reject(false)
      }).then(()=>{
        addMember(this.infoForm).then((response) => {
          Toast("添加成员成功");
          wx.miniProgram.redirectTo({
            url: "/pages/member/memberList/memberList",
          });
        }).catch(response => {
            Toast("添加成员失败：" + response.msg);
        });
      },()=>{
        Toast("验证码输入错误");
      })
      
    },
    onSexConfirm(value) {
      console.log(value);
      this.infoForm.sex = value.value;
      this.showSex = false;
    },
    phoneRequired(val) {
      return val.length == 11 && val[0] == 1;
    },
    sendCode() {
      if (!this.phoneRequired(this.infoForm.phone)) {
        Toast("请填写正确的手机号码");
        return;
      }
      this.sendCodIng = true;
      smsCode(this.infoForm.phone)
        .then((res) => {
          console.log(res);
          this.sendCodIng = false;
          this.codeCountIng = true;
          setTimeout(() => {
            this.$refs.countDown.reset();
            this.$refs.countDown.start();
          }, 0);
          Toast("发送成功");
        })
        .catch((err) => {
          console.log(err);
          this.sendCodIng = false;
          Toast(err.msg || "发送失败");
        });
    },
    onFinish() {
      this.codeCountIng = false;
    },
  },
};
</script>

<style scoped lang="stylus">
/deep/ .van-field--disabled .van-field__label
  color: #646566;

.submit-btn
  width calc(100vw - 100px)
  margin 120px 50px 30px
  background-image: linear-gradient(to right, #8b64e5, #563bc8);
  border none

/deep/ .van-button--primary
  color: #fff
  background: linear-gradient(to right, #8b64e5, #563bc8)
  border: 1px solid #563bc8
</style>
