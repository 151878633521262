import request from "@/utils/request";

/**
 * 设备使用时长
 * @returns {*}
 */
export function newestRecord() {
    return request.get("/api/reportRecord/newest");
}

/**
 * 设备使用时长
 * @returns {*}
 */
export function findWatchStatus(deviceId) {
    return request.get("/api/device/findWatchStatus/" + deviceId);
}

