<template>
  <div class="myQR">
    <div class="top-div">_</div>
    <div class="cont">
      <div class="img">
        <img src="../../../assets/img/icon/user/默认头像@2x.png" />
      </div>
      <div class="username">顾问二维码</div>
      <!-- <div class="tip">{{ consultant.title }}</div> -->
      <!-- <vue-qr class="qr-img" :text="consultant.qrCode" margin="0" colorLight="#f1f1f1"></vue-qr> -->
      <img class="qr-img" :src="consultant.qrCode" alt="" srcset="">
      <div class="member">{{ consultant.detail }}</div>
    </div>
  </div>
</template>

<script>
import { getmyself } from "@/api/index";
import { consultant } from "@/api/user";
// import wx from "wx";
// import vueQr from 'vue-qr/src/packages/vue-qr.vue'

export default {
  components: {
    // vueQr,
  },
  data() {
    return {
      userInfo: {},
      consultant: {},
    };
  },
  created() {
    getmyself().then((res) => {
      if (res != null) {
      if (res.sex == '1') {
        res.sex = '男'
      }
      if (res.sex == '2') {
        res.sex = '女'
      }
        this.userInfo = res || {};
      }
    });
    consultant().then((res) => {
      if (res != null) {
        this.consultant = res || {};
      }
    });
  },
};
</script>

<style scoped lang="stylus">
.myQR
  height: 100vh
  background: linear-gradient(to top, #e0a7ee, #b3bcf7)
  .top-div
    height 0
    opacity 0

.cont
  margin: (215px + 106px) 131px 0 112px
  background: #fff
  border-radius: 35px
  .img
    box-sizing: border-box
    width 165px
    border: solid 12px #fff
    margin: 0 auto 0
    border-radius: 50%
    position relative
    top -106px
    img
      display: block
      border-radius: 50%
  .username
    margin-top calc(-106px - 9px)
    font-size 40px
    line-height: 62px
    color: #333333
  .tip
    font-size 28px
    line-height: 48px
    color: #666666
  .qr-img
    width 330px
    margin 20px 0
  .member
    padding-bottom 30px
</style>
