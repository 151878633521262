
import { Options, Vue } from 'vue-class-component';

import wx from 'wx';
import { listQuestionnaire } from '@/api/memberQuestionnaire'
import {getCurrentUserInfo, getmyself} from "@/api/index"

@Options({
    data() {
        return {
            pageStatus: {
                data: [],

                loading: false,
                finished: false,
                pageNo: 0,
                pageSize: 10,
            },
            pullRefresh: {
                loading: false,
            },
            requestParams: {
                memberId: null,
            }
        }
    },
    methods: {
        toNewUserQuestionnaire() {
            wx.miniProgram.navigateTo({
                url: '/pages/user/userQuestionnaire/userQuestionnaire?type=1&entryMode=3&memberId=' + this.requestParams.memberId,
            })
        },
        onRefresh  ()  {
            location.reload();
        },
        onLoad(){
            listQuestionnaire({
                memberId: this.requestParams.memberId,
                pageNo: this.pageStatus.pageNo,
                pageSize: this.pageStatus.pageSize,
            }).then(data => {
                Array.prototype.push.apply(this.pageStatus.data,data.content)
                if(this.pageStatus.pageNo + 1 >= data.totalPage){
                    this.pageStatus.finished = true;
                }
                this.pageStatus.pageNo++;
            },err => {
                this.tipsMessage(`加载数据异常，请稍后重试（${err}）！`)
                this.pageStatus.finished = true;
            }).finally(() => {
                this.pageStatus.loading = false;
                this.loading = false;
            })
        },
        onClick(id, item){
            console.log(id)
            if(item.type === 2){
                this.tipsMessage(`当前记录不支持查看详情！`)
                return;
            }
            wx.miniProgram.navigateTo({
                url: `/pages/user/userQuestionnaire/userQuestionnaire?id=${id}&mode=detail`, 
            })
        },
        tipsMessage(msg){
            this.$dialog.alert({
                message: msg,
                theme: 'round-button',
                confirmButtonText: '关闭',
                confirmButtonColor: 'rgb(159, 102, 204)',
                messageAlign: 'left',
            }).then(() => {
            // on close
            });
        }

    },
    created() {
        let {
            memberId,
        } = this.$route.query;

        if(memberId){
            this.requestParams.memberId = memberId;    
        }else{
            getmyself().then(data => {
                this.requestParams.memberId = data.id;                    
            })
        }

    },
    mounted(){
        const unWatch = this.$watch(()=> this.requestParams.memberId ,(newVal, oldVal)=>{
            if(newVal){
                this.$refs['list'].check();
                this.$nextTick(function(){
                    unWatch();
                    console.log("取消[requestParams.memberId]监听器成功");
                })
            }
        },{
            immediate: true,
        })
    }
})
export default class UserQuestionnaireList extends Vue {

}

