
import { Options, Vue } from 'vue-class-component';
import {getArticleInfo, getCurrentUserInfo} from "@/api/index"
import { getObtainGenerateSolutionStatus } from "@/api/sleepSolution"
import wx from "wx"

@Options({
    data() {
        return {
            list: [
                {
                    title: null,
                    content: null,
                    isRichText: false,
                },
            ],
            
            memberId: null,
        }
    },
    methods: {
        /**
         * 跳转到健康问卷页面
         */
        toHealthPage(){
            let memberId = this.memberId
            getObtainGenerateSolutionStatus(memberId).then(respData => {

                let {
                // 近3天的报告数量
                reportCount,
                // 方案数量
                solutionCount
                } = respData

                if(reportCount <= 0){
                    this.$dialog.alert({
                        confirmButtonText: '我知道了',
                        confirmButtonColor: 'rgb(116, 83, 213)',
                        message: '请先完成测评报告，我们将根据您最新的报告出具专属睡眠改善方案，如有问题可咨询您的健康顾问。',
                    })
                    return
                }

                // 跳转到健康问卷页面
                wx.miniProgram.navigateTo({
                    url: '/pages/user/health/questionnaire/questionnaire?memberId=' + memberId,
                });
            })
            
        }
    },
    created(){
        
        // 获取会员ID参数
        getCurrentUserInfo().then(data =>{
            this.memberId = data.id
        })

        getArticleInfo(64).then(respData =>{
             this.list = [{
                title: respData.title,
                content: respData.content,
                isRichText: true,
             }]
        });

    },  
    beforeRouteEnter(to, from) {
        document.body.style.backgroundColor="#eeeff4";
    },
    beforeRouteLeave(to, from) {
        document.body.style.backgroundColor=''
    },
})
export default class SchemeCustomize extends Vue {
    list!: any[];
    member!: string;
    toHealthPage!: Function;
}

