<template>
  <div class="equipment">
    <van-grid :column-num="3" :border="false">
      <van-grid-item @click="goAboutDevice">
        <van-image :src="require('@/assets/img/equipment/watch.png')" class="btn-img"></van-image>
        <div class="btn-text">关于设备</div>
      </van-grid-item>
      <van-grid-item @click="goWifiSet">
        <van-image :src="require('@/assets/img/equipment/wifi.png')" class="btn-img"></van-image>
        <div class="btn-text">WIFI设置</div>
      </van-grid-item>
      <van-grid-item @click="UnbundlingEquipment">
        <van-image :src="require('@/assets/img/equipment/unbind.png')" class="btn-img"></van-image>
        <div class="btn-text">解绑设备</div>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import wx from "wx";
import {Toast} from "vant";
import { boundDeviceList, untieDevice } from "@/api/equipmentSet";

export default {
  setup() {
    const currentRate = ref(0);
    const text = computed(() => currentRate.value.toFixed(0) + "%");

    return {
      text,
      currentRate,
    };
  },
  data() {
    return {
      data: null,
      showNameInfo: false
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goToUrl(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    UnbundlingEquipment() {
      this.$dialog
        .confirm({
          title: "确认解绑当前设备？",
          message: "解绑后将无法收到当前设备数据",
        })
        .then(() => {
          boundDeviceList().then(resData => {
            untieDevice(resData[0]?.deviceIdentity).then(untieData => {
              Toast('解绑成功')
              setTimeout(() => {
                wx.miniProgram.navigateTo({
                  url: "/pages/health/healthReload",
                });
              }, 1500)
            }).catch(err => {
              Toast(err?.msg || '解绑失败')
            })
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    goAboutDevice() {
      wx.miniProgram.navigateTo({
        url:'/pages/health/equipmentSet/aboutDevice/aboutDevice',
      });
    },
    goWifiSet() {
      wx.miniProgram.navigateTo({
        url:'/pages/health/equipmentSet/wifiSet/wifiSet',
      });
    },
  },
};
</script>

<style  scoped lang="stylus">
.equipment
  padding 0 20px

.btn-img
  width 140px
  margin-bottom 20px
</style>
