
import { Options, Vue } from 'vue-class-component';      
import ScoreNum from '@/components/report/ScoreNum.vue';

@Options({
  props: {
    data: {},
  },
  components: {
    ScoreNum,
  },
  data() {
    return {
    }
  },
  methods: {
  },
})
export default class ReportSubTitleItem extends Vue {
}
