
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    product: {},
  },
  data() {
    return {
      showProductDetail: false,
    }
  },
})
export default class ProductItem extends Vue {
  product!: object
}
