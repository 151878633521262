<template>

<div style="margin: 0 auto;width:90%;text-align:left;">
     <H3 v-if="title != 'null' && title != null " v-html="title" style="text-align:left;"></H3>
     <p v-if="releaseTime" style="font-size: 15px;text-align: right;color: #333;">发布日期：{{ releaseTime }}</p>
     <br/>
     <p v-html="text"></p>
     
</div>
<video v-if="video != 'null' && video != null "  :src="video"  style="width:90%" controls="controls" ></video>
</template>

<script>
import {getArticleInfo,finbyBannerId,findByIconId} from "@/api/index"

export default {
  beforeRouteLeave (to, from, next) {
        to.meta.keepAlive=true
        next()
  },
  data() {
    return {
        text:   null,
        title:  null,
        video:  null,
        releaseTime: null,
    };
   
  },
  created(){
    if(this.$route.params.id != 0){
       getArticleInfo(this.$route.params.id).then(response =>{
         console.log(response)
             this.text = response.content || null;
             this.title = response.title || null;
             this.video = response.type == 2 ? response.videoUrl : response.fileUrl ;
             this.releaseTime = response.releaseTime || null;
            });
    }else if(this.$route.params.bannerId != 0){
      finbyBannerId(this.$route.params.bannerId).then(response =>{
         console.log(response)
             this.text = response.bannerText || null;
            });
    }else if(this.$route.params.iconId != 0){
       findByIconId(this.$route.params.iconId).then(response =>{
         console.log(response)
             this.text = response.iconText || null;
            });
    }
   
         

  },
  methods: {
    goff(){
        this.$router.back()
    },
  },
};
</script>

<style >

img{
  max-height:100% !important;
  max-width:100% !important;
  text-align:center !important;
}
video{
  text-align:center !important;
}
</style>