<template>
  <div class="conter-box">
  <button class="btn" @click="testSkip()">？？？</button>

    <!-- tab组件 -->
    <van-tabs
      @click="onClick"
      title-active-color="#333"
      title-inactive-color="#999"
      title-active-size="22px"
      line-width="0"
      style="height: 55px"
    >
      <!-- 密码登录功能 -->
      <van-tab :title="passLogin">
        <van-form
          @submit="onSubmit"

        >
          <van-field
            v-model="username"
            clearable
            autofocus
            name="用户名"
            label="账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号"
            placeholder="请输入账号"
          />
          <van-field
            v-model="password"
            clearable
            type="password"
            name="密码"
            label="登陆密码"
            placeholder="请输入登陆密码"
          />
          <div style="margin: 50px 0 0">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="linear-gradient(to right,#7d50e3,#5d45ca)"
              >登录</van-button
            >
          </div>
          <div class="forPass" @click="forPassword">忘记密码?</div>
        </van-form>
      </van-tab>
      <!-- 验证码登录功能 -->
      <van-tab :title="vcLogin">
        <van-form @submit="onSubmit">
          <van-field
            v-model="username"
            clearable
            autofocus
            name="用户名"
            label="手机号码"
            placeholder="请输入账号"
          />
          <!-- 验证码 -->
          <van-field
            v-model="sms"
            center
            clearable
            label="验&nbsp;证&nbsp;码"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                size="small"
                style="border-radius: 20px; font-weight: 600"
                @click="getVerificationCode"
                >发送验证码</van-button
              >
            </template>
          </van-field>

          <div style="margin: 50px 0 0">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="linear-gradient(to right,#7d50e3,#5d45ca)"
              >登录</van-button
            >

          </div>
        </van-form>
      </van-tab>
    </van-tabs>
  </div>
  <van-button
      size="small"
      style="border-radius: 20px; font-weight: 600"
      @click="scan"
  >扫一扫</van-button
  >
</template>

<script>
import { Toast } from "vant";
import {getJsapiSign} from '@/api/weixin'
import wx from 'wx';

export default {
  data() {
    return {
      passLogin: "密码登录",
      vcLogin: "验证码登录",

      username: "",
      password: "",

      tabTitle: "密码登录",
      sms: "",
    };
  },
  created() {
    console.log('created')
    this.getSign()
    this.wxReady()
  },
  // mounted() {
  // },
  methods: {

    getSign() {
      const url = window.location.href
      getJsapiSign(url).then(item =>{
        this.wxConfig(item)
      })
    },
    wxConfig(item) {
      console.log('weixin config start...', item)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: item.appId, // 必填，公众号的唯一标识
        timestamp: item.timestamp, // 必填，生成签名的时间戳
        nonceStr: item.nonceStr, // 必填，生成签名的随机串
        signature: item.signature,// 必填，签名
        jsApiList: ['chooseImage','scanQRCode',], // 必填，需要使用的JS接口列表
      })

    },
    wxReady() {
      wx.ready(function() {

        wx.checkJsApi({
          jsApiList: ['chooseImage','scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          }
        });

        let btn = document.getElementById('launch-btn');
        btn.addEventListener('launch', function (e) {
          console.log('success', e);
        });
        btn.addEventListener('error', function (e) {
          console.log('fail', e.detail);
        });


      });

      wx.error(function(res){
        alert(res);
      });
    },
    scan() {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          setTimeout(() =>{
            const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            alert('扫描结果' +result)
          },1000)
        }
      });
    },
    //tab 切换事件
    onClick(name, title) {
      this.tabTitle = title;
      console.log(this.tabTitle);
    },
    //表单提交事件
    onSubmit(values) {
      console.log("submit", values);
      if (this.tabTitle == "密码登录") {
        if (this.username) {
          if (!this.password) {
            Toast({
              message: "请输入密码",
              position: "top",
            });
          } else {
            console.log("发送网络请求");
          }
        } else {
          Toast({
            message: "请输入账号",
            position: "top",
          });
        }
      } else {
        if (this.username) {
          if (!this.sms) {
            Toast({
              message: "请输入验证码",
              position: "top",
            });
          } else {
            console.log("发送网络请求");
          }
        } else {
          Toast({
            message: "请输入账号",
            position: "top",
          });
        }
      }
    },
    //忘记密码事件
    forPassword() {
      console.log("忘记密码");
    },
    //获取验证码
    getVerificationCode() {
      console.log("获取验证码");
    },
    testSkip() {
      wx.miniProgram.navigateTo({
        url:'/pages/test/test',
        success: function(){
          console.log('success')
        },
        fail: function(){
          console.log('fail');
        },
        complete:function(){
          console.log('complete');
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus">
body {
  overflow: height;
  background-color: #fff;
}

.conter-box {
  width: 600px;
  height: 620px;
  margin: 0 auto;
  padding-top: 200px;
}

.forPass {
  margin-top: 30px;
  text-align: right;
  color: #ababab;
}

.van-tab:nth-child(1)::after{
  content:'';
  height:40px;
  border 1px solid #d9d9d9;
  position: relative;
  left: 100px
}

.van-tab--active {
  font-weight 600
}


</style>
