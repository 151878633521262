import request from "@/utils/request";

/**
 * 查询微信公众平台的绑定通知状态
 * @returns 
 */
export function queryBindState(options = {}) {
    return request.get("/api/wx/mp/query/bind/state",{}, options);
}

/**
 * 查询用户公众号的订阅关注状态
 * @returns 
 */
export function queryFollowState(options = {}) {
    return request.get("/api/wx/mp/query/follow/state",{}, options);
}
