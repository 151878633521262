<template>
  <div id="app">
    <div class="ageComponent__age">
      <span> {{ centerNum }}</span
      >斤
    </div>
    <div class="border">
      <div class="ageComponent">
        <div class="centerLine" ></div>
        <div class="centerLine1" ></div>
        <div class="centerLine2" ></div>

        <div class="ageComponent--solid"></div>
        <div class="OverflowEle">
          <div
            class="ageComponent__calibration"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
            ref="calibration"
          >
            <div class="item" v-for="item in count" :key="item.index">
              <div class="lineBox">
                <div
                  class="line"
                  :class="{short: item % 2, active: centerNum === item &amp;&amp; interval === null}"
                ></div>
              </div>
              <div class="num">{{ item + 1 }}</div>
            </div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  name: "pickerAge",
  data() {
    return {
      age: 188,
      centerNum: 6,
      standard: 0,
      calibrationWidth: 0,
      data: 0,
      count: 500,
      interval: null, //定时器
    };
  },
  mounted() {
    this.initAge();
    // if (this.$refs.calibration.scrollLeft === 0) {
    this.standard =
      this.$refs.calibration.getElementsByClassName("item")[0].clientWidth;
    console.log(this.standard);
    this.calibrationWidth = this.$refs.calibration.clientWidth;
    // }
    const _this = this;
    let topValue = 0; // 上次滚动条到顶部的距离
    // interval = null;// 定时器

    function debounce(fn) {
      let timeout = null;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fn.apply(this, topValue);
        }, 100);
      };
    }

    function test() {
      clearInterval(_this.interval);
      _this.$refs.calibration.scrollLeft = _this.data;
      _this.interval = null;
    }
    this.$refs.calibration.addEventListener("scroll", debounce(test));
    this.$refs.calibration.addEventListener("scroll", function (e) {
      // if(_this.interval == null) {
      //     _this.interval = setInterval(test, 200);
      // }
      const scrollLeft = _this.$refs.calibration.scrollLeft;
      let round = Math.round(scrollLeft / _this.standard);

      topValue = _this.$refs.calibration.scrollLeft;

      if (round + 6 > _this.count) {
        round = _this.count - 6;
      }

      _this.data =
        _this.$refs.calibration.getElementsByClassName("item")[
          round
        ].offsetLeft;
      _this.centerNum = round + 6;

      _this.$emit("onChangeAge", _this.centerNum);
    });
  },
  methods: {
    onTouchStart(e) {
      e.stopPropagation();
    },
    onTouchMove(e) {
      // this.data = this.$refs.calibration.scrollLeft
      // const scrollLeft = this.$refs.calibration.scrollLeft
      // let round = Math.round(scrollLeft / this.standard)
      // if (round + 6 > this.count) {
      //     round = this.count - 6
      // }
      //
      // this.centerNum = round + 6
    },
    onTouchEnd(e) {
      this.$emit("weightNum", this.centerNum);
    },
    initAge() {
      this.centerNum = this.age;

      this.$refs.calibration.scrollLeft =
        this.$refs.calibration.getElementsByClassName("item")[
          this.age - 6
        ].offsetLeft;
    },
  },
};
</script>
<style>
.border {
  width: 580px;
  height: 100px;
  border: 3px solid #999;
  border-radius: 90px;
  margin: 0 auto;
}
.ageComponent {
  position: relative;
  width: 530px;
  margin: 0 auto;
  height: 100px;
}

.ageComponent .centerLine {
  position: absolute;
  top: 60px;
  left: 275px;
  width: 4px;
  height: 30px;
  background-color: #ff6300;
}
.ageComponent .centerLine1 {
  position: absolute;
  top: 50px;
  left: 265px;
  width: 4px;
  height: 40px;
  background-color: #ff6300;
}
.ageComponent .centerLine2 {
  position: absolute;
  top: 60px;
  left: 255px;
  width: 4px;
  height: 30px;
  background-color: #ff6300;
}

.ageComponent::before {
  left: 0;
  top: 46px;
  width: 32px;
  height: 32px;
  position: absolute;
  content: "";
  background: linear-gradient(
    360deg,
    #fafafa 0%,
    rgba(250, 250, 250, 0.65) 100%
  );
}

.ageComponent::after {
  right: 0;
  top: 46px;
  width: 32px;
  height: 32px;
  position: absolute;
  content: "";
  background: linear-gradient(
    360deg,
    #fafafa 0%,
    rgba(250, 250, 250, 0.65) 100%
  );
}

.ageComponent__age {
  margin: 20px auto;
  text-align: center;
  height: 120px;
  color: #333;
  line-height: 120px;
  font-size: 66px;
}

.ageComponent__age span {
  line-height: 120px;
  font-size: 66px;
  font-weight: bold;
}

.ageComponent--solid {
  margin-top: 12px;
  height: 2px;
  position: relative;
  top: 70px;
  background: linear-gradient(
    to right,
    rgba(95, 99, 121, 0.05),
    rgba(95, 99, 121, 0.3),
    rgba(95, 99, 121, 0.05)
  );
}

.ageComponent .OverflowEle {
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.ageComponent__calibration {
  display: flex;
  width: 530px;
  /*width: 100%;*/
  height: 75px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.ageComponent__calibration .item {
  min-width: 60px;
  height: 100px;
}

.ageComponent__calibration .item .lineBox {
  position: relative;
  top: 45px;
  height: 40px;
  margin-bottom: 8px;
}

.ageComponent__calibration .item .line {
  width: 2px;
  margin: 0 auto;
  height: 23px;
  background-color: rgba(95, 99, 121, 0.4);
}

.ageComponent__calibration .item .line.short {
  height: 24px;
  background-color: rgba(95, 99, 121, 0.2);
}

.ageComponent__calibration .item .line.active {
  background-color: transparent;
}

.ageComponent__calibration .item .num {
    position: relative;
    top: -40px;
  text-align: center;
  color: rgba(95, 99, 121, 0.3);
  font-size: 14px;
}

.ageComponent__calibration::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
</style>