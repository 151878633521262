
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    padding: '',
  },
})
export default class Disclaimers extends Vue {
  padding!: string
}
