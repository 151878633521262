
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: '',
    person: '',
  },
  data() {
    return {
      showNameInfo: false,
    }
  },
  methods: {
  }
})
export default class PersonInfoToast extends Vue {
  person!: string
}
