<template>
  <div>
    <Call1 title="设备名称" value="智能手表" />
    <Call1 title="IMEI" :value="imei" btn="复制" />
  </div>
</template>

<script>
import Call1 from "@/components/Call/Call.vue";

export default {
  components: {
    Call1,
  },
  data() {
    return {
      imei: ''
    }
  },
  created() {
    this.imei = this.$route.query.imei
  },
  methods: {
  },
};
</script>

<style scoped lang="stylus">
.call {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 80px;
  line-height: 80px;
  display: flex;

  .title {
    flex: 1;
    text-align: left;
    font-size: 32px;
    font-weight: 700;
  }

  .content {
    flex: 1;
    text-align: left;
    font-size: 32px;
    color: #aaa;
    text-align: right;
  }

  span {
    color: blue;
  }
}
</style>