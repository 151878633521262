<template>
  <div>
    <van-nav-bar
      title="血糖"
      left-arrow="true"
      @click-left="onClickLeft"
    ></van-nav-bar>
    <SelectTimeCall @TodayDate="aaa" :time="'2021-11-22 10:22'" />
    <div class="inputBox">
      <div class="inputLeftBox">
        <div class="inputContent title">血糖数值</div>
      </div>
      <input class="inputClass" placeholder="请输入mmol/L" type="text" />
    </div>

    <div class="affirmBtn">确认</div>
  </div>
</template>

<script>
import SelectTimeCall from "@/components/SelectTimeCall";
export default {
  components: {
    SelectTimeCall,
  },
  data() {
    return {};
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="stylus">
.inputBox {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 4px solid #eee;
  padding:0 40px;
  height: 80px;

  .inputContent {
    height: 70px;
    width: 200px;
    line-height: 75px;
    font-size: 26px;
    text-align: left;
  }

  .title {
    font-size: 30px;
  }
}

.inputLeftBox {
  float: left;
}

.inputClass {
  float: left;
  border: 0;
  width: 60%;
  height: 70px;
  line-height: 70px;
}

.seletDate {
  float: left;
  text-align: right;
  width: 70%;
  height: 50px;
  line-height: 50px;
}

.date {
  padding-top: 18px;
}
</style>