
import { Options, Vue } from 'vue-class-component';

import { getObtainGenerateSolutionStatus, getList } from "@/api/sleepSolution"
import wx from "wx"
import moment from 'moment';

@Options({
    data() {
        return {
            loading: false,
            finished: false,
            list: [],
            memberId: null,
            schemeListItemImg: require('@/assets/img/scheme/scheme-list-item.svg')
        }
    },
    methods: {
        onLoad(){
            this.loadSchemeList();
        },
        /**
         * 加载方案列表
         */
        loadSchemeList(){
            getList(this.memberId).then(respData => {
                respData.forEach(v => {
                    // 格式化创建时间。
                    v.formatCreateTime = v.createTime && moment(v.createTime).format("YYYY-MM-DD HH:mm:ss") || ''
                })
                respData.forEach(v => {
                    // 格式化创建时间。
                    v.formatPayTime = v.payTime && moment(v.payTime).format("YYYY-MM-DD HH:mm:ss") || ''
                })
                this.list = respData;
                this.finished = true;
            }).catch(response => {
                this.$toast.fail("加载方案列表失败，原因：" + response.msg)
            })
        },
        /**
         * 跳转到健康问卷页面
         */
        toHealthPage(){
            let memberId = this.memberId
            getObtainGenerateSolutionStatus(memberId).then(respData => {

                let {
                // 近3天的报告数量
                reportCount,
                // 方案数量
                solutionCount
                } = respData

                if(reportCount <= 0){
                    this.$dialog.alert({
                        confirmButtonText: '我知道了',
                        confirmButtonColor: 'rgb(116, 83, 213)',
                        message: '请先完成测评报告，我们将根据您最新的报告出具专属睡眠改善方案，如有问题可咨询您的健康顾问。',
                    })
                    return
                }

                // 跳转到健康问卷页面
                wx.miniProgram.navigateTo({
                    url: '/pages/user/health/questionnaire/questionnaire?memberId=' + this.memberId,
                });
            })
            
        },
        /**
         * 跳转到健康详情页
         */
        toHealthInfoPage(sleepSolutionId){
            // 跳转到健康问卷页面
                wx.miniProgram.navigateTo({
                    url: '/pages/user/health/scheme/info/SchemeInfo?memberId=' + this.memberId + '&sleepSolutionId='+sleepSolutionId ,
                });
        }

    },
    created() {

        let {memberId} = this.$route.query;
        this.memberId = memberId;
        console.log("获取会员ID参数 请求query");
    },  
    beforeRouteEnter(to, from) {
        document.body.style.backgroundColor="#eeeff4";
    },
    beforeRouteLeave(to, from) {
        document.body.style.backgroundColor=''
    },
})
export default class SchemeList extends Vue {
    loading!: boolean;
    finished!: boolean;
    list!: any[];
    
    onLoad!: Function;
    toHealthPage!: Function;
    toHealthInfoPage!: Function;

    memberId!: string;

    schemeListItemImg!: string;
}

