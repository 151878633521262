import request from "@/utils/request";

/**
 * 健康测评列表
 * @returns {*}
 */
export function categoryList() {
    return request.get("/api/health/categoryList");
}


/**
 * 查询某项报告内容详情
 * @returns {*}
 */
export function categoryDetail(reportId,dataType) {
    return request.get("/api/health/categoryDetailByDataType",{
        reportId,dataType
    });
}

/**
 * 查询报告列表
 * @returns {*}
 */
export function getReportList(data = {}) {
    return request.get("/api/report/getReportList",data);
}

/**
 * 查询报告列表V2
 * @returns {*}
 */
export function getReportListV2(data = {}) {
    return request.get("/api/report/getReportListV2",data);
}

/**
 * 查询报告详情
 * @returns {*}
 */
export function fullCategoryDetail(reportId) {
    return request.get("/api/health/fullCategoryDetail", {
        reportId
    });
}

/**
 * 报告趋势
 * @returns {*}
 */
export function findReportTrend(dayLength) {
    return request.get("/api/report/findReportTrend", {
        dayLength,
        reportType: 1
    });
}

/**
 * 手动生成报告
 * @param memberId 会员ID
 */
export function createReport(memberId: number){
    return request.get("/api/report/createReport",{
        memberId: memberId
    })
}

/**
 * 获取多个报告的基本信息
 * @param reportIds 报告Ids，多个以逗号拼接
 * @returns array
 */
export function getReportBaseInfo(reportIds: string) {
    return request.get(`/api/report/v7/reportBaseInfo`, {
        reportIds: reportIds
    });
}

export function getV8ReportBaseInfo(reportIds: string) {
    return request.get(`/api/report/v8/reportBaseInfo`, {
        reportIds: reportIds
    });
}