import request from "@/utils/request";

/**
 * 获取成员的基本信息
 * @param memberId 成员ID 
 * @returns 
 */
export function getMemberBaseInfo(memberId) {
    return request.get("/api/member/username/remark/memberBaseInfo", {
        memberId: memberId
    });
}

export function getMemberBaseInfoByHealthQuestionId(healthQuestionId: number) {
    return request.get("/api/member/username/remark/health-question/memberBaseInfo",{
        healthQuestionId: healthQuestionId
    });
}

export function saveUsernameRemark(memberId: number, remarkName: string){
    return request.post("/api/member/username/remark/save", {
        memberId: memberId,
        remarkName: remarkName,
    });
}