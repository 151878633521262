
import { Options, Vue } from 'vue-class-component';
import IconButton from "@/components/button/IconButton.vue";
import TodayHeartRate from "@/components/functionCard/todayHeartRate/TodayHeartRate.vue";
import WatchUse from "@/components/functionCard/watchUse/WatchUse.vue";
import {categoryList} from '@/api/report'
import wx from "wx";
import {getJsapiSign} from '@/api/weixin'
import {getCurrentUserInfo, bindDevice, getmyself} from "@/api/index"
import { boundDeviceList } from "@/api/equipmentSet";
import {Toast} from "vant";

import { getObtainGenerateSolutionStatus } from "@/api/sleepSolution"
import leafImage from '@/assets/img/scheme/leaf.svg';
import { copyContent } from '@/utils/ClipboardUtils'

// 1精神测评、 2睡眠测评、3生命节律 4心血管功能 5心理状态 6 生命活力 7寒热状态 8心血管病 9脑血管 10老年性痴 11失眠 12糖尿病

@Options({
  components: {
    IconButton,
    TodayHeartRate,
    WatchUse,
  },
  data() {
    return {
      defaultAvatar: require('@/assets/img/icon/user/默认头像@2x.png'),
      showImg:false,
      healthTypeList: [],
      userInfo: {},
      deviceList: {},
      leafImg: leafImage,
      loading: false,
    }
  },
  computed: {
    /**
     * 穿戴状态
     * @returns true穿戴/false未穿戴
     */
    wearingState(){
      return this.deviceList.length > 0 && this.deviceList[0].wearStatus == 1
    },
  },
  created() {

    if (this.$route.query.token) {
      localStorage.setItem('token', this.$route.query.token)
    }

    if (this.$route.query.healthPageIsScanSkip === 'true') {
      if (this.$route.query.healthPageScanCode === 'err') {
        Toast('请扫描正确的二维码')
      } else {
        bindDevice(this.$route.query.healthPageScanCode).then(res => {
          Toast('绑定成功')
          boundDeviceList().then(resData => {
            this.deviceList = resData || []
          })
        }).catch(err => {
          console.log('err///////', err)
          Toast(err?.msg || '绑定失败')
        })
      }
    } else {
      boundDeviceList().then(resData => {
        this.deviceList = resData || []
      })
    }
    // 获取初始化健康测评图标列表
      // this.initHealthTypeList();

    getCurrentUserInfo().then(res =>{
      console.log("getCurrentUserInfo",res);
      
      // 获取会员ID参数
      this.userInfo.memberId = res.id;

      if (res.sex == '1') {
        res.sex = '男'
      }
      if (res.sex == '2') {
        res.sex = '女'
      }
      this.userInfo.userName = res.userName
      this.userInfo.sex = res.sex
      this.userInfo.avatarUrl = res.avatar
      this.userInfo.uid = res.uid
      if (!res.birthday) {
        wx.miniProgram.reLaunch({
          url:'/pages/user/userInfo/userInfo',
        });
      }
    })
  },
  methods:{
    
    copyContent: copyContent,
    /**
     * 刷新当前页面
     */
    onRefresh(){
      let route = this.$route
      let paths = route.href.split("?")

      let buildPath = paths[0];
      let params = new URLSearchParams(paths[1])
      params.set("healthPageIsScanSkip","false")
      params.set("healthPageScanCode","")
      params.delete("token")
      buildPath = buildPath + "?" + params.toString()
      // 因为是#开头，导致误以为是锚点，页面不会刷新。使用reload刷新，assign用于修改当前href地址指向。
      location.assign(buildPath)
      location.reload()
    },
    // 专属睡眠改善方案 Btn
    toSleepScheme(){
      let memberId = this.userInfo.memberId;
      getObtainGenerateSolutionStatus(memberId).then(respData => {
          let {
          // 近3天的报告数量
          reportCount,
          // 方案数量
          solutionCount
          } = respData

          if(solutionCount > 0){
              // 跳转到方案列表页面
              wx.miniProgram.navigateTo({
                url: '/pages/user/health/scheme/list/SchemeList?memberId=' + memberId,
              });
              return
          }

          if(reportCount <= 0){
              this.$dialog.alert({
                  confirmButtonText: '我知道了',
                  confirmButtonColor: 'rgb(116, 83, 213)',
                  message: '请先完成测评报告，我们将根据您最新的报告出具专属睡眠改善方案，如有问题可咨询您的健康顾问。',
              })
              return
          }

          // 跳转到健康问卷页面
          wx.miniProgram.navigateTo({
              url: '/pages/user/health/questionnaire/questionnaire?memberId=' + memberId,
          });
      })

    },
    initHealthTypeList() {
      this.healthTypeList =this.$store.state.cachedHealthTypeList
      console.log('cache' , this.healthTypeList)
      if(this.healthTypeList.length > 0){
        return;
      }
      const _dataTypeList = this.$store.state.dataTypeList
        categoryList().then(resp => {
          if (!resp) {
            return
          }
          resp.forEach(item =>{
            const dataType  = _dataTypeList.filter(f => f.type === item.data_type + '')[0]
            item['url'] = dataType.healthBackgroundImgUrl
          })
          this.healthTypeList = resp
          this.cacheHealthTypeList(resp)
        });
    },
    cacheHealthTypeList(list) {
      this.$store.commit('addHealthTypeList',list)
    },
    goToReportDetail(item) {
      if (item.report_id === undefined) {
        Toast({
          message: "还未生成报告数据哟",
          position: "top",
        });
        return;
      }
      wx.miniProgram.navigateTo({
        url:'/pages/health/evaluation/evaluation?report_id='+item.report_id + '&data_type='+item.data_type,
      });
    },
    goToReportList() {
      wx.miniProgram.navigateTo({
        url:'/pages/health/reportList/reportList?memberId=' + this.userInfo.memberId,
      });
    },
    goToTrend() {
      wx.miniProgram.navigateTo({
        url:'/pages/health/trend/trend'
      });
    },
    goToUrl(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    getSign() {
      const url = window.location.href
      console.log('window.location.href', window.location.href)
      getJsapiSign(url).then(item =>{
        this.wxConfig(item)
      })
    },
    wxConfig(item) {
      console.log('weixin config start...', item)
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: item.appId, // 必填，公众号的唯一标识
        timestamp: item.timestamp, // 必填，生成签名的时间戳
        nonceStr: item.nonceStr, // 必填，生成签名的随机串
        signature: item.signature,// 必填，签名
        jsApiList: ['chooseImage','scanQRCode',], // 必填，需要使用的JS接口列表
      })

    },
    wxReady() {
      wx.ready(function() {

        // alert('wxReady..................................')
        wx.checkJsApi({
          jsApiList: ['chooseImage','scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          }
        });

        let btn = document.getElementById('launch-btn');
        btn.addEventListener('launch', function (e) {
          console.log('success', e);
        });
        btn.addEventListener('error', function (e) {
          console.log('fail', e);
        });


      });

      wx.error(function(res){
        // alert(res);
      });
    },
    scan() {
      wx.miniProgram.navigateTo({
        url:'/pages/authorizeRouter/authorizeRouter?fromLocationCode=0200',
      });
    },
    equipmentSet() {
      wx.miniProgram.navigateTo({
        url:'/pages/health/equipmentSet/equipmentSet',
      });
    },
    switchMembers() {
      wx.miniProgram.navigateTo({
        url:'/pages/member/switchMembers/switchMembers',
      });
    },
    toMemberInfo() {
      wx.miniProgram.navigateTo({
        url:'/pages/member/memberInfo/memberInfo',
      });
    },
  }
})
export default class Health extends Vue {}
