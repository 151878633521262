
<template id="bodys">
<!-- <van-nav-bar
      title="消息"
      left-arrow="true"
      @click-left="goff"
    ></van-nav-bar> -->
    <div class="news">
        <div class = "date">
            2021年01月07日 10:00
        </div>
        <div class="content">
            <div class="title">
                <strong> 测评报告提醒 </strong>
            </div>
            <div class="name">
                报告名称:小波健康状态测试分析报告
            </div>
            <div class="reportcontent" >
                报告内容:2021年01月01日至2021年01月07日报告
            </div>
            <div class="reportDate" >
             报告时间:2021年01月07日
            </div>
        </div>
    </div>
    <div class = "news">
        <div class = "date">
            2021年01月07日 10:00
        </div>
        <div class="content">
            <div class="title">
                <strong> 测评报告提醒 </strong>
            </div>
            <div class="name">
                报告名称:小波健康状态测试分析报告
            </div>
            <div class="reportcontent" >
                报告内容:2021年01月01日至2021年01月07日报告
            </div>
            <div class="reportDate" >
             报告时间:2021年01月07日
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
       
    };
   
  },
beforeCreate () {
      document.querySelector('body').setAttribute('style', 'background-color:#e3e0e06e;')
},
//销毁前清除
beforeUnmount () {
      document.querySelector('body').removeAttribute('style')
},
  methods: {
    goff(){
        this.$router.back()
    },
  },
};
</script>

<style scoped lang="stylus">

#bodys{
    background-color:#e3e0e06e
} 
.date{
     color: rgb(192 188 188)
     font-size 20px;
}
.content{
    margin-top 30px
    height:240px;
    background-color:#fff;
}
.title{
    text-align:left;position: relative;left:25px;top: 20px;font-size:36px
}
.name{
    color: rgb(154 148 148);text-align:left;position: relative;left:25px;top: 45px;font-size:25px
}
.reportcontent{
    color: rgb(154 148 148);text-align:left;position: relative;left:25px;top: 56px;font-size:25px
}
.reportDate{
    color: rgb(154 148 148);text-align:left;position: relative;left:25px;top: 65px;font-size:25px
}
.news{
    margin: 0 auto;
    width:90%;
    text-align:center;
    margin-top 30px;
    
}

</style>