<template>
<van-pull-refresh  v-model="loading"  @refresh="onRefresh">
<!-- v-show="evaluationReportList.length > 0" -->
  <div >
    <van-list
      v-model:loading="pageStatus.loading"
      :finished="pageStatus.finished"
      finished-text="数据已经加载完了噢"
      error-text="数据加载失败，点击重新加载"
      @load="onLoadData"
    >
      <!-- <div class="report-list">
        <div class="reprt-list-item" v-for="(item, i) in evaluationReportList" :key="i"
            @click="goToReport(item.reportVersion, item.id, item.memberId)">
          <div class="item-name">测评报告</div>
          <div class="item-date">{{ item.reportDate }} - {{item.createTime}}</div>
          <svg viewBox="0 0 1024 1024" class="righticon demo-home-nav__icon">
            <path fill="#B6C3D2"
                  d="M601.1 556.5L333.8 289.3c-24.5-24.5-24.5-64.6 0-89.1s64.6-24.5 89.1 0l267.3 267.3c24.5 24.5 24.5 64.6 0 89.1-24.5 24.4-64.6 24.4-89.1-.1z"></path>
            <path fill="#B6C3D2"
                  d="M690.2 556.5L422.9 823.8c-24.5 24.5-64.6 24.5-89.1 0s-24.5-64.6 0-89.1l267.3-267.3c24.5-24.5 64.6-24.5 89.1 0 24.5 24.6 24.5 64.6 0 89.1z"></path>
          </svg>
        </div>
      </div> -->

      <div class="card card-shadow" v-for="(item, i) in evaluationReportList" :key="i"  @click="goToReport(item.reportVersion, item.id, item.memberId)">

        <div style="position: relative;text-align: left;padding: 12px 18px;" >
          <div style="position: relative;width: 100%;padding-right: 15px;box-sizing: border-box;">
            <span style="color: #939393;">{{ item.reportDate }} - {{item.createTime}}</span>
            <span style="position: absolute;top: 2px;transform: scale(1.5);right: 0;">></span>
          </div>
          <van-divider />
          <div style="display: flex;width: 100%;">
            <div style="width: 44px;">
              <svg v-if="item.isFillDesc == 1" style="width: 100%;height: auto;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1838" height="128" width="128"><path d="M728.3 928.2H297.9c-84.2 0-152.8-68.5-152.8-152.8V329.1c0-84.2 68.5-152.8 152.8-152.8H365v60h-67.1c-51.2 0-92.8 41.6-92.8 92.8v446.3c0 51.2 41.6 92.8 92.8 92.8h430.4c51.2 0 92.8-41.6 92.8-92.8V329.1c0-51.2-41.6-92.8-92.8-92.8H661v-60h67.3c84.2 0 152.8 68.5 152.8 152.8v446.3c0 84.3-68.6 152.8-152.8 152.8z" fill="#7232dd" p-id="1839"></path><path d="M593.6 308.1h-161c-49.3 0-89.4-40.1-89.4-89.4v-31.4c0-49.3 40.1-89.4 89.4-89.4h161c49.3 0 89.4 40.1 89.4 89.4v31.4c0 49.3-40.1 89.4-89.4 89.4zM432.6 158c-16.2 0-29.4 13.2-29.4 29.4v31.4c0 16.2 13.2 29.4 29.4 29.4h161c16.2 0 29.4-13.2 29.4-29.4v-31.4c0-16.2-13.2-29.4-29.4-29.4h-161z" fill="#7232dd" p-id="1840"></path><path d="M511.3 477.1H320.1c-16.6 0-30-13.4-30-30s13.4-30 30-30h191.3c16.6 0 30 13.4 30 30s-13.5 30-30.1 30zM576.1 604.1h-256c-16.6 0-30-13.4-30-30s13.4-30 30-30h256.1c16.6 0 30 13.4 30 30s-13.5 30-30.1 30zM640.1 732.1h-320c-16.6 0-30-13.4-30-30s13.4-30 30-30h320.1c16.6 0 30 13.4 30 30s-13.5 30-30.1 30z" fill="#7232dd" p-id="1841"></path></svg>
              <img v-else src="@/assets/img/reportlist/reportlist-icon.svg" />
            </div>
            <div style="flex-grow: 1;">
              <van-row>
                <van-col span="6">
                  <div style="text-align: center;">
                    <span style="display: inline-block;font-size: 1.3em;" :style="{ color: getColorByScore(item.heartMultiScore) }" >
                      <span v-if="!item.heartMultiScore" style="">-</span>
                      <span v-else v-text="parseInt(item.heartMultiScore)"></span>
                    </span>
                    <br />
                    <span style="display: inline-block;color: #B6B6B6;">心藏</span>
                  </div>
                </van-col>
                <van-col span="6">
                  <div style="text-align: center;">
                    <span style="display: inline-block;font-size: 1.3em;" :style="{ color: getColorByScore(item.liverMultiScore) }" >
                      <span v-if="!item.liverMultiScore" style="">-</span>
                      <span v-else v-text="parseInt(item.liverMultiScore)"></span>
                    </span>
                    <br />
                    <span style="display: inline-block;color: #B6B6B6;">肝藏</span>
                  </div>
                </van-col>
                <van-col span="6">
                  <div style="text-align: center;">
                    <span style="display: inline-block;font-size: 1.3em;" :style="{ color: getColorByScore(item.kidneyMultiScore) }" >
                      <span v-if="!item.kidneyMultiScore" style="">-</span>
                      <span v-else v-text="parseInt(item.kidneyMultiScore)"></span>
                    </span>
                    <br />
                    <span style="display: inline-block;color: #B6B6B6;">肾藏</span>
                  </div>
                </van-col>
                <van-col span="6">
                  <div style="text-align: center;">
                    <span style="display: inline-block;font-size: 1.3em;" :style="{ color: getColorByScore(item.yhgnScore) }">
                      <span v-if="!item.yhgnScore" style="">-</span>
                      <span v-else v-text="parseInt(item.yhgnScore)"></span>
                    </span>
                    <br />
                    <span style="display: inline-block;color: #B6B6B6;">脾脏</span>
                  </div>
                </van-col>
              </van-row>
            </div>
            <span style="width: 34px;"></span>
          </div>
  
        </div>
      </div>


    </van-list>
  </div>
  <NoData v-if="!evaluationReportList.length"></NoData>
</van-pull-refresh>
</template>


<script>
import { getReportList, getReportListV2 } from "@/api/report";
import { getmyself } from "@/api/index"
import NoData from '@/components/noData/NoData.vue'
import moment from 'moment'
import wx from "wx";
import { Toast } from "vant";

export default {
  name: "EvaluationReportList",
  components: {
    NoData,
  },
  data() {
    return {
      evaluationReportList: [],
      memberId: '',
      loading: false,

      pageStatus: {
        loading: false,
        finished: false,
        pageNo: 1,
        pageSize: 10,
      },
    }
  },
  created() {

    let {
      memberId,
    } = this.$route.query

    this.memberId = memberId

  },
  methods: {
    getColorByScore(score){

      if(!score){
        return "#000000";
      }

      if(score >= 80){
        return "#3a811d";
      }else if(score >=60 && score < 80){
        return "#f5a742";
      }else {
        return "#f27133";
      }
    },
    onRefresh  ()  {
      location.reload();
    },
    onLoadData(){
      this.getData().then(data => {
        Array.prototype.push.apply(this.evaluationReportList,data.content)
        if(this.pageStatus.pageNo + 1 > data.totalPage){
          this.pageStatus.finished = true;
        }
        this.pageStatus.pageNo++;
      }).finally(data => {
        this.pageStatus.loading = false;
        this.loading = false;
      })
    },
    getData() {
      return getReportListV2({
        pageNo: this.pageStatus.pageNo,
        pageSize: this.pageStatus.pageSize,
        memberId: this.memberId,
      }).then(item => {
        if(item === undefined || item === null){
          this.pageStatus.finished = true;
        }
        item.content.forEach(m => {
          m.reportDate = moment(m.reportDate).format("YYYY年MM月DD日")
          m.createTime = moment(m.createTime).format("YYYY年MM月DD日")
        })
        return item;
      })
    },
    goToReport(version, id, memberId) {
      if (version === '7.0') {
        wx.miniProgram.navigateTo({
          url: `/pages/share/report/report?id=${id}&memberId=${memberId}`
        });
      } else if (version === '7.1') {
        wx.miniProgram.navigateTo({
          url: `/pages/share/report7_1/report7_1?id=${id}&memberId=${memberId}`
        });
      } else if (version === '8.0') {
        wx.miniProgram.navigateTo({
          url: `/pages/share/report8_0/report8_0?id=${id}&memberId=${memberId}`
        });
      } else {
        wx.miniProgram.navigateTo({
          url: `/pages/share/before/beforeReport?id=${id}&memberId=${memberId}`
        });
      }
    },
  }
}
</script>

<style scoped lang="stylus">

.notice-swipe
  height: 40px;
  line-height: 40px;

.reprt-list-item
  text-align: left;
  padding 25px 30px
  position relative
  border-bottom 1px solid #eee

  &:nth-last-child(1)
    border-bottom none

  .item-date
    font-size 28px
    color #999
    margin-top 11px

  .righticon
    width 32px
    position absolute
    right 40px
    top 50%
    transform translate(0, -50%)

</style>
