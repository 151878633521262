<template>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <div class="wifi-name-box">
        <van-field
            v-model="wifiname"
            name="name"
            label="网络名称"
            placeholder="网络名称"
            :rules="[{ required: true, message: '请填写网络名称' }]"
        />
        <van-button round size="mini" type="primary" color="linear-gradient(to right,#7d50e3,#5d45ca)" class="search-btn" @click="toSearchWifi" v-show="showSearchBtn">
            搜索WiFi
        </van-button>
      </div>
      <van-field
          v-model="encryptTypeName"
          is-link
          readonly
          name="encryptType"
          label="安全性"
          @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
            title="安全性"
            :columns="encryptTypeList"
            :columns-field-names="{ text: 'name', }"
            @confirm="onSelectEncryptConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="密码"
          :rules="[{ required: false, message: '请填写密码' }]"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit" color="linear-gradient(to right,#7d50e3,#5d45ca)">
        确定
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { getEncryptType, addWifi, updateWifi} from "@/api/equipmentSet";
import { Toast } from 'vant';
import wx from "wx";
import { ref } from 'vue';

export default {
  components: {},
  setup() {
    const wifiname = ref('');
    const password = ref('');
    const encryptTypeName = ref('WPAorWPA2_PSK');
    const encryptTypeVal = ref(10);
    const showPicker = ref(false);

    const onSelectEncryptConfirm = (value) => {
      encryptTypeVal.value = value.value;
      encryptTypeName.value = value.name;
      showPicker.value = false;
    };

    return {
      wifiname,
      password,
      encryptTypeName,
      encryptTypeVal,
      onSelectEncryptConfirm,
      showPicker,
    };
  },
  data() {
    return {
      wifitList: [],
      deviceIdentity: '',
      encryptTypeList: [],
      id: '',
      wifiId: '',
      editData: {},
      showSearchBtn: false,
    };
  },
  created() {
    if (this.$route.query.editData) {
      let editData = JSON.parse(this.$route.query.editData)
      this.editData = editData
      this.wifiname = editData.name
      this.password = editData.password
      this.id = editData.id
      this.wifiId = editData.wifiId
      this.encryptTypeVal = editData.encryptType
    } else {
      this.showSearchBtn = true
    }

    if (this.$route.query.name) {
      this.wifiname = this.$route.query.name
    }

    this.deviceIdentity = this.$route.query.imei

    getEncryptType().then(resData => {
      this.encryptTypeList = resData
      if (this.$route.query.editData) {
        let encryptTypeVal = JSON.parse(this.$route.query.editData).encryptType
        resData.forEach(item => {
          if (item.value == encryptTypeVal) {
            this.encryptTypeName = item.name
          }
        })
      }
    })
  },
  methods: {
    toSearchWifi() {
      wx.miniProgram.redirectTo({
        url: '/pages/member/equipment/searchWifi/searchWifi?imei=' + this.imei,
      });
    },
    onSubmit(values) {
      values.deviceId = this.deviceIdentity
      values.encryptType = this.encryptTypeVal
      if (!this.showSearchBtn) {
        values.id = this.id
        values.wifiId = this.wifiId
        updateWifi(values).then(res => {
          Toast('修改成功')
          setTimeout(() => {
            wx.miniProgram.navigateBack({
              delta: 1,
            })
            wx.miniProgram.redirectTo({
              url: '/pages/member/equipment/wifiSet/wifiSet?imei=' + this.imei,
            })
          }, 1000)
        }).catch(err => {
          Toast(err?.msg || '修改失败')
        })
      } else {
        addWifi(values).then(res => {
          Toast('添加成功')
          setTimeout(() => {
            wx.miniProgram.navigateBack({
              delta: 1,
            })
            wx.miniProgram.redirectTo({
              url: '/pages/member/equipment/wifiSet/wifiSet?imei=' + this.imei,
            })
          }, 1000)
        }).catch(err => {
          Toast(err?.msg || '添加失败')
        })
      }
    }
  },
};
</script>

<style scoped lang="stylus">
.wifi-name-box
  position relative
  .search-btn
    position absolute
    right: 26px
    top 50%
    transform translate(0, -50%)
</style>