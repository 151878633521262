<template>
  <div>
    <van-nav-bar
      title="心率"
      left-arrow="true"
      @click-left="onClickLeft"
    ></van-nav-bar>
  </div>
  <div class="selectionTime">
    <div class="left"><van-icon name="arrow-left" /></div>
    <div class="content" is-link @click="showPopup">
      {{ TodayDate }}<van-icon style="fontsize: 26px" name="calendar-o" />
    </div>
    <div class="right"><van-icon name="arrow" /></div>
  </div>
  <van-popup
    v-model:show="show"
    position="bottom"
    :close-on-click-overlay="false"
  >
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      title="选择年月日"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="setTime"
      @cancel="cancelTime"
  /></van-popup>
  <div class="ecartsBox" id="main"></div>
</template>

<script>
import { ref } from "vue";
import * as ecarts from "echarts";

export default {
  name: "echarts",
  setup() {
    const currentDate = ref(new Date());
    return {
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate,
    };
  },
  data() {
    return {
      show: false,
      TodayDate: "",
    };
  },
  mounted() {
    this.TodayDate = `${this.currentDate.getFullYear()}年${
      this.currentDate.getMonth() + 1
    }月${this.currentDate.getDate()}日`;
    console.log(ecarts);
    this.echartsInit();
  },
  methods: {
    echartsInit() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      let data = [
        ["2000-06-05", 116],
        ["2000-06-06", 129],
        ["2000-06-07", 135],
        ["2000-06-08", 86],
        ["2000-06-09", 73],
        ["2000-06-10", 85],
        ["2000-06-11", 73],
        ["2000-06-12", 68],
        ["2000-06-13", 92],
        ["2000-06-14", 130],
        ["2000-06-15", 245],
        ["2000-06-16", 139],
        ["2000-06-17", 115],
        ["2000-06-18", 111],
        ["2000-06-19", 190],
        ["2000-06-20", 206],
        ["2000-06-21", 137],
        ["2000-06-22", 128],
        ["2000-06-23", 85],
        ["2000-06-24", 94],
        ["2000-06-25", 71],
        ["2000-06-26", 106],
        ["2000-06-27", 84],
        ["2000-06-28", 93],
        ["2000-06-29", 85],
        ["2000-06-30", 73],
        ["2000-07-01", 83],
        ["2000-07-02", 125],
        ["2000-07-03", 107],
        ["2000-07-04", 82],
        ["2000-07-05", 44],
        ["2000-07-06", 72],
        ["2000-07-07", 106],
        ["2000-07-08", 107],
        ["2000-07-09", 66],
        ["2000-07-10", 91],
        ["2000-07-11", 92],
        ["2000-07-12", 113],
        ["2000-07-13", 107],
        ["2000-07-14", 131],
        ["2000-07-15", 111],
        ["2000-07-16", 64],
        ["2000-07-17", 69],
        ["2000-07-18", 88],
        ["2000-07-19", 77],
        ["2000-07-20", 83],
        ["2000-07-21", 111],
        ["2000-07-22", 57],
        ["2000-07-23", 55],
        ["2000-07-24", 60],
           ["2000-06-05", 116],
        ["2000-06-06", 129],
        ["2000-06-07", 135],
        ["2000-06-08", 86],
        ["2000-06-09", 73],
        ["2000-06-10", 85],
        ["2000-06-11", 73],
        ["2000-06-12", 68],
        ["2000-06-13", 92],
        ["2000-06-14", 130],
        ["2000-06-15", 245],
        ["2000-06-16", 139],
        ["2000-06-17", 115],
        ["2000-06-18", 111],
        ["2000-06-19", 190],
        ["2000-06-20", 206],
        ["2000-06-21", 137],
        ["2000-06-22", 128],
        ["2000-06-23", 85],
        ["2000-06-24", 94],
        ["2000-06-25", 71],
        ["2000-06-26", 106],
        ["2000-06-27", 84],
        ["2000-06-28", 93],
        ["2000-06-29", 85],
        ["2000-06-30", 73],
        ["2000-07-01", 83],
        ["2000-07-02", 125],
        ["2000-07-03", 107],
        ["2000-07-04", 82],
        ["2000-07-05", 44],
        ["2000-07-06", 72],
        ["2000-07-07", 106],
        ["2000-07-08", 107],
        ["2000-07-09", 66],
        ["2000-07-10", 91],
        ["2000-07-11", 92],
        ["2000-07-12", 113],
        ["2000-07-13", 107],
        ["2000-07-14", 131],
        ["2000-07-15", 111],
        ["2000-07-16", 64],
        ["2000-07-17", 69],
        ["2000-07-18", 88],
        ["2000-07-19", 77],
        ["2000-07-20", 83],
        ["2000-07-21", 111],
        ["2000-07-22", 57],
        ["2000-07-23", 55],
        ["2000-07-24", 60],
           ["2000-06-05", 116],
        ["2000-06-06", 129],
        ["2000-06-07", 135],
        ["2000-06-08", 86],
        ["2000-06-09", 73],
        ["2000-06-10", 85],
        ["2000-06-11", 73],
        ["2000-06-12", 68],
        ["2000-06-13", 92],
        ["2000-06-14", 130],
        ["2000-06-15", 245],
        ["2000-06-16", 139],
        ["2000-06-17", 115],
        ["2000-06-18", 111],
        ["2000-06-19", 190],
        ["2000-06-20", 206],
        ["2000-06-21", 137],
        ["2000-06-22", 128],
        ["2000-06-23", 85],
        ["2000-06-24", 94],
        ["2000-06-25", 71],
        ["2000-06-26", 106],
        ["2000-06-27", 84],
        ["2000-06-28", 93],
        ["2000-06-29", 85],
        ["2000-06-30", 73],
        ["2000-07-01", 83],
        ["2000-07-02", 125],
        ["2000-07-03", 107],
        ["2000-07-04", 82],
        ["2000-07-05", 44],
        ["2000-07-06", 72],
        ["2000-07-07", 106],
        ["2000-07-08", 107],
        ["2000-07-09", 66],
        ["2000-07-10", 91],
        ["2000-07-11", 92],
        ["2000-07-12", 113],
        ["2000-07-13", 107],
        ["2000-07-14", 131],
        ["2000-07-15", 111],
        ["2000-07-16", 64],
        ["2000-07-17", 69],
        ["2000-07-18", 88],
        ["2000-07-19", 77],
        ["2000-07-20", 83],
        ["2000-07-21", 111],
        ["2000-07-22", 57],
        ["2000-07-23", 55],
        ["2000-07-24", 60],
      ];

      let myChart = ecarts.init(document.getElementById("main"));
      // 绘制图表
      myChart.setOption({
        // 显示图例
        legend: {
          icon: "rect",
          bottom: 0,
          left: 50,
        },
        color: ["#ff6700"],
        xAxis: {
          data: data.map((item) => {
            return item[0];
          }),
          show: true,
          axisTick: false,
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
          },
        },
        yAxis: {
          axisTick: {
            // 纵坐标旁边的符合
            show: false,
          },
          min: 10,
          splitLine: {
            show: true,
          },
        },
        series: {
          type: "line",
          data: data.map((item) => {
            return item[1];
          }),
          // 绘制阴影
          markArea: {
            silent: false,
          },
        },
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    showPopup() {
      this.show = true;
    },
    setTime(e) {
      console.log(e.getFullYear());
      this.TodayDate = `${e.getFullYear()}年${
        e.getMonth() + 1
      }月${e.getDate()}日`;

      this.show = false;
    },
    cancelTime() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="stylus">
.selectionTime {
  display: flex;
  width: 100%;
  font-size: 32px;
  padding: 20px 0;
  flex-wrap: nowrap;
  line-height: 40px;

  .left {
    flex: 1;
  }

  .content {
    flex: 4;
  }

  .right {
    flex: 1;
  }
}

.ecartsBox {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 500px;
}
</style>