import request from "@/utils/request";
/**
 * 获取文章详情
 * @param  
 * @returns 
 */
export function getAboutDevice(deviceId: number) {
    return request.get(`/api/device/aboutDevice/${deviceId}`)
}



/**
* 获取文章详情
* @param  
* @returns 
*/
export function getDeviceValidDuration(endTime: number, memberId: number, startTime: number) {
    return request.get('api/lemonnc/deviceValidDuration',
        { endTime, memberId, startTime })
}

/**
 * 获取设备列表
 */
export function boundDeviceList() {
    return request.get('api/memberDevice/boundDeviceList')
}

/**
 * 解绑设备
 */
export function untieDevice(deviceIdentity) {
    return request.get('api/memberDevice/untieDevice', { deviceIdentity })
}

/**
 * 查询wifi列表
 */
export function findWifiList(deviceIdentity) {
    return request.get('api/device/findWifiList/' + deviceIdentity, {})
}

/**
 * 删除wifi列表
 */
export function removeWifi(id, deviceIdentity) {
    return request.post('api/device/removeWifi/' + id + '?deviceId=' + deviceIdentity, {})
}

/**
 * 安全性字典
 */
export function getEncryptType() {
    return request.get('api/dict/t/encryptType', {})
}

/**
 * 添加wifi
 */
export function addWifi(data) {
    return request.post('api/device/addWifi', data)
}

/**
 * 修改wifi
 */
export function updateWifi(data) {
    return request.post('api/device/updateWifi', data)
}
