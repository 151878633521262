import request from "@/utils/request";

/**
 * 保存
 * @param data 数据
 * @returns 
 */
export function saveMemberAndDeviceData(data){
    return request.post("/api/collect/saveMemberAndDeviceData", data);
}

/**
 * 获取此成员的最新的填写记录信息
 * @param refMemberId 成员ID
 * @returns 
 */
export function getNewestDeviceData(refMemberId){
    return request.get("/api/collect/newest/deivceData", {
        refMemberId: refMemberId
    });
}

/**
 * 根据成员码查找对应成员的基本信息
 * @param memberBindCode 成员唯一码
 * @returns 
 */
export function getMemberInfoByBindCode(memberBindCode){
    return request.get("/api/collect/member/bindcode/info", {
        memberBindCode: memberBindCode
    });
}