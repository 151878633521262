import wx from 'wx';
import qs from 'qs'


/**
 * 导航到微信指定路径
 * @param path 微信Page路径
 * @param options 配置选项，可覆盖url属性，具体支持的属性参阅：https://developers.weixin.qq.com/miniprogram/dev/api/route/wx.navigateTo.html
 */
export function useNavigateTo(path: string, data: object = {}, options: object = {}){
    const newData = Object.assign({},{
        url: path,
    },options);
    newData.url += qs.stringify(data, {
        addQueryPrefix: true,
        arrayFormat: 'comma',
    })
    console.log("useNavigateTo", newData);
    wx.miniProgram.navigateTo(newData)
}
