
import { mapState } from 'vuex'
import {Options, Vue} from 'vue-class-component';
import { saveQuestionnaire, getQuestionnaireDetail } from "@/api/memberQuestionnaire";
import wx from "wx"
import {getCurrentUserInfo, getmyself, getUserInfo} from "@/api/index"

@Options({
    computed: mapState({
        questionnaireList: 'userQuestionnaireList',
    }),
    data(){
        return {
            onlyReadable: false,
            list: [],
            memberId: null,
            form: {
                id: undefined,
                // 症状数据
                jsonData: [],
                // 成员ID
                memberId: null,
                // 其他信息
                otherInfo: null,
                // 问卷类型：1疾病信息采集，2睡眠问卷采集
                type: null,
                // 填报进入方式：1注册后进入，2通过”我的“页面入口进入。
                entryMode: null,
            },
            // 用户信息
            userInfo: {},
        }
    },
    methods: {
        /**
         * 处理选择项
         * @param selectedValue 当前选择的值的对象
         * @param selectItems 根下面的可选择项列表
         * @param root 当前根对象
         * @param list 总列表
         * @param index 总列表的当前正在处理的索引
         * @param echo 回显数据模式
         */
        handlerSelectChnage(selectedValue, selectItems, root, list, index, echo){

            console.log("handlerSelectChnage", selectedValue, selectItems, root, list, index);

            if(list[index]._isChild){
                // 标记二级组件准备完成
                if(list[index]._readyComplete !== undefined && !list[index]._readyComplete){
                    list[index]._readyComplete = true;

                    this.$nextTick(function(){
                        // 清除初始化时携带的校验信息
                        this.$refs.form.resetValidation(root.name)
                    })
                }
                return;
            }

            // if(selectedValue.length === 0){
            //     console.log("当前未选中项哦",root.name)
            //     return;
            // }
            
            let values = Object.values(selectedValue)
            console.log(values);
            
            // 查找选中的一级项对象
            let selecteds = selectItems.filter(v => values.includes(v.value))
            console.log(selecteds);

            for (let i = 0; i < selecteds.length; i++) {
                // 一级选中项
                const item = selecteds[i];

                // 判断是否有子项。
                if('childs' in item){
                    if(item.selectedShowChild){
                        console.log("不进行展示子项的选择");
                        continue;
                    }

                    // 二级子级项列表
                    item.childs.forEach(childItem => {

                        let questionnaire = JSON.parse(JSON.stringify(childItem || '{}'))
                        questionnaire['selectedValueItems'] = []

                        // 如果有子项，判断是否已在列表中。
                        let exists = list.some(ele => ele.name === questionnaire.name)
                        
                        // 如果不在列表中，那么将其添加进去并设置初始对象。并添加一级对二级对象的引用。
                        if(!exists){
                            console.log("当前二级项不存在列表中", questionnaire,list);
                            // 设置之间的引用关系
                            if(item._refChilds && Array.isArray(item._refChilds)){
                                item._refChilds.push(questionnaire)
                            }else{
                                item._refChilds = [questionnaire]
                            }
                            questionnaire._refConditionItem = item;
                            questionnaire._refConditionItemBelongRoot = root;
                            questionnaire._isChild = true;
                            // 标记二级组件准备未完成，需要多确认一步。
                            // 原因是通过下面的方式新添加元素，不知为何，会触发onChanage事件，导致新添加入的数据触发了这个内容。
                            questionnaire._readyComplete = false;
                            // 不存在则添加子元素
                            list.splice(index + 1,0,questionnaire);
                            console.log("当前二级项添加成功", questionnaire,list);
                        }else{
                            console.log("当前二级项已存在，不做任何处理", questionnaire,list);
                        }

                    })

                }
            }

            
            // 如果在列表中，当前已取消勾选的。则进行清除二级项。
            let selectedChilds = list.filter(v => v._isChild);
            selectedChilds.forEach(ele => {
                console.log("当前二级项", ele);
                let refConditionItemBelongRoot = ele._refConditionItemBelongRoot
                let refConditionItem = ele._refConditionItem;
                
                let exists = refConditionItemBelongRoot.selectedValueItems.includes(refConditionItem.value)
                if(!exists){
                    // 该子项归属的父级触发项，如果不在已选择项列表中，将进行移除。
                    console.log("移除二级项", ele, "前置条件项", refConditionItem , "当前选中项", refConditionItemBelongRoot.selectedValueItems,"因为所属上级选择项处于未选中状态");
                    let targetIndex = list.indexOf(ele);
                    targetIndex > -1 && list.splice(targetIndex, 1)
                }

            })

        },
        submitMemberQuestionnaireSave(value: any){
            let memberId = this.memberId
            if(!memberId){
                this.$toast("会员ID不存在，非法操作哦")
                return
            }

            this.form.id = undefined

            this.form.memberId = memberId

            let list = this.list;

            // 删除不是需要组装数据的对象
            delete value['otherInfo']

            let keys = Object.keys(value)

            let result = [];


            for(let groupKey of keys) {
                let groupValue = value[groupKey]

                // 组下选中项的一级value值
                let selectedValueItems = Object.values(groupValue)

                // 组对象
                let group = list.find(v => v.name === groupKey)

                // 根据选中项进而获取组对象里的实际对象信息。
                let groupSelectedItems = group.selectItems.filter(v => selectedValueItems.includes(v.value));


                const groupResult = {
                    groupName: group.name,
                    selectedItems: []
                };

                // 处理子项的关系
                if(group._isChild){

                    let refConditionItemBelongRoot = group._refConditionItemBelongRoot
                    let refConditionItem = group._refConditionItem

                    // 添加对条件组的引用
                    // 前置条件组的组名，具有唯一值
                    groupResult['refGroupName'] = refConditionItemBelongRoot.name
                    // groupKey对象所属的前置条件组的可选项的执行项，便于回溯。
                    groupResult['refGroupValueItem'] = refConditionItem.value

                    // 增加标识字段，便于区分出顶级和子项的标识字段。
                    groupResult['isChild'] = true;
                }


                // 处理组下的选中值的对象
                groupSelectedItems.forEach(v => {
                    groupResult.selectedItems.push({
                        value: v.value
                    });
                })

                result.push(groupResult)

            }

            console.log("请求表单对象",result,JSON.stringify(result));
            
            this.form.jsonData = JSON.stringify(result);


            // 进入模式：
            //      1是通过注册用户填写信息时进入的。
            //      2是通过"我的"页面直接进入的
            //      3是通过"我的">"问卷调查列表页(疾病症状调查问卷)"进入的。
            const entryMode = this.form.entryMode;

            saveQuestionnaire(this.form).then(respData => {
                this.$toast.success({
                    message: "保存成功",
                    overlay: true,
                    forbidClick: true,
                    duration: 2500,
                    onClose: function(){
                        // 跳转到健康页面页面
                        // wx.miniProgram.switchTab({
                        //     url: '/pages/health/health',
                        // });

                        // wx.miniProgram.redirectTo({
                        //     url: '/pages/user/userQuestionnaireList/userQuestionnaireList',
                        // })

                        if(entryMode == 1){
                            wx.miniProgram.switchTab({
                                url: '/pages/health/health',
                            });
                        }else{
                            // 返回上一页。
                            wx.miniProgram.navigateBack();
                        }
                    }
                })

            }).catch(response => {
                this.$toast.fail(response.msg);
            })
            
        }

    },
    created(){
        let questionnaireList = JSON.parse(JSON.stringify(this.questionnaireList || '[]'))
        questionnaireList.forEach(v => {
            v['selectedValueItems'] = []
        })
        this.list = questionnaireList

        let {memberId, type, entryMode, 
            // 模式：detail详情
            mode, 
            id // 问卷主键ID
        } = this.$route.query;
        console.log("query", this.$route.query);
        

        if(mode === "detail"){

            if(!id){
                alert("无效问卷ID")
                return;
            }
            // 只读
            this.onlyReadable = true;

            this.form.id = id;

            getQuestionnaireDetail(id).then(data => {
                console.log("detail", data);

                // 处理普通字段
                this.form.otherInfo = data.otherInfo
                
                // 处理核心jsonData字段
                let selectedItemJSON = JSON.parse(data.jsonData)

                // 选中的项的映射对象，key为组名，子级则是需要通过(.)进行引用
                let selectedMapping = selectedItemJSON.reduce((preVal,curVal) => {
                    
                    if(curVal.isChild){
                        preVal[`${curVal.refGroupName}.${curVal.groupName}`] = curVal
                    }else{
                        preVal[curVal.groupName] = curVal
                    }

                    return preVal;
                }, {})

                
                let list = this.list;//.slice();

                for (let index = 0; index < list.length; index++) {
                    const item = list[index];

                    let groupName;
                    if(item._isChild){
                        groupName = `${item._refConditionItemBelongRoot.name}.${item.name}`
                    }else{
                        groupName = item.name
                    }

                    let selectedItem = selectedMapping[groupName]
                    
                    let selectedValueItems = selectedItem.selectedItems.reduce((app,val) => {
                        app.push(val.value);
                        return app;
                    }, []);

                    item['selectedValueItems'] = selectedValueItems
                    this.handlerSelectChnage(selectedValueItems, item.selectItems, item, list, index)
                }

            })
        }else{
            if(memberId){
                console.log("使用当前传递过来的成员ID", memberId);

                getUserInfo(memberId).then(res =>{
                    console.log("getUserInfo",res);
                    // 获取会员ID参数
                    this.memberId = res.id;
                    this.form.memberId = this.memberId

                    this.userInfo.userName = res.userName
                    console.log("使用当前传递过来的成员ID", res.id);
                }).catch(response => {
                    this.$dialog.alert({
                        message: response.msg,
                        confirmButtonText: '返回上一页',
                    }).then(() => {
                        // 返回上一页。
                        wx.miniProgram.navigateBack();
                    });
                })
            }else{
                // 获取当前用户的成员信息
                getmyself().then(res =>{
                    console.log("getmyself",res);
                    // 获取会员ID参数
                    this.memberId = res.id;
                    this.form.memberId = this.memberId

                    this.userInfo.userName = res.userName
                    console.log("使用当前用户的成员ID", res.id);
                })
            }
        }

        this.form.type = type || null;
        this.form.entryMode = entryMode || null;

    },  
    beforeRouteEnter(to, from) {
        document.body.style.backgroundColor="#eeeff4";
    },
    beforeRouteLeave(to, from) {
        document.body.style.backgroundColor=''
    },
})
export default class Questionnaire extends Vue {
    list!: any[];
    memberId!: string;
    groupChecked!: any[];
    submitMemberQuestionnaireSave!: Function;
    form: any;
}


