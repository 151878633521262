<template>
  <div>
    <TitleNavBar :titleName="titleName" />
    <template v-for="(item, index) in list" :key="index">
      <div class="member">
        <div class="imgBox fl">
          <img :src="item.url" alt="" />
        </div>
        <div class="username fl">{{ item.username }}</div>
        <div class="quesBtn fr">测评问卷</div>
      </div>
    </template>
  </div>
</template>

<script>
import TitleNavBar from "@/components/Navbar/titleNavBar.vue";

export default {
  data() {
    return {
      titleName: "成员管理",
      list: [
        {
          url:
            "https://www.keaidian.com/uploads/allimg/181230/co1Q230125205-0-12.jpg",
          username: "张三",
        },
        {
          url:
            "https://www.keaidian.com/uploads/allimg/181230/co1Q230125205-0-12.jpg",
          username: "李四",
        },
      ],
    };
  },
  components: {
    TitleNavBar,
  },
};
</script>

<style scoped lang='stylus'>
.member {
  width: 720px;
  height: 150px;
  border-bottom: 1px solid #ddd;
  margin: 0 auto;
  padding: 30px 0;
  box-sizing: border-box;

  .imgBox {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #333;
    overflow: hidden;

    img {
      width: 90px;
    }
  }

  .username {
    height: 90px;
    line-height: 90px;
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    padding-left: 35px;
  }

  .quesBtn {
    width: 162px;
    height: 66px;
    border: 1px solid #333;
    margin: 22.5px;
    border-radius: 20px;
    line-height: 66px;
    font-size: 28px;
  }
}
</style>