import request from "@/utils/request";

/**
 * 保存问卷
 * @param data 
 * @returns 
 */
export function saveQuestionnaire(data) {
    return request.post("/api/member/questionnaire/save",data);
}


/**
 * 获取指定成员的问卷数量
 * @param memberId 
 * @param type
 * @returns 
 */
export function getQuestionnaireCount(memberId, type) {
    return request.get("/api/member/questionnaire/count/byMemberId",{
        memberId,
        type
    });
}

export function listQuestionnaire(data){
    return request.get("/api/member/questionnaire/list",data);
}


export function getQuestionnaireDetail(id){
    return request.get("/api/member/questionnaire/detail/" + id);
}