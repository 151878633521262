
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
  props: {
    name: '',
    icon: '',
    iconColor: '',
    iconClass: '',
    img: '',
  },
  data() {
    return {
    }
  },
})
export default class IconButton extends Vue {
  // 按钮名称
  name!: string
  // 按钮Icon，优先级低于按钮图片
  icon!: string
  // 按钮Icon颜色
  iconColor!: string
  // 按钮Icon类名
  iconClass!: string
  // 按钮图片
  img!: string
}
