
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    score: Number,
    addZero: false,
  }
})
export default class ScoreNum extends Vue {
  score!: number
  addZero!: boolean
}
