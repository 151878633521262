<template>
  <div>
    <van-form>
      <van-field
        v-model="infoForm.userName"
        placeholder="请输入姓名"
        name="userName"
        label="姓名"
        input-align="right"
      />
      <van-field
        readonly
        clickable
        name="picker"
        v-model="sexCn"
        label="性别"
        placeholder="点击选择性别"
        @click="showSex = true"
        right-icon="arrow"
        input-align="right"
      />
      <van-popup v-model:show="showSex" position="bottom">
        <van-picker
          show-toolbar
          :columns="[
            { value: '男', zhName: '男' },
            { value: '女', zhName: '女' },
          ]"
          value-key="zhName"
          @confirm="onSexConfirm"
          @cancel="showSex = false"
        />
      </van-popup>
      <van-field
        v-model="infoForm.birthday"
        placeholder="请选择生日"
        name="birthday"
        label="生日"
        disabled
        @click="show = true"
        input-align="right"
      />
      <van-field
        v-model="infoForm.height"
        placeholder="请输入身高"
        name="height"
        label="身高"
        input-align="right"
      >
        <template #button>
          厘米
        </template>
      </van-field>
      <van-field
        v-model="infoForm.weight"
        placeholder="请输入体重"
        name="weight"
        label="体重"
        input-align="right"
      >
        <template #button>
          公斤
        </template>
      </van-field>
    </van-form>
    <van-button class="submit-btn" type="primary" @click="saveFn()" block round
      >提交</van-button
    >

    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="dateConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { improveUserInfo } from "@/api/user";
import { getmyself } from "@/api/index";
import { Toast } from "vant";
import moment from "moment";
import wx from "wx";
export default {
  name: "newUserInfo",
  data() {
    return {
      infoForm: {
        userName: "",
        sex: "",
        birthday: "",
        height: "",
        weight: "",
        userId: "",
      },
      show: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(1960, 5, 15),
      showSex: false,
    };
  },
  created() {
    if (this.$route.query.token) {
      localStorage.setItem('token', this.$route.query.token)
    }
    let userInfo = JSON.parse(this.$route.query.userInfo)
    getmyself().then((res) => {
      if (res.sex == '1') {
        res.sex = '男'
      }
      if (res.sex == '2') {
        res.sex = '女'
      }
      this.infoForm = res || {
        userName: "",
        sex: "",
        birthday: "",
        height: "",
        weight: "",
        userId: "",
      };
      if (this.infoForm.birthday) {
        this.infoForm.birthday = moment(this.infoForm.birthday).format("YYYY-MM-DD")
      }
      this.infoForm.avatar = userInfo.avatar;
      // this.infoForm.userName = userInfo.userName;
      this.infoForm.sex = userInfo.sex;
    });
  },
  computed: {
    currentDateStr: function() {
      let year = "";
      let month = "";
      let day = "";
      if (this.currentDate) {
        year = this.currentDate.getFullYear();
        month = this.currentDate.getMonth() + 1;
        day = this.currentDate.getDate();
      }
      return year + "/" + month + "/" + day;
    },
    sexCn: function() {
      return this.infoForm.sex
    },
    heightCheck() {
      return this.infoForm.height >= 50 && this.infoForm.height <= 300;
    },
    weightCheck() {
      return this.infoForm.weight >= 10 && this.infoForm.weight <= 300;
    },
  },
  methods: {
    dateConfirm(value) {
      this.show = false;
      this.currentDate = value;
      this.infoForm.birthday = moment(this.currentDate).format("YYYY-MM-DD");
    },
    saveFn() {
      if (!this.infoForm.userName) {
        Toast("请填写姓名");
        return;
      }
      if (
        this.infoForm.userName.replace(/[^\\u0000-\u00ff]/, "aa").length > 10
      ) {
        Toast("姓名过长");
        return;
      }
      if (this.infoForm.sex === "" || this.infoForm.sex === null) {
        Toast("请选择性别");
        return;
      }
      if (!this.infoForm.birthday) {
        Toast("请选择生日");
        return;
      }
      if (!this.infoForm.height) {
        Toast("请填写身高");
        return;
      }
      if (this.infoForm.height.toString().indexOf(".") != -1) {
        if (
          this.infoForm.height.toString().length -
            this.infoForm.height.toString().indexOf(".") >
          2
        ) {
          Toast("身高最多支持一位小数");
          return;
        }
      }
      if (!this.heightCheck) {
        Toast("身高信息范围阈值应在50cm~300cm之间");
        return;
      }
      if (!this.infoForm.weight) {
        Toast("请填写体重");
        return;
      }
      if (this.infoForm.weight.toString().indexOf(".") != -1) {
        if (
          this.infoForm.weight.toString().length -
            this.infoForm.weight.toString().indexOf(".") >
          2
        ) {
          Toast("体重最多支持一位小数");
          return;
        }
      }
      if (!this.weightCheck) {
        Toast("体重信息范围阈值应在10kg~300kg之间");
        return;
      }
      improveUserInfo(this.infoForm).then(() => {
        Toast("个人信息已保存");
        setTimeout(() => {
          wx.miniProgram.switchTab({
            url: "/pages/index/index",
          });
        }, 1500);
      });
    },
    onSexConfirm(value) {
      console.log(value);
      this.infoForm.sex = value.value;
      this.showSex = false;
    },
  },
};
</script>

<style scoped lang="stylus">
/deep/ .van-field--disabled .van-field__label
  color: #646566;

.submit-btn
  width calc(100vw - 100px)
  margin 120px 50px 30px
  background-image: linear-gradient(to right, #8b64e5, #563bc8);
  border none
</style>
