import request from "@/utils/request";

/**
 * 添加成员
 */
export function addMember(member) {
  return request.post('/api/user/addMember', member, {login: true})
}

/**
 * 扫码绑定人员
 */
export function bind(bindCode) {
  return request.get('/api/user/bind', { bindCode }, {login: true})
}

/**
 * 已绑定人员列表
 */
export function bindUserList() {
  return request.get('/api/user/bindUserList', {}, {login: true})
}

/**
 * 发送验证码
 */
export function smsCode(phone) {
  return request.get('/api/smsCode/REGISTER?phone=' + phone, {login: true})
}

/**
 * 校验验证码
 */
export function checkSmsCode(phone, smsCode) {
  return request.get('/api/smsCode/checkSmsCode/REGISTER', { phone, smsCode }, {login: true})
}

/**
 * 解绑人员
 */
 export function untie(memberId) {
  return request.get('/api/user/untie/' + memberId, {}, {login: true})
}

/**
 * 扫码添加设备
 */
export function bindDevice(data) {
  return request.post('/api/memberDevice/bindDevice/', data, {login: true})
}

/**
 * 解绑设备
 */
export function untieDevice(deviceIdentity) {
    return request.get('api/memberDevice/untieDevice', { deviceIdentity })
}

/**
 * 查询wifi列表
 */
export function findWifiList(deviceId) {
    return request.get('api/device/findWifiList/' + deviceId, {})
}

/**
 * 删除wifi
 */
export function removeWifi(id, deviceId) {
    return request.post('api/device/removeWifi/' + id + '?deviceId=' + deviceId, {})
}

/**
 * 切换当前人员
 */
 export function changeCurrentMember(memberId) {
  return request.get('api/user/changeCurrentMember' + '?memberId=' + memberId, {})
}


/**
 * 根据成员ID查找wifi列表
 * @param memberId 成员ID
 * @returns 
 */
export function findMemberWifiListByMemberId(memberId) {
  return request.get('api/device//findWifiList/by/member', {
    memberId: memberId
  })
}


/**
 * 根据设备码查询设备wifi列表
 * @param deviceId 设备码
 * @returns 
 */
export function findDeviceWifiListByDevice(deviceId) {
  return request.get('api/device/findWifiList/by/device/' + deviceId, {})
}

/**
 * 获取指定成员的基本信息
 * @returns 成员的基本信息
 */
export function getMemberBaseInfo(memberId: number){
  return request.get(`api/member/${memberId}/baseInfo`, {})
}