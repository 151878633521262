<template>
  <van-notice-bar mode="link" @click="showHint">什么是WiFi设置？</van-notice-bar>
  <van-popup
      v-model:show="show"
      closeable
      close-icon="close"
      :style="{ width:'80vw', }"
      :round="true"
  >
    <div class="popupTitle">WIFI状态</div>
    <div class="popupContent">
      <div class="citem">添加WiFi成功后在WiFi列表增加一条记录，此时WiFi状态为已提交，说明你添加的WiFi已经提交到服务器，正在等待验证。</div>
      <div class="citem">若手表连接WiFi成功，WiFi列表的状态展示为已验证，说明你添加的WiFi正在使用中。</div>
      <div class="citem">若手表WiFi显示为未连接，此WiFi列表显示该WiFi状态为验证失败，说明此WiFi不可用。</div>
    </div>
  </van-popup>

  <div class="clearfix" v-if="wifitList.length < 3">
    <van-button icon="plus" type="primary" round size="small" class="add-btn fr" color="linear-gradient(to right,#7d50e3,#5d45ca)" @click="toAdd">添加WiFi</van-button>
  </div>
  <NoData v-if="!wifitList.length"></NoData>
  <div class="wifi-list card-universal" v-else>
    <!-- 点击编辑，左滑删除 -->
    <div class="wifi-item clearfix" v-for="item in wifitList" :key="item.id">
      <van-swipe-cell>
        <div class="clearfix" @click="editWifi(item)">
          <van-image :src="require('@/assets/img/equipment/wifi.png')" class="wifi-img fl"></van-image>
          <div class="wifi-name fl">{{ item.name }}</div>
          <!-- 0 验证失败，1已验证，2 已提交 -->
          <div class="wifi-status fr">{{ item.status == 0 ? '验证失败' : item.status == 1 ? '已验证' : item.status == 2 ? '已提交' : '' }}</div>
        </div>
        <template #right>
          <div class="del-btn" @click="delFn(item.id)">删除</div>
        </template>
      </van-swipe-cell>
    </div>
  </div>
  <div class="wifi-tip" v-if="wifitList.length">
    <van-divider>※点击列表WIFI可进行设置，向左滑动删除当前WIFI</van-divider>
  </div>
</template>

<script>
import NoData from '@/components/noData/NoData.vue'
import {boundDeviceList, findWifiList, removeWifi, untieDevice} from "@/api/equipmentSet";
import { Dialog, Toast } from 'vant';
import wx from "wx";
import {  findDeviceWifiListByDevice,findMemberWifiListByMemberId } from "@/api/member";

export default {
  components: { NoData },
  data() {
    return {
      titleSet: `Wifi设置`,
      show: false,
      wifitList: [],
      deviceIdentity: '',
    };
  },
  created() {
    boundDeviceList().then(resData => {
      this.deviceIdentity = resData[0]?.deviceIdentity
      findMemberWifiListByMemberId(resData[0]?.memberId).then(data => {
        this.wifitList = data
      })
    })
  },
  methods: {
    showHint() {
      this.show=true
    },
    delFn(id) {
      let _this = this
      Dialog.confirm({
        title: '确定删除吗？',
      }).then(res => {
        console.log(res)
        if (res === 'confirm') {
          console.log(id)
          removeWifi(id, _this.deviceIdentity).then(res => {
            Toast('删除成功')
            findWifiList(_this.deviceIdentity).then(data => {
              _this.wifitList = data
            })
          }).catch(err => {
            Toast(err?.msg || '删除失败')
          })
        }
      });
    },
    toAdd() {
      wx.miniProgram.navigateTo({
        url: '/pages/health/equipmentSet/wifiEdit/wifiEdit',
      });
    },
    editWifi(data) {
      wx.miniProgram.navigateTo({
        url: '/pages/health/equipmentSet/wifiEdit/wifiEdit?editData=' + JSON.stringify(data),
      });
    },
  },
};
</script>

<style scoped lang="stylus">
:deep .van-popup__close-icon--top-right
  top: 11px;
  right: 16px;

.popupTitle
  height 50px
  border-bottom 1px solid #eee
  text-align left
  font-size 32px
  padding 22px 34px 10px
  line-height 50px
  font-weight 600

.popupContent
  padding 8px 30px 30px
  font-size 30px
  text-align left
  .citem
    margin-top 12px
    text-indent 60px

.wifi-list
  margin-top 30px
  margin-bottom 30px
  .wifi-item
    padding 20px 0
    font-size 30px
    line-height 50px
    border-bottom: 1px solid #eee
    &:nth-last-child(1)
      border-bottom: none
    .wifi-img
      width: 50px
      height: 50px
    .wifi-name
      margin-left: 16px
    .wifi-status
      color #999
    .del-btn
      margin-left: 10px
      background linear-gradient(to right, #ff6034, #ee0a24)
      color #fff
      font-size 27px
      padding: 0 16px
      border-radius 12px

.add-btn
  margin-top: 30px
  margin-right: 30px

.wifi-tip
  margin-top 100px

</style>