import request from "@/utils/request";

/**
 * 是否具备特殊功能的权限
 * @returns {*}
 */
 export function hasFunction(userId: number, funCode: string) {
  return request.get("/api/user/special/function/has/function",{
    userId:  userId,
    funCode: funCode,  
  });
}
