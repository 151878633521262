
import {Options, Vue} from 'vue-class-component';

import TrendItem from '@/components/report/TrendItem.vue';

@Options({
  components: {
    TrendItem,
  },
  data: () => {
    return {
      active: 0
    }
  }
})
export default class Trend extends Vue {
}
