
import { Options, Vue } from 'vue-class-component';
import {getmyself, getReportDetail} from "@/api/index";
import ScoreNum from '@/components/report/ScoreNum.vue';
import ExpertSubTitleItem from '@/components/report/ExpertSubTitleItem.vue';
import PersonInfoToast from '@/components/toast/PersonInfoToast.vue';
import ProductItem from '@/components/product/ProductItem.vue';
import Disclaimers from '@/components/report/Disclaimers.vue';
import {fullCategoryDetail} from '@/api/report'

@Options({
  components: {
    ScoreNum,
    ExpertSubTitleItem,
    PersonInfoToast,
    ProductItem,
    Disclaimers,
  },
  data() {
    return {
      reportData: {},
      panel1: [],
      panel1$1: [],
      panel2: [],
      panel3: [],
      panel4: ['1'],

      dataList: [],
      report: null,
      memberInfo: null
    }
  },
  created() {
    document.title = '健康状态测评分析报告'
    getReportDetail(this.$route.params.reportId).then((res: any) => {
      this.reportData = res
    })
    const reportId = this.$route.params.reportId
    this.getData(reportId)
    getmyself().then(res =>{
      if (res.sex == '1') {
        res.sex = '男'
      }
      if (res.sex == '2') {
        res.sex = '女'
      }
      this.memberInfo = res
    })
  },
  methods: {
    getData(reportId) {
      fullCategoryDetail(reportId).then(item =>{
        this.report = item.report
        this.dataList = item.dataList
        console.log(this.report)
      })
    },
    getColorClassName() {
      return 'red'
    },
  },
})
export default class Index extends Vue {
  report!: any
}
