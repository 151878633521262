
import { Options, Vue } from 'vue-class-component';
import { getMemberDetail, getExpertReportDetail } from "@/api/index";
import ScoreNum from '@/components/report/ScoreNum.vue';
import ReportSubTitleItem from '@/components/report/ReportSubTitleItem.vue';
import PersonInfoToast from '@/components/toast/PersonInfoToast.vue';
import Disclaimers from '@/components/report/Disclaimers.vue';

@Options({
  components: {
    ScoreNum,
    ReportSubTitleItem,
    PersonInfoToast,
    Disclaimers,
  },
  data() {
    return {
      memberData: {},
      heartReportData: {},
      liverReportData: {},
      kidneyReportData: {},
      avatarDefault: require('@/assets/img/icon/avatar.png'),
      loading: false,
    }
  },
  created() {
    document.title = '五藏测评报告'

    let tasks = [
      getMemberDetail(this.$route.params.memberId).then((res: any) => {
        this.memberData = res
      }),
      getExpertReportDetail(this.$route.params.reportId, 1).then((res: any) => {
        this.heartReportData = res
      }),
      getExpertReportDetail(this.$route.params.reportId, 2).then((res: any) => {
        this.liverReportData = res
      }),
      getExpertReportDetail(this.$route.params.reportId, 3).then((res: any) => {
        this.kidneyReportData = res
      }),
    ]

    Promise.allSettled(tasks).finally(()=>{
      this.loading = false;
    })

  },
  methods: {
    onRefresh  ()  {
      location.reload();
    },
  },
})
export default class ExpertReport extends Vue {}
