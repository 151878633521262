
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        dataList: {
            type: Array,
            default(){
                return [];
            }
        },
        dotSuccColor: {
            type: String,
            default: '#8af564',
        },
        dotFailColor: {
            type: String,
            default: 'red',
        },
        dotActiveColor: {
            type: String,
            default: '#0077ff',
        },
        dotInactiveColor: {
            type: String,
            default: '#969799',
        },
        lineSuccColor: {
            type: String,
            default: '#8af564',
        },
        lineFailColor: {
            type: String,
            default: 'red',
        },
        lineActiveColor: {
            type: String,
            default: '#0077ff',
        },
        lineInactiveColor: {
            type: String,
            default: '#969799',
        },
        defaultActiveStepIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            datqListMap: {},
            activeStepIndex: null,
        }
    },
    watch: {
        activeStepIndex(newVal){
            if(newVal >= this.dataList.length){
                this.$emit("stepsComplete", newVal)
            }
        }
    },
    methods: {

        getDotStyle(index){

            let isSuccess = this.datqListMap[index]?.isSuccess || false;
            let isFail = this.datqListMap[index]?.isFail || false;

            const defineColor = {
                backgroundColor: this.activeStepIndex > index ? this.dotSuccColor :
                                 this.activeStepIndex === index ? this.dotActiveColor : this.dotInactiveColor,

                transform: this.activeStepIndex === index ? 'scale(1.6)' : 'unset'
            };

            if(isSuccess){
                defineColor.backgroundColor = this.dotSuccColor
            }

            if(isFail){
                defineColor.backgroundColor = this.dotFailColor
            }

            return defineColor;
        },
        getLineStyle(index){

            const defineColor = {
                backgroundColor: this.activeStepIndex > index ? this.lineSuccColor :
                                 this.activeStepIndex === index ? this.lineActiveColor : this.lineInactiveColor,
            };
            
            let isSuccess = this.datqListMap[index]?.isSuccess || false;
            let isFail = this.datqListMap[index]?.isFail || false;

            if(isSuccess){
                defineColor.backgroundColor = this.lineSuccColor
            }

            if(isFail){
                defineColor.backgroundColor = this.lineFailColor
            }

            return defineColor;
        },
        loading(index = null){
            index = index || this.activeStepIndex

            this.datqListMap[index] = this.datqListMap[index] || {};
            this.datqListMap[index].loading = true;
            this.datqListMap[index].loadComplete = false;
        },
        loadComplete(index = null){
            index = index || this.activeStepIndex

            this.datqListMap[index] = this.datqListMap[index] || {};
            this.datqListMap[index].loading = false;
            this.datqListMap[index].loadComplete = true;
        },
        asSuccess(index = null){
            index = index || this.activeStepIndex

            this.datqListMap[index] = this.datqListMap[index] || {};
            this.datqListMap[index].isSuccess = true;
            this.datqListMap[index].isFail = false;
        },
        asFail(index = null){
            index = index || this.activeStepIndex

            this.datqListMap[index] = this.datqListMap[index] || {};
            this.datqListMap[index].isFail = true;
            this.datqListMap[index].isSuccess = false;
        },
        showBody(index = null) {
            index = index || this.activeStepIndex

            this.datqListMap[index] = this.datqListMap[index] || {};
            this.datqListMap[index].showBody = true;
        },
        nextStep(index = null){
            this.activeStepIndex = index || this.activeStepIndex + 1;
            console.log("nextStep", this.activeStepIndex, "index",index)
        }

    },
    created() {
        // TODO 

        this.activeStepIndex = this.defaultActiveStepIndex;

    },
})
export default class StatusStepsComponent extends Vue {

}

