<template>
  <div>
    <TitleNavBar :titleName="titleName" />
    <template v-for="(item, index) in list" :key="index">
      <div class="listBox">
        <div class="listBox-content">
          <p class="list-title">{{ item.title }}</p>
          <p class="list-date">{{ item.date }}</p>
        </div>
        <div class="listBox-icon ">
            <van-icon name="arrow" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TitleNavBar from "@/components/Navbar/titleNavBar.vue";

export default {
  data() {
    return {
      titleName: "健康报告",
      list: [
        {
          title: "xxx健康报告",
          date: "2020-18-18",
        },
        {
          title: "xxx健康报告",
          date: "2020-18-18",
        },
      ],
    };
  },
  components: {
    TitleNavBar,
  },
};
</script>

<style scoped lang='stylus'>
.listBox {
  width: 700px;
  height: 160px;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
  margin: 30px auto 0;

  .listBox-content {
    width: 650px;
    height 160px
    float: left;
    text-align left 
    box-sizing border-box
    .list-title{
        width 100%
        height 95px
        font-size 36px
        line-height 95px
        padding-left 20px
        box-sizing border-box
        margin 0
        color #333
    }
    .list-date{
        width 100%
        font-size 28px
        padding-left 20px
        box-sizing border-box
        margin 0
        color #a0a0a0
    }
  }
  .listBox-icon{
      width 40px
      height 160px
      float left 
      line-height 160px
      font-size 36px
      color #a0a0a0
  }
}
</style>