import ClipboardJS from 'clipboard'
import { Toast } from 'vant';


export function copyContent(el,options={}){
    let clipboard = new ClipboardJS(el, options)
    clipboard.on('success', e => {
        Toast('复制成功');
        // 释放内存
        clipboard.destroy();
    })
    clipboard.on('error', e => {
        console.error('复制失败:', e);
        // 不支持复制
        Toast.fail('复制失败');
        // 释放内存
        clipboard.destroy()
    })
    el.click();
}
