
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import '../plugins/rem.js'
import './assets/css/reset.styl'
import './assets/css/variable.styl'
import {
  PullRefresh,
  Badge,
  ActionSheet,
  Image,
  NoticeBar,
  List,
  Swipe,
  SwipeItem ,
  Button,
  Collapse,
  CollapseItem,
  Empty,
  Skeleton,
  Icon,
  Popup,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Form,
  Field,
  Toast,
  NavBar,
  Cell,
  DatetimePicker,
  Lazyload,
  Picker,
  Grid,
  GridItem,
  Circle ,
  Progress ,
  Uploader,
  Dialog,
  Image as VanImage,
  Col,
  Row,
  RadioGroup,
  Radio,
  SwipeCell,
  CellGroup,
  Loading,
  Divider,
  Checkbox,
  CheckboxGroup,
  Card,
  Sticky,
  Overlay,
  CountDown,
  Switch,
  Notify,
  Tag,
  Search,
  Popover,
} from 'vant';


const app = createApp(App)
app.use(store).use(router).mount('#app')
app.use(Button);
app.use(Uploader);
app.use(ActionSheet);
app.use(PullRefresh)
app.use(Image);
app.use(NoticeBar);
app.use(List);
app.use(Collapse);
app.use(CollapseItem);
app.use(Empty);
app.use(Skeleton);
app.use(Icon);
app.use(Swipe);
app.use(SwipeItem)
app.use(Popup);
app.use(Tabbar);
app.use(Lazyload);
app.use(Badge)
app.use(TabbarItem);
app.use(VanImage);
app.use(Tab);
app.use(Tabs);
app.use(Form);
app.use(Field);
app.use(Toast);
app.use(NavBar);
app.use(Cell);
app.use(Dialog)
app.use(DatetimePicker);
app.use(Picker);
app.use(Grid);
app.use(GridItem);
app.use(Circle);
app.use(Progress);
app.use(Col);
app.use(Row);
app.use(Radio);
app.use(RadioGroup);
app.use(SwipeCell);
app.use(CellGroup);
app.use(Loading);
app.use(Divider);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Card);
app.use(Sticky);
app.use(Overlay);
app.use(CountDown);
app.use(Switch);
app.use(Notify);
app.use(Tag);
app.use(Search);
app.use(Popover);

app.config.globalProperties.$common = {
  formatDate(value, format = 'YYYY-MM-DD hh:mm') {
    try {
      return moment(value).format(format)
    } catch {
      return '-'
    }
  },
}

app.config.warnHandler = function (msg) {
  if (!msg.includes('Avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders.')) { // uniApp bug: https://ask.dcloud.net.cn/question/71966
    return console.warn && console.warn(msg)
  }
}
  