
import { Options, Vue } from 'vue-class-component';
import ScoreNum from '@/components/report/ScoreNum.vue';

@Options({
  components: {
    ScoreNum,
  },
  props: {
    data: []
  }
})
export default class ExpertSubTitleItem extends Vue {
  data!: any
}
