
import { Options, Vue } from 'vue-class-component';      
import ScoreNum from '@/components/report/ScoreNum.vue';
import { number } from 'echarts';

@Options({
  emits:['showDescPopup'],
  props: {
    imgList: [],
    codeList: [],
    styleView: {
      type: Number,
      // 0是默认风格
      default: 0,
    }
  },
  components: {
    ScoreNum,
  },
  data() {
    return {
    }
  },
  methods: {

    /**
     * 打开Popup
     */
    openMeaningPopup(item,dom, type){
      if(type === 2){
        item.titleIsHtml = false;
        item.contentIsHtml = true;
      }
      this.$emit('showDescPopup',item,dom)
    }

  },
})
export default class ReportSubTitleItem extends Vue {
}
