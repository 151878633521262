<template>
  <div>
    <div class="top-box clearfix">
      <!-- <van-button class="fl" icon="plus" type="default" round @click="addBind">手动添加成员</van-button> -->
      <van-button class="fl" icon="scan" type="default" round @click="scanBind">扫码添加成员</van-button>

      <van-button class="fr" icon="plus" type="default" round @click="addBind">手动添加成员</van-button>
    </div>
    <van-divider>※向左滑动删除当前成员</van-divider>
    <template v-if="loadFinished">
      <template v-for="(item, index) in list" :key="index">
        <van-swipe-cell>
          <div class="member clearfix">
            <div class="imgBox fl">
              <van-image
                round
                fit="cover" class="avatar__wrapper"
                :src="item.avatar && item.avatar || defaultAvatar"
              />
              <div class="online" v-if="item.wearing == 1"></div>
            </div>
            <div class="username fl">
              {{ item.userName }}
              <span style="position: relative;top: -1px;">
                <van-tag round color="#efefef" text-color="rgb(159, 102, 204)">UID： {{ item.uid }}</van-tag>
              </span>
            </div>
            <!-- <div class="quesBtn fr" v-if="item.wearing == 1" @click="toEquipment(item.id, item.imei)">设备管理</div>
            <div class="quesBtn fr" v-else @click="bindDeice(item.id)">绑定设备</div> -->
            <!-- <div class="quesBtn fr purple">趋势报告</div> -->
            <div class="quesBtn fr blue" @click="toReportList(item.id)">
              测评报告
            </div>
          </div>
          <template #right>
            <div class="del-btn" @click="delFn(item.id)">删除</div>
          </template>
        </van-swipe-cell>
      </template>
    </template>
    <van-overlay v-else :show="true">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
        <van-loading  color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import wx from "wx";
import { bindUserList, untie, bindDevice, bind } from "@/api/member";

export default {
  data() {
    return {
      loadFinished: false,
      titleName: "成员管理",
      list: [],
      defaultAvatar: require('@/assets/img/icon/user/默认头像@2x.png'),
    };
  },
  components: {},
  created() {
    if (this.$route.query.memberDeviceIsScanSkip === "true") {
      if (this.$route.query.memberDeviceScanCode === "err") {
        Toast("请扫描正确的二维码");
        bindUserList().then((resData) => {
          this.list = resData || [];
        }).finally(v=>{
          this.loadFinished = true;
        });
      } else {
        bindDevice({
          memberId: this.$route.query.bindMemberId,
          deviceIdentity: this.$route.query.memberDeviceScanCode,
        })
          .then(() => {
            Toast("绑定成功");
            bindUserList().then((resData) => {
              this.list = resData || [];
            }).finally(v=>{
              this.loadFinished = true;
            });
          })
          .catch((err) => {
            console.log("err///////", err);
            Toast(err?.msg || "绑定失败");
            bindUserList().then((resData) => {
              this.list = resData || [];
            }).finally(v=>{
              this.loadFinished = true;
            });
          });
      }
    } else {
      bindUserList().then((resData) => {
        this.list = resData || [];
      }).finally(v=>{
        this.loadFinished = true;
      });
    }
    if (this.$route.query.memberPageIsScanSkip === "true") {
      if (this.$route.query.memberPageScanCode === "err") {
        Toast("请扫描正确的二维码");
        bindUserList().then((resData) => {
          this.list = resData || [];
        }).finally(v=>{
          this.loadFinished = true;
        });
      } else {
        bind(this.$route.query.memberPageScanCode)
          .then(() => {
            Toast("绑定成功");
            bindUserList().then((resData) => {
              this.list = resData || [];
            }).finally(v=>{
              this.loadFinished = true;
            });
          })
          .catch((err) => {
            console.log("err///////", err);
            Toast(err?.msg || "绑定失败");
            bindUserList().then((resData) => {
              this.list = resData || [];
            }).finally(v=>{
              this.loadFinished = true;
            });
          });
      }
    } else {
      bindUserList().then((resData) => {
        this.list = resData || [];
      }).finally(v=>{
        this.loadFinished = true;
      });
    }
  },
  methods: {
    delFn(id) {
      Dialog.confirm({
        title: "确定删除吗？",
      }).then((res) => {
        console.log(res);
        if (res === "confirm") {
          console.log(id);
          untie(id, this.deviceIdentity).then(res => {
            Toast('删除成功', res)
            bindUserList().then((resData) => {
              this.list = resData || [];
            });
          }).catch(err => {
            Toast(err?.msg || '删除失败')
          })
        }
      });
    },
    toReportList(id) {
      wx.miniProgram.navigateTo({
        url: "/pages/member/reportList/reportList?id=" + id,
      });
    },
    scanBind() {
      wx.miniProgram.redirectTo({
        url: "/pages/member/bindMember/bindMember?url=memberList",
      });
    },
    addBind() {
      wx.miniProgram.navigateTo({
        url: "/pages/member/addMember/addMember",
      });
    },
    toEquipment(id, imei) {
      wx.miniProgram.redirectTo({
        url: "/pages/member/equipment/equipment?imei=" + imei + '&id=' + id,
      });
    },
    bindDeice(id) {
      wx.miniProgram.redirectTo({
        url: "/pages/member/equipment/bindDevice/bindDevice?id=" + id,
      });
    },
  },
};
</script>

<style scoped lang="stylus">
@import '../css/member.styl'


.avatar__wrapper {
  width: 80px;
  height: 80px;
}

</style>
