<template>
 <div>
    <van-nav-bar
      title="血糖"
      left-arrow="true"
      @click-left="onClickLeft"
    ></van-nav-bar>
  </div>
  <div class="selectionTime">
    <div class="left"><van-icon name="arrow-left" /></div>
    <div class="content" is-link @click="showPopup">
      {{ TodayDate }}
    </div>
    <div class="right"><van-icon name="arrow" /></div>
  </div>
    <div class="content1">
    <div class="dateBox">10:42</div>
    <div class="numBox">
      &nbsp;&nbsp;&nbsp;5.1<span class="unitBox">mmol/L</span>
    </div>

    <div class="progressBarBox">
      <span class="progressBarTltle">最近一次血糖值</span>
    </div>

  </div>
  <div class="importBtn">
      <div class="import" @click="goToUrl('bloodGlucoseWrite')">手动输入</div>
      <div class="import" @click="goToUrl('bloodGlucoseHistory')">历史记录</div>
  </div>
  <van-popup
    v-model:show="show"
    position="bottom"
    :close-on-click-overlay="false"
  >
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      title="选择年月日"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="setTime"
      @cancel="cancelTime"
  /></van-popup>
</template>

<script>
import { ref } from "vue";
export default {
     setup() {
    const currentDate = ref(new Date());
    return {
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate,
    };
  },
  data() {
    return {
      show: false,
      TodayDate: "",
    };
  },
  mounted() {
    this.TodayDate = `${this.currentDate.getFullYear()}年${
      this.currentDate.getMonth() + 1
    }月${this.currentDate.getDate()}日`;
  },
  methods: {
    goToUrl(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
    showPopup() {
      this.show = true;
    },
    setTime(e) {
      console.log(e.getFullYear());
      this.TodayDate = `${e.getFullYear()}年${
        e.getMonth() + 1
      }月${e.getDate()}日`;

      this.show = false;
    },
    cancelTime() {
      this.show = false;
    },
  },
};


</script>
<style scoped lang="stylus">
.selectionTime {
  display: flex;
  width: 100%;
  font-size: 32px;
  padding: 20px 0;
  flex-wrap: nowrap;
  line-height: 40px;

  .left {
    flex: 1;
  }

  .content {
    font-size 26px
    color #ccc
    flex: 4;
  }

  .right {
    flex: 1;
  }
}

.ecartsBox {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 300px;
}
.content1 {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;

  .dateBox {
    color: #aaa;
    font-size: 28px;
    margin-bottom : 20px;
  }

  .numBox {
    font-size: 100px;
  }

  .unitBox {
    font-size: 30px;
  }

  .progressBarTltle {
    font-size: 28px;
    color: #aaa;
  }

  .progressBar {
    width: 80%;
    height: 8px;
    box-sizing: border-box;
    margin: 20px auto;
    background-image: linear-gradient(to right, green, yellow, orange, red);
  }
}
.cellContentBox {
  float: left;
}
.cellTitle{
    line-height 40px
    height 40px
    font-size 32px
    font-weight: 700;
}
.cellContent{
    line-height 40px
    height 40px
    color #aaa
}
.leftDate{
    float right 
    height 80px
    line-height 80px
    padding-right 50px
    color #111
}
.importBtn
    width 100%
    box-sizing border-box
    padding 30px
    height 200px
   
    background-color #eee
    .import
        width 50%
        float left
        line-height 140px

</style>