<template>
  <div>
    <titleNavBar titleName="体重" />
    <SelectTimeCall @TodayDate="aaa" :time="'2021-11-22 10:22'" />
    <div class="contrntTitle">体重</div>
    <PickerAge @weightNum='weightNum' />
    <div class="affirmBtn">确认</div>
  </div>
</template>

<script>
import titleNavBar from "@/components/Navbar/titleNavBar.vue";
import SelectTimeCall from "@/components/SelectTimeCall";
import PickerAge from "@/components/docs";

export default {
  components: {
    titleNavBar,
    SelectTimeCall,
    PickerAge,
  },
  methods: {
    aaa(e) {
      console.log(e);
    },
    weightNum(e){
      console.log(e);
    }
  },
};
</script>

<style scoped lang="stylus">
.contrntTitle {
  width: 100%;
  height: 50px;
  background-color: #ccc;
  text-align: left;
  padding: 0 40px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 26px;
  line-height: 50px;
}
</style>