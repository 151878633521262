<template>
  <div>
    <!-- <TitleNavBar :titleName="titleName" @click="goff()"/> -->
    
    <textarea  v-model="textdata" name="name"  rows="2" cols="80" maxlength="100" class="test-textarea" placeholder="请写下您对小程序的感受，我们将认真听取您的意见，努力提供更优质的服务。"></textarea>
    <div class="uploadImg">
     <div>上传图片（最多6张，文件大小上限为10Mb)</div>
     <br/>
      <van-uploader v-model="fileList" :after-read="uploadImgs" accept="image/*" :max-count="6" :max-size="1024*1024*10" @oversize="onOversize" multiple />
    </div>
    <van-button block type="info" @click="submit" style="margin: 0 auto;;width:90%;border-radius: 10px;margin-top:20px" native-type="submit" color="#5388fc">提交</van-button>
  </div>


</template>

<script>
// import TitleNavBar from "@/components/Navbar/titleNavBar.vue";
import { Toast } from 'vant';
import axios from 'axios';
import wx from 'wx';
import { saveAppSuggestFeeback } from '@/api/AppSuggestFeeback'

export default {
  data() {
    return {
      titleName: "意见反馈",
      weight: "",
      textdata:null,
      fileUrl:"",
      userId:this.$route.query.userId,
      fileList: [
      ],
      updaloadFile:[]
    };
  },
  // components: {
  //   TitleNavBar,
  // },
  methods: {
    //关闭弹窗
    onConfirm() {
      this.disPlaycaender = false;
    },
    onOversize() {
      Toast('文件大小不能超过10Mb');
    },
    /*上传图片*/
    uploadImgs(file,name) {
      const data = new FormData();    
      const index = name; 
      data.append("file", file.file);
       axios.post('/api/file/upload/relative/SUGGEST',data
      ).then((res) => {
       if(res.data.code == 200){
            this.updaloadFile.push({index:index.index,url:res.data.data})
          }
      }).catch(function (error) {
        console.log(error);
      });
      // this.$http.post(`/api/file/upload/`+"suggest",data,
      //   {
      //    headers: {
      //     "Content-Type": "multipart/form-data"
      //   }
      // }).then(res => {
      //     if (res.code == 200) {
      //     this.updaloadFile.push({index:index.index,url:res.data})
      //     }
      // });
    },
    goff(){
        this.$router.back()
    },
    submit(){
       this.updaloadFile.forEach(item =>{
         this.fileUrl = this.fileUrl + item.url+","
       })
       if(this.textdata == null || this.textdata == ''){
          Toast.fail("反馈内容必须填写")
       }else{

        saveAppSuggestFeeback({
            content:this.textdata,
            files:this.fileUrl,
            // 这里的userId是其他页面传递的。findMySelf.id
            // userId:this.userId
          }).then(data => {
            Toast.success({
              message: '提交成功',
              onOpened:function(){
                wx.miniProgram.navigateBack()
              }
            });
          }).catch(function (error) {
            console.log(error);
          });
        
       }
    }
  },
};
</script>

<style scoped lang='stylus'>

textarea::-webkit-input-placeholder {
          /* placeholder字号  */
          font-size: 30px;
          line-height:1.5

        }
.test-textarea {

      display: block;
      width: 90%;
      height: 200px;
      line-height: 30px;
      padding: 10px;
      margin: 0 auto;
      margin-top : 40px;
      border: 1px solid #ddd;
      border-radius: 6px;
      font-size: 30px;
      word-wrap: break-word;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-user-modify: read-write-plaintext-only;
}
.uploadImg{
  width :90%;
  margin 0 auto;
  margin-top 30px;
  text-align left;
  font-size 25px;
}
  
</style>