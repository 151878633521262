<template>
  <div>
    <van-nav-bar
      :title="titleName"
      left-arrow
      border='false'
      @click-left="onClickLeft"
    />
    
  </div>
</template>

<script>
export default {
  props: ["titleName"],
  methods: {
    //左上角返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>  