// 睡眠方案报告接口

import request from "@/utils/request";

/**
 * 获取睡眠报告详情
 * @param id id
 * @returns 
 */
export function getDetail(id: string) {
    return request.get('/api/sleepSolution/getDetail/' + id,{},{login: true})
}


/**
 * 获取睡眠报告列表
 * @param memberId 
 * @returns 
 */
export function getList(memberId: string){
    return request.get("/api/sleepSolution/getList/" + memberId, {}, {})
}



/**
 * 获取是否生成过方案OR测评报告
 * @param memberId 
 * @returns 
 */
 export function getObtainGenerateSolutionStatus(memberId: string){
    return request.get("/api/sleepSolution/obtainGenerateSolutionStatus/" + memberId, {}, {})
}


/**
 * 根据报告memberId请求睡眠报告信息
 */
export function getSleepSolution(memberId: string){
    return request.get("/api/sleepSolution/requestSleepSolution/" + memberId, {}, {})
}


/**
 * 失眠方案调查问卷保存
 * @param data 表单数据
 * @returns 
 */
export function sleepQuestionnaireSave(data: any){
    return request.post("/api/sleepSolution/sleepQuestionnaireSave", data, {})
}