
import { Options, Vue } from "vue-class-component";
import * as echarts from "echarts";
// import { mockData } from "./mock";
import { getTodayHeartRate } from "@/api/index";

@Options({
  data() {
    return {
      loading: false,
      loadFail: false,
      loadFinished: false,
      heartRateData: null,
    };
  },
  created() {
    this.getRateData();
  },
  methods: {
    getRateData() {
      this.loading = true;
      getTodayHeartRate()
        .then((data) => {
          // data = mockData
          if (data != null && data.length > 0) {
            this.heartRateData = data;
            this.$nextTick(function () {
              this.echartsInit();
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loadFail = true;
        }).finally(()=>{
          this.loading = false;
          this.loadFinished = true;
        });
    },
    echartsInit() {
      let data = this.heartRateData;

      let myChart = echarts.init(document.getElementById("main"));
      // 绘制图表
      myChart.setOption({
        visualMap: [
          {
            show: false,
            type: "continuous",
            seriesIndex: 0,
            min: 0,
            max: 150,
          },
          {
            show: false,
            type: "continuous",
            seriesIndex: 1,
            dimension: 0,
            min: 0,
            max: data.length - 1,
          },
        ],
        // 显示图例
        legend: {
          icon: "rect",
          bottom: 0,
          left: 50,
        },
        color: ["#ff6700"],
        xAxis: {
          data: data.map((item) => {
            return this.$common.formatDate(item.dataTime, "HH:mm");
          }),
          axisTick: false,
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          min: 40,
        },
        series: {
          type: "line",
          data: data.map((item) => {
            return item.healthValue;
          }),
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#f81c1c",
              },
              {
                offset: 1,
                color: "#ffebeb",
              },
            ]),
          },
          symbolSize: 1,
          lineStyle: {
            width: 1,
          },
          emphasis: {
            lineStyle: {
              width: 1,
            },
          },
        },
        tooltip: {
          trigger: "axis",
        },
      });
    },
  },
})
export default class TodayHeartRate extends Vue {}
