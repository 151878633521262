<template>
  <div>
    <img src="../../assets/img/nodata.png" alt="无数据提示">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>