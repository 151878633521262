import request from "@/utils/request";


export function save(data) {
    return request.post("/api/reportDesc/save", data);
}

export function findSymptomTag(symptom) {
    return request.get("/api/reportDesc/findSymptomTag", {
        symptom: symptom,
    });
}

export function findAllTagByIds(tagIds: string){
    return request.get("/api/reportDesc/findAllTagByIds", {
        tagIds: tagIds,
    });
}

export function getByReportId(reportId){
    return request.get("/api/reportDesc/" + reportId);
}


export function getCommonlyUsedSymptomTag(){
    return request.get("/api/reportDesc/symptomTag/commonlyUsed");
}