
import { Options, Vue } from 'vue-class-component';
import { findBindMemberSubscribeList, findSubscribeInfoListByMemberId, subscribeSave } from '@/api/subscribeManage'
import { queryBindState, queryFollowState} from '@/api/wxMp'
import StatusStepsComponent from './StatusStepsComponent.vue'

@Options({
    components: {
        StatusStepsComponent
    },
    data() {
        return {
            pageState: {
                loading: false,
                overlayState: {
                    show: false,
                }
            },
            list: [],
            loadState: {
                loading: false,
                finished: false,
            },
            defaultAvatar: require('@/assets/img/icon/user/默认头像@2x.png'),
            subscribeData: {
                show: false,
                currentMemberInfo: {},
                list: [],
            },
            condStatus: {
                showDataState: false,
                showCheckState: true,
            },
            // 是否显示引导指南
            showGuideHelp: true,
            // 当前引导指南的所处索引。
            guideHelpIndex: -1,
            // 是否显示引导指南加载遮罩
            showGuideHelpLoading: false,
        }
    },
    methods: {
        onRefresh(){
            location.reload();
        },
        onLoad(){
            findBindMemberSubscribeList().then(list => {
                this.list = list;
            }).finally(()=>{
                this.loadState.loading = false;
                this.loadState.finished = true;
            })
        },
        /**
         * 打开会员订阅管理
         * @param memberInfo 会员信息
         */
        openMemberSubscribeManage(memberInfo){
            let {memberId} = memberInfo
            this.pageState.overlayState.show = true;
            this.reloadSubscribeData(memberId).then(_ => {
                this.subscribeData.currentMemberInfo = memberInfo;
                this.subscribeData.show = true;
            }).finally(_=>{
                this.pageState.overlayState.show = false;
            })
        },
        reloadSubscribeData(memberId){
            return findSubscribeInfoListByMemberId(memberId).then(list => {
                this.subscribeData.list = list;
            })
        },
        handlerSubscribeStateChange(subscribeItem){
            console.log("handlerSubscribeStateChange", this, subscribeItem);
            let memberInfo = this.subscribeData.currentMemberInfo;
            // 订阅状态  订阅状态（1开启 / 2关闭 ）；null等同于2
            let subscribeState = subscribeItem.subscribeState === 1 ? 1 : 2;
            subscribeSave({
                watchMemberId: memberInfo.memberId,
                templateId: subscribeItem.templateId,
                subscribeId: subscribeItem.id,
                // 订阅状态  订阅状态（1开启 / 2关闭 ）；null等同于2
                subscribeState: subscribeState,
            }).then(data => {
                this.$notify({
                    type: 'success',
                    message: `订阅状态${subscribeState === 1 ? '开启' : '关闭' }成功`,
                });

                // 刷新订阅数据
                this.reloadSubscribeData(memberInfo.memberId).then(_ => {
                    // 针对当前已打开订阅面板的会员信息，刷新并统计外部的右上角的已订阅的数量
                    memberInfo.subscribeCount = this.subscribeData.list.filter(v => v.subscribeState === 1).length
                });
                
            })
        },
        /**
         * 检查公众号订阅状态
         */
        checkFollowAndBindNotiveStatus(){
            this.showGuideHelpLoading = true;

            queryFollowState({
                nativeAxios: true,
            }).then(response => {
                console.log("queryFollowState", response)
                if(response.data === 1){
                    // 已关注
                    return response;
                } else {
                    // 未关注
                    return Promise.reject(response)
                }
            })
            .then(()=>{
                // 已关注，开始判断是否已绑定
                return queryBindState({
                    nativeAxios: true,
                }).then(response => {
                    console.log("queryBindState", response)
                    if(response.data === 1){
                        // 已绑定
                        return response;
                    } else {
                        // 未绑定
                        return Promise.reject(response)
                    }
                })
            },response => {
                // 提示信息，未关注
                // this.$dialog.alert({
                //     message: response.msg,
                // });
                this.guideHelpIndex = 0
                this.showGuideHelpLoading = false;
                return new Promise(()=>null);
            })
            .then(()=>{
                // 已进行过通知绑定
                this.showGuideHelp = false;
            },response => {
                // 提示信息，未进行通知绑定
                // this.$dialog.alert({
                //     message: response.msg,
                // });
                
                this.guideHelpIndex = 1
                this.showGuideHelpLoading = false;
                return new Promise(()=>null);
            }).finally(()=>{
                this.showGuideHelpLoading = false;
            });
        }
    },
    created() {
        // TODO

    },
    mounted(){
        if(this.showGuideHelp){
            this.checkFollowAndBindNotiveStatus();
        } else {
            this.showGuideHelp = false;
        }
    }
})
export default class SubscribeManageList extends Vue {
    list!: any[];
    loadState!: {
        loading: boolean;
        finished: boolean;
    };
    onLoad!: Function;
}

