import request from "@/utils/request";


/**
 * 根据当前登录用户所绑定的会员ID查找对应的订阅管理信息
 * @param data 请求数据
 * @param data.watchMemberId 监测的成员ID
 * @returns 
 */
export function findSubscribeInfoListByMemberId(watchMemberId: number) {
    return request.get("/api/wx/subscribe/manage/member/subscribe/list", {
        watchMemberId: watchMemberId,
    });
}


/**
 * 查找与当前绑定会员列表的信息
 * @returns 
 */
export function findBindMemberSubscribeList(){
    return request.get("/api/wx/subscribe/manage/member/list", {
    });
}



/**
 * 保存或更新
 * @returns 
 */
 export function subscribeSave(data: {
    watchMemberId: number;
    templateId: string;
    // 订阅状态（1开启 / 2关闭 ）
    subscribeState: number;
    subscribeId: number;
 }){
    return request.post("/api/wx/subscribe/manage/save", data)
}

