<template>
  <div>
    <Call1 title="设备名称" value="智能手表" />
    <Call1 title="IMEI" :value="imeiVal" btn="复制" />
  </div>
</template>

<script>
import Call1 from "@/components/Call/Call.vue";
import { getAboutDevice, boundDeviceList } from "@/api/equipmentSet";
export default {
  components: {
    Call1,
  },
  data() {
    return {
      imeiVal: ''
    }
  },
  mounted() {
    boundDeviceList().then(resData => {
      this.imeiVal = resData[0]?.deviceIdentity
      // getAboutDevice(resData[0].deviceIdentity).then(res=>{
      //   console.log(res);
      // })
    })
  },
  methods: {
  },
};
</script>

<style scoped lang="stylus">
.call {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 80px;
  line-height: 80px;
  display: flex;

  .title {
    flex: 1;
    text-align: left;
    font-size: 32px;
    font-weight: 700;
  }

  .content {
    flex: 1;
    text-align: left;
    font-size: 32px;
    color: #aaa;
    text-align: right;
  }

  span {
    color: blue;
  }
}
</style>