<template>
  <div>
    <!-- 用户信息部分 -->
    
    <div class="userMesBox">
      <div class="userImgBox" @click="goUserInfo">
        <van-image
            round
            fit="cover"
            width="100%"
            height="100%"
            :src="imgUrl && imgUrl || defaultAvatarSrc"
          />
      </div>
      <div class="usernameBox">
        <div  v-if="username.indexOf('立即登录') === -1" class="username">
          {{ username }}
          <span style="position: relative;top: -3px;">
            <van-tag round color="#efefef" text-color="rgb(159, 102, 204)" :data-clipboard-text="uid" ref="copyContent_UID" @click.stop="copyContent($refs.copyContent_UID.$el,$event)" >UID： {{ uid }}</van-tag>
          </span>
        </div>
        <div v-if="username == '立即登录'" class="username" @click="gologin" >{{ username }}  </div>
        <div class="personalized-signature">{{ personalizedSignature }}</div>
      </div>
      <div class="QRcode">
        <img src="../../assets/img/icon/user/个人二维码@2x.png" @click="toMyQR" />
      </div>
    </div>
    <div class="functionDiv">
      <div class="functionPiece" @click="goUserInfo">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/个人信息@2x.png" />
        </div>
        <div class="functionName">个人信息</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="functionPiece" @click="toMyAdviser()">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/健康报告@2x.png" />
        </div>
        <div class="functionName">联系顾问</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="functionPiece" @click="toUserQuestionnaireList()">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/个人信息@2x.png" />
        </div>
        <div class="functionName">调查问卷</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="functionPiece" @click="toMember()" >
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/成员管理@2x.png" />
        </div>
        <div class="functionName">成员管理</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="functionPiece" @click="toSubscribeManageList()" v-if="userSpecialFunction.subscribeManage">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/成员管理@2x.png" />
        </div>
        <div class="functionName">订阅管理</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>

      
      <div class="functionPiece" @click="toCollectDeviceData()">
        <div class="functionIcon">
          <svg style="fill: #7761c6; position: relative; top: calc(100% / 2 - 14px);right: -2px;" t="1678186952610" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3761" width="28" height="28"><path d="M889.5 357.8L833 301.9c-15.5-15.4-40.9-15.4-56.4 0l-395 391.3L494.4 805l395-391.3c15.6-15.4 15.6-40.5 0.1-55.9zM344 863.9l133.2-41.7-112.9-111.8-42.1 131.9c-5.6 17.4 4.1 27.1 21.8 21.6zM675.4 243.8c0-17.6-16.2-32-36-32H282.9c-19.8 0-36 14.4-36 32s16.2 32 36 32h356.5c19.8 0 36-14.4 36-32zM521.8 383.1c0-17.6-16.2-32-36-32H282.9c-19.8 0-36 14.4-36 32s16.2 32 36 32h202.9c19.8 0 36-14.4 36-32zM425.2 522.3c0-17.6-16.2-32-36-32H282.9c-19.8 0-36 14.4-36 32s16.2 32 36 32h106.3c19.8 0 36-14.4 36-32z" p-id="3762"></path><path d="M783.6 616.8c-19.9 0-36 16.1-36 36V886H193V138h554.6v76.4c0 19.9 16.1 36 36 36s36-16.1 36-36V102c0-19.9-16.1-36-36-36H157c-19.9 0-36 16.1-36 36v820c0 19.9 16.1 36 36 36h626.6c19.9 0 36-16.1 36-36V652.8c0-19.9-16.2-36-36-36z" p-id="3763"></path></svg>
        </div>
        <div class="functionName">健康日记</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>

      
      <div class="functionPiece" @click="toHealthQuestionnaire()">
        <div class="functionIcon">
          <svg style="fill: #7761c6; position: relative; top: calc(100% / 2 - 14px);right: -2px;" t="1678186952610" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3761" width="28" height="28"><path d="M889.5 357.8L833 301.9c-15.5-15.4-40.9-15.4-56.4 0l-395 391.3L494.4 805l395-391.3c15.6-15.4 15.6-40.5 0.1-55.9zM344 863.9l133.2-41.7-112.9-111.8-42.1 131.9c-5.6 17.4 4.1 27.1 21.8 21.6zM675.4 243.8c0-17.6-16.2-32-36-32H282.9c-19.8 0-36 14.4-36 32s16.2 32 36 32h356.5c19.8 0 36-14.4 36-32zM521.8 383.1c0-17.6-16.2-32-36-32H282.9c-19.8 0-36 14.4-36 32s16.2 32 36 32h202.9c19.8 0 36-14.4 36-32zM425.2 522.3c0-17.6-16.2-32-36-32H282.9c-19.8 0-36 14.4-36 32s16.2 32 36 32h106.3c19.8 0 36-14.4 36-32z" p-id="3762"></path><path d="M783.6 616.8c-19.9 0-36 16.1-36 36V886H193V138h554.6v76.4c0 19.9 16.1 36 36 36s36-16.1 36-36V102c0-19.9-16.1-36-36-36H157c-19.9 0-36 16.1-36 36v820c0 19.9 16.1 36 36 36h626.6c19.9 0 36-16.1 36-36V652.8c0-19.9-16.2-36-36-36z" p-id="3763"></path></svg>
        </div>
        <div class="functionName">健康问卷</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>

      
      <div class="functionPiece" @click="toWxPath('/pages/healthQuestionnaire/expertSuggest/expertSuggestList')" v-if="userSpecialFunction.expertSuggest">
        <div class="functionIcon">
          <van-icon name="comment-o" color="#7761c6" size="25px" style="font-weight: bold; position: relative; top: calc(100% / 2 - 14px);right: -2px;" />
        </div>
        <div class="functionName">专家建议</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>

    </div>
    <div class="functionDiv">
      <!-- <div class="functionPiece" @click="goEvaluationReportList">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/健康报告@2x.png" />
        </div>
        <div class="functionName">测评报告</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div> -->
      <!-- <div class="functionPiece" @click="test()" >
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/成员管理@2x.png" />
        </div>
        <div class="functionName">我的好友</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div> -->
      <div class="functionPiece" @click="gofeeback">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/意见反馈@2x.png" />
        </div>
        <div class="functionName">意见反馈</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>

    <van-popup v-model:show="showAdviser" :overlay="true"  close-icon="close" >
            <div class="adviser">
              <img src="../../assets/img/icon/erweima.jpg" style="margin-top:13px">
              <span style="margin-top:-50px">微信号: {{ wechat }}</span><button class="btn" @click="copy">一键复制</button>
            </div>
    </van-popup>

   
    <!-- 方法模块部分 -->
    <!-- <div class="functionDiv">
      <div class="functionPiece">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/测评问卷@2x.png" />
        </div>
        <div class="functionName">测评问卷</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="functionPiece">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/使用帮助@2x.png" />
        </div>
        <div class="functionName">使用帮助</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="functionPiece">
        <div class="functionIcon">
          <img src="../../assets/img/icon/user/意见反馈@2x.png" />
        </div>
        <div class="functionName">意见反馈</div>
        <div class="functionbtn">
          <van-icon name="arrow" />
        </div>
      </div>
    </div> -->
      <!-- <van-button style="width:90%;border-radius: 10px;margin-top:10px" type="primary" size="large" @click="exitLogin()">退出登录</van-button> -->
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import {getmyself} from "@/api/index"
import wx from 'wx';
import {getMySelfInfo} from "@/api/user";
import {hasFunction} from "@/api/userSpecialFunction";
import defaultAvatarSrc from "@/assets/img/icon/user/默认头像@2x.png";
import { copyContent } from '@/utils/ClipboardUtils'

export default {
  data() {
    return {
      defaultAvatarSrc: defaultAvatarSrc,

      wechat:"LikY520ab",
      showAdviser:false,
      imgUrl: "", 
      username: "立即登录",
      personalizedSignature: "每次多了解自己一些，远离慢病",
      userId:"",
      //方法框名称
      show: false,
      actions: [
        { name: '退出登录', color: '#ee0a24' },
      ],
      title: {
        personalInformation: "个人信息",
        healthReport: "测评报告",
      },

      // 创建用户ID 
      createUserId: null,
      userSpecialFunction: {
        subscribeManage: false,
        expertSuggest: false,
      },
      uid: '',
    };
  },
  created(){  
    if (this.$route.query.token) {
      console.log(this.$route.query.token)
      localStorage.setItem('token', this.$route.query.token)
    }

    getmyself().then(res =>{
      if(res != null){
      this.username = res.userName || "用户"
      this.imgUrl = res.avatar || null
      this.userId = res.id
      this.createUserId = res.createUserId;
      this.uid = res.uid;
      }
      
      if (!res.birthday) {
        wx.miniProgram.reLaunch({
          url:'/pages/user/userInfo/userInfo',
        });
      }
    }).
    // 判断是否具备特权菜单功能
    then(_=> {

      let createUserId = this.createUserId;

      hasFunction(createUserId, "subscribeManage").then(data => {
        this.userSpecialFunction.subscribeManage = data;
      })

      hasFunction(createUserId, "expertSuggest").then(data => {
        this.userSpecialFunction.expertSuggest = data;
      })

    })

  },
  methods: {
    copyContent: copyContent,
    gologin(){
      console.log("ss")
      wx.miniProgram.reLaunch({ url:'/pages/authorization/authorization' });
    },
     copy(data) {
      //创建一个input元素
      let input = document.createElement('input') 
      //给input的内容复制
      input.value = this.wechat   
      // 在body里面插入这个元素
      document.body.appendChild(input)   
      // 选中input里面内容
      input.select()  
      //执行document里面的复制方法
      document.execCommand("Copy") 
      // 复制之后移除这个元素
      document.body.removeChild(input)
      Toast("复制成功")
   },
     goUserInfo() {
      wx.miniProgram.navigateTo({
        url:'/pages/user/userInfo/userInfo?userId='+this.userId,
        success: function(){
          console.log('success')
        },
        fail: function(){
          console.log('fail');
        },
        complete:function(){
          console.log('complete');
        }
      });
    },
     gofeeback() {
      wx.miniProgram.navigateTo({
        url:'/pages/user/feeback/feeback?userId='+this.userId,
        success: function(){
          console.log('success')
        },
        fail: function(){
          console.log('fail');
        },
        complete:function(){
          console.log('complete');
        }
      });
    },
    //个人信息点击跳转个人信息详情页面
     gonews(){
      // this.$router.push({name: 'news'});
       wx.miniProgram.navigateTo({
        url:'/pages/user/news/news',
      });
    },
    goEvaluationReportList() {
      wx.miniProgram.navigateTo({
        url:'/pages/user/evaluation-report/evaluation-report',
      });
    },
    adviser(){
      this.showAdviser = true
    },
    goToUrl(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    exitLogin(){
      
        Dialog.confirm({
          // title: '退出登录',
          message: '确定要退出当前帐号？',
          confirmButtonText:'退出登录'
        }).then(() => {
          console.log(234234234)
          wx.miniProgram.reLaunch({ url:'/pages/authorization/logout' });
        }).catch(() => {
          // on cancel
        });
    },
    toMyQR() {
      wx.miniProgram.navigateTo({
        url:'/pages/user/myQR/myQR',
      });
    },
    toMyAdviser() {
      wx.miniProgram.navigateTo({
        url:'/pages/user/myAdviser/myAdviser',
      });
    },
    toMember() {
      wx.miniProgram.navigateTo({
        url:'/pages/member/memberList/memberList',
      });
    },
    toSubscribeManageList() {
      wx.miniProgram.navigateTo({
        url:'/pages/user/subscribeManage/subscribeManage',
      });
    },
    toUserQuestionnaireList() {
      wx.miniProgram.navigateTo({
        url: '/pages/user/userQuestionnaireList/userQuestionnaireList',
      })
    },
    toCollectDeviceData() {
      wx.miniProgram.navigateTo({
        url: '/pages/member/collectDeviceData/CollectDeviceData',
      })
    },
    toHealthQuestionnaire(){
      wx.miniProgram.navigateTo({
        url: '/pages/healthQuestionnaire/healthQuestionnaireList',
      })
    },
    toWxPath(wxPath){
      wx.miniProgram.navigateTo({
        url: wxPath,
      })
    }
  },
};
</script>

<style scoped lang="stylus">

.btn{
    height: 35px;
    vertical-align:middle;
    text-align: center;
    line-height: 23px;
    font-size:30px
    margin-left 20px
    background-color #0080FF
    border:none
    border-radius: 15px;
    color #ffffff
}
.child {
    width: 40px;
    height: 40px;
    background: url('../../assets/img/icon/news.png');
    background-size: 40px 40px;
    border-radius: 4px;
    margin-top 3px
  }
  .guwen {
    width: 38px;
    height: 40px;
    background: url('../../assets/img/icon/guwen.png');
    background-size: 38px 40px;
    border-radius: 4px;
    margin-top 3px
  }

  .adviser {
    width: 500px;
    height: 620px;
  }
.userMesBox {
  width: 750px;
  padding: 20px 40px 0px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex
  .userImgBox {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    overflow: hidden;
    margin-top 30px
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .usernameBox {
    height: 140px;
    padding-left: 20px;
    box-sizing: border-box;
    flex 1
    .username {
      text-align: left;
      font-size: 38px;
      font-weight: 600;
      padding-top: 40px;
    }

    .personalized-signature {
      text-align: left;
      height: 60px;
      line-height: 60px;
      font-size 26px
    }
  }

  .QRcode {
    width: 50px;
    position: relative;
    margin-top 30px
    img {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 35px;
      left: 0;
    }
  }
}

.functionDiv {
  width: 690px;
  margin: 0 auto 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  overflow: hidden;
  margin-top :50px;

  .functionPiece {
    width: 100%;
    height: 126px;
    border-bottom: 1px solid #ccc;

    .functionIcon {
      width: 110px;
      height: 126px;
      float: left;
      position: relative;

      img {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 35px;
        left: 36px;
      }
    }

    .functionName {
      width: 530px;
      height: 126px;
      line-height: 126px;
      float: left;
      text-align: left;
      font-weight: 600;
    }

    .functionbtn {
      height: 126px;
      line-height: 135px;
      float: left;
      text-align: left;
      font-size: 40px;
      color: #999;
    }
  }
}
</style>