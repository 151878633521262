<template>
<div>
  <div class="call">
    <div class="title">{{ title }}</div>
    <div class="content">
      <span class="c-val">{{ value }}</span>
      <span class="c-btn" v-if="btn" @click="Copy()">{{ btn }}</span>
    </div>
  </div>
  <div class="copy-div">
    <input ref="code" :value="value" readonly />
  </div>
</div>
</template>

<script>
import { Toast } from "vant";
export default {
  props: {
    title: String,
    value: String,
    btn: String,
  },
  methods: {
    Copy() {
      const copyText = this.$refs.code;
      copyText.select(); // 选择对象
      document.execCommand("copy");
      Toast.success("复制成功");
    },
  },
};
</script>

<style scoped lang="stylus">
.call {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 80px;
  display: flex;

  .title {
    flex: 1;
    text-align: left;
    font-size: 32px;
    font-weight: 700;
  }

  .content {
    flex: 1;
    text-align: left;
    font-size: 32px;
    color: #aaa;
    text-align: right;
    display: flex;
    .c-val {
      display: inline-block;
      color: #000;
      width: 300px;
    }
  }

  span {
    color: blue;
    width: 70px;
  }

  input {
    padding-top: 20px;
    border: none;
    text-align: right;
  }
}

.copy-div
  height: 0
  opacity 0

</style>