<template>
  <div>
    <van-nav-bar
      title="血压"
      left-arrow="true"
      @click-left="onClickLeft"
    ></van-nav-bar>
  </div>
  <div class="content">
    <div class="dateBox">2021/7/24 上午10:42</div>
    <div class="numBox">
      &nbsp;&nbsp;&nbsp;&nbsp;128/88<span class="unitBox">毫米汞柱</span>
    </div>

    <div class="progressBarBox">
      <span class="progressBarTltle">正常高值血压</span>
      <van-progress
        class="progressBar"
        :percentage="88"
        color="none"
        pivot-color="orange"
        pivot-text="正常"
      />
    </div>
  </div>
  <van-cell is-link  @click="goToUrl('bloodPressure/xyParticulars')">
    <van-icon style="fontSize: 40px; float: left" name="todo-list-o"/>
    <div class="cellContentBox">
      <div class="cellTitle">128/88毫米汞柱</div>
      <div class="cellContent">2021/7/24 上午10:42</div>
    </div>
  </van-cell>
  <div class="importBtn" @click="goToUrl('bloodPressure/xyParticulars')">手动输入</div>
</template>

<script>
export default {
  methods: {
        goToUrl(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="stylus">
.content {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;

  .dateBox {
    color: #aaa;
    font-size: 28px;
    margin-top: 50px;
  }

  .numBox {
    font-size: 100px;
  }

  .unitBox {
    font-size: 30px;
  }

  .progressBarTltle {
    font-size: 28px;
    color: #aaa;
  }

  .progressBar {
    width: 80%;
    height: 8px;
    box-sizing: border-box;
    margin: 20px auto;
    background-image: linear-gradient(to right, green, yellow, orange, red);
  }
}

.cellContentBox {
  float: left;
}
.cellTitle{
    line-height 40px
    height 40px
    font-size 32px
    font-weight: 700;
}
.cellContent{
    line-height 40px
    height 40px
    color #aaa
}
.importBtn{
width 100%
box-sizing border-box
padding 30px
height 100px
background-color #eee
position fixed
bottom 100px
}
</style>