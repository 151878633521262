import request from "@/utils/request";
import { Form } from "element-ui";

/**
 * 获取健康报告详情
 * @param reportId
 * @returns {*}
 */
 export function getReportDetail(reportId: number) {
  return request.get("/api/reportSuggest/findList", {
    reportId
  }, {
      login: false
  });
}

/**
 * 获取用户信息
 * @param memberId
 * @returns {*}
 */
export function getMemberDetail(memberId: number) {
  return request.get("/api/member/getInfo/byMemberId", {
    memberId
  }, {
      login: false
  });
}

/**
 * 获取报告详情
 * @param reportId
 * @param reportType
 * @returns {*}
 */
 export function getExpertReportDetail(reportId: number, reportType: number) {
  return request.get(`/api/report/getReportDetail/${reportId}`, {
    reportType
  }, {
      login: false
  });
}

/**
 * 获取报告详情
 * @param reportId
 * @param reportType
 * @returns {*}
 */
 export function getReportVersion7(reportId: number, reportType: number) {
  return request.get(`/api/report/v7/evaluationReport/${reportId}/${reportType}`, {}, {
      login: false
  });
}

export function getReportVersion8(reportId: number, reportType: number) {
  return request.get(`/api/report/v8/evaluationReport/${reportId}/${reportType}`, {}, {
      login: false
  });
}

/**
 * 获取报告详情
 * @param reportId
 * @returns {*}
 */
 export function getImgCodetVersion7(reportId: number) {
  return request.get(`/api/report/v7/allCodeImageData/${reportId}`, {}, {
      login: false
  });
}

export function getImgCodetVersion8(reportId: number) {
  return request.get(`/api/report/v8/allCodeImageData/${reportId}`, {}, {
      login: false
  });
}

/**
 * 获取文章列表
 * @param  
 * @returns 
 */
export function getArticleList(pageNo: number) {
  return request.get('/api/article/findAllList',
    {pageNo},
    {login: false})
}
/**
 * 获取文章详情
 * @param  
 * @returns 
 */
 export function getArticleInfo(id: number) {
  return request.get('/api/article/detail',
    {id},
    {login: false})
}
/**
 * 获取banner详情
 * @param  
 * @returns 
 */
 export function finbyBannerId(id: number) {
  return request.get('/api/banner/findById',
    {id},
    {login: false})
}
/**
 * 获取icon
 * @param  
 * @returns 
 */
 export function findByIconId(id: number) {
  return request.get('/api/icon/findById',
    {id},
    {login: false})
}

/**
 * 获取banner列表
 * @param  
 * @returns 
 */
 export function getBannerList() {
  return request.get('/api/banner/findAllList',
    {login: false})
}

/**
 * 获取icon列表
 * @param
 * @returns
 */
 export function getIconList() {
  return request.get('/api/icon/findAllList',
    {login: false})
}
/**
 * 获取个人详细信息（个人信息页面）
 * @param  
 * @returns 
 */
 export function getUserInfo(memberId: number) {
  return request.get('/api/member/getInfo',
    {memberId},
    {login: false})
}

/**
 * 获取个人详细信息（我的页面）
 * @param  
 * @returns 
 */
 export function getmyself() {
  return request.get('/api/member/findMySelf',
    {login: true})
}

/**
 * 查询当前选择的人员信息
 * @param  
 * @returns 
 */
 export function getCurrentUserInfo() {
  return request.get('/api/member/getCurrentUserInfo',
    {login: true})
}

/**
 * 保存个人详细信息（个人信息页面）
 * @param  
 * @returns 
 */
 export function saveInfo(member: Form) {
  return request.post('/api/member/improveUserInfo',
    {member},
    {login: true})
}
/**
 * 提交意见反馈
 * @param  
 * @returns 
 */
 export function savesuggest(suggest: JSON) {
   console.log(suggest)
  return request.post('/api/appSuggest/save',
    {suggest},
    {login: true})
}

export function saveMemberInfo(data) {
    return request.post('', data, {login: true});
}

/**
 * 获取个人详细信息（我的页面）
 * @param
 * @returns
 */
export function bindDevice(scanCode) {
    return request.post('/api/memberDevice/bindDevice', {deviceIdentity: scanCode}, {login: true} )
}

/**
 * 获取今天的心率
 * @param
 * @returns
 */
export function getTodayHeartRate() {
    return request.get('/api/healthNorms/lastDayHeartRateData', {}, {login: true} )
}
