<template>
  <div>
    <titleNavBar titleName="历史记录" />
    <van-collapse v-model="activeNames">
      <van-collapse-item
        title-class="collapseTitle"
        title="2021年7月7日"
        name="1"
      >
        <div class="box">
          <van-icon style="fontsize: 40px; float: left" name="todo-list-o" />
          <div class="cellContentBox">
            <div class="cellTitle">体重</div>
            <div class="cellContent" style="color: #333; font-weight: 700">
              77公斤
            </div>
          </div>
          <div class="leftDate">16：38</div>
        </div>
      </van-collapse-item>
      <van-collapse-item
        title-class="collapseTitle"
        title="2021年7月7日"
        name="2"
      >
        <div class="box">
          <van-icon style="fontsize: 40px; float: left" name="todo-list-o" />
          <div class="cellContentBox">
            <div class="cellTitle">体重</div>
            <div class="cellContent" style="color: #333; font-weight: 700">
              77公斤
            </div>
          </div>
          <div class="leftDate">16：38</div>
        </div>
      </van-collapse-item>
      <van-collapse-item
        title-class="collapseTitle"
        title="2021年7月7日"
        name="3"
      >
        <div class="box">
          <van-icon style="fontsize: 40px; float: left" name="todo-list-o" />
          <div class="cellContentBox">
            <div class="cellTitle">体重</div>
            <div class="cellContent" style="color: #333; font-weight: 700">
              77公斤
            </div>
          </div>
          <div class="leftDate">16：38</div>
        </div>
        <div class="box">
          <van-icon style="fontsize: 40px; float: left" name="todo-list-o" />
          <div class="cellContentBox">
            <div class="cellTitle">体重</div>
            <div class="cellContent" style="color: #333; font-weight: 700">
              77公斤
            </div>
          </div>
          <div class="leftDate">16：38</div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>


<script>
import titleNavBar from "@/components/Navbar/titleNavBar.vue";
import { ref } from "vue";
export default {
  setup() {
    const activeNames = ref(["1"]);
    return { activeNames };
  },
  components: {
    titleNavBar,
  },
  mounted() {
    console.log(this.activeNames);
  },
};
</script>

<style  lang="stylus">
.collapseTitle {
  font-weight: 700;
}

.cellContentBox {
  float: left;
}

.cellTitle {
  line-height: 40px;
  height: 40px;
  font-size: 32px;
  font-weight: 700;
}

.cellContent {
  line-height: 40px;
  height: 40px;
}

.leftDate {
  float: right;
  height: 40px;
  line-height: 40px;
}

.box {
  margin-top: 20px;
  width: 100%;
  border-bottom: 1px solid #eee;
  float: left;
}
</style>