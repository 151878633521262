
import { Options, Vue } from 'vue-class-component';
import { saveMemberAndDeviceData, getNewestDeviceData, getMemberInfoByBindCode } from '@/api/collectDeviceData'
import wx from "wx"
import {getCurrentUserInfo, getmyself} from "@/api/index"

/**
 *  1: 血糖
 *  2: 血压
 *  3: 血氧
 */
const DATA_TYPE_MAPPING = {
    // 血糖
    bloodSugar: 1,
    // 血压
    bloodPressure: 2,
    // 血氧
    bloodOxygen: 3,
    // 身高
    height: 4,
    // 体重
    weight: 5,
    // 备注
    remark: 6,
}


@Options({
    data() {
        return {
            // tabs状态数据
            tabsStateData: {
                // key选项卡name值，value为数据状态对象值
                agencyEntry: {
                    // 选项卡是否启用
                    enabled: true,
                    memberBindCode: '',
                    showInitPage: false,
                }

            },
            currentMemberId: null,
            defaultActiveTab: 'yourselfEntry',
            activeTab: 'yourselfEntry',
            form: {
                memberName: '',
                /**
                 * NULL：表示自己录入数据信息的；
                 * 有值：代表是给这个成员ID进行代入信息的。
                 */
                memberId: '',
                // 身高
                height: '',
                // 体重
                weight: '',
                // 血糖
                bloodSugar: '',
                // 血压
                bloodPressure: {
                    // 收缩压
                    systolicBloodPressure: '',
                    // 舒张压
                    diastolicPressure: '',
                },
                // 血氧
                bloodOxygen: {
                    // 血氧饱和度
                    SpO2: '',
                    // 脉率
                    PR: '',
                    // 血流灌注指数
                    PI: '',
                },
                // 备注内容
                remark: "",
            },
            // 最新/最后一条设备数据记录
            newestMemberData: {
                _rawData: null,
                // key为数据类型，value为对象值。
                deviceDatas: {},
                createTime: null,
            },

        }
    },
    methods: {
        resetFormData(clearData = true){
            const initDefaultData = {
                memberName: '',
                memberId: '',
                // 身高
                height: '',
                // 体重
                weight: '',
                // 血糖
                bloodSugar: '',
                // 血压
                bloodPressure: {
                    // 收缩压
                    systolicBloodPressure: '',
                    // 舒张压
                    diastolicPressure: '',
                },
                // 血氧
                bloodOxygen: {
                    // 血氧饱和度
                    SpO2: '',
                    // 脉率
                    PR: '',
                    // 血流灌注指数
                    PI: '',
                },
                // 备注内容
                remark: "",
            }
            if(clearData){
                this.form = initDefaultData;
            }else{
                return initDefaultData;
            }
        },
        resetNewestMemberData(clearData = true){
            const initDefaultData = {
                _rawData: null,
                // key为数据类型，value为对象值。
                deviceDatas: {},
                createTime: null,
            }
            if(clearData){
                this.newestMemberData = initDefaultData;
            }else{
                return initDefaultData;
            }
        },

        onSubmit(){
            // 对需要保存的数据进行结构化组织。
            let formData = this.form;
            let deviceDataList = Object.keys(DATA_TYPE_MAPPING).map(key=>{
                if(['bloodPressure', "bloodOxygen"].includes(key)){
                    let keys = Object.keys(formData[key])
                    if(!keys.some(element => !!formData[key][element])){
                        return null;
                    }
                } else if(!formData[key]){
                    return null;
                }

                return ({
                    dataType: DATA_TYPE_MAPPING[key],
                    value: JSON.stringify(formData[key])
                });
            }).filter(v => v !== null)

            const saveData = {
                memberId: formData.memberId,
                deviceDataList: deviceDataList,
            };
            console.log("提交表单的数据", saveData);

            if(deviceDataList.length === 0){
                this.$toast.success({
                    message: '请至少填写一项数据！',
                    type: 'fail',
                    overlay: true,
                    closeOnClickOverlay: true,
                    forbidClick: true
                })
                return;
            }

            this.$toast.loading({
                message: '正在保存中',
                overlay: true,
                closeOnClickOverlay: false,
                forbidClick: true,
                duration: 0,
            })
            
            saveMemberAndDeviceData(saveData).then(data => {
                console.log("saveMemberAndDeviceData Response", data)
                this.$toast.success({
                    message: '保存成功',
                    type: 'success',
                    overlay: true,
                    closeOnClickOverlay: false,
                    forbidClick: true,
                    onClose: () => {
                        this.$nextTick(function(){
                            this.onTabsChange(this.activeTab)
                        })
                    }
                })
            }).catch(err => {
                this.$toast.fail({
                    message: '保存失败，' + err.msg || err,
                    overlay: true,
                    closeOnClickOverlay: true,
                    forbidClick: true,
                    duration: 5000,
                })
            });

        },
        loadNewestDeviceData(){
            let refMemberId = this.currentMemberId;
            // 加载最新的一份数据作为历史记录回显
            getNewestDeviceData(refMemberId).then(data => {
                console.log("getNewestDeviceData", data)
                this.newestMemberData._rawData = data;
                if(data){
                    data.forEach(deviceData => {

                        let value = deviceData.value
                        try{
                            value = JSON.parse(deviceData.value || '""')
                        }catch (e) {
                            // no handler
                        }

                        this.newestMemberData.deviceDatas[deviceData.dataType] = {
                            value: value,
                            createTime: deviceData.createTime,
                        }

                        if(!this.newestMemberData.createTime){
                            this.newestMemberData.createTime = deviceData.createTime
                        }

                    });
                }

            })
        },
        onTabsChange(name, title, routeToActiveTab=false){
            console.log("onTabsChange", name, title);
            
            this.resetFormData()
            this.resetNewestMemberData();
            this.currentMemberId = null;

            if(name === 'yourselfEntry'){
                // 获取当前成员的信息
                getmyself().then(data => {
                    console.log("getmyself", data);
                    this.currentMemberId = data.id;

                    this.form.memberName = data.userName;
                    // this.form.height = data.height;
                    // this.form.weight = data.weight;
                    // NULL：表示自己录入数据信息的；
                    // 有值：代表是给这个成员ID进行代入信息的。
                    this.form.memberId = data.id;
                    
                }).then(_=>{
                    // 加载最新的一份数据作为历史记录回显
                    this.loadNewestDeviceData();
                })
            }else if(name === 'agencyEntry'){
                // 通过扫码获取指定成员的信息
                // 只有通过路由激活的选项卡才需要进入处理。
                // 这是因为通过路由激活的方式，只有通过扫成员码才能进入。
                // 下面的代码需要通过通过成员码查询出对应的成员信息的。
                if(routeToActiveTab){
                    this.tabsStateData.agencyEntry.showInitPage = false;
                    
                    let memberBindCode = this.tabsStateData.agencyEntry.memberBindCode

                    if(!memberBindCode){
                        this.$dialog.alert({
                            message: '缺少必要的参数，请重新扫码后重试',
                            confirmButtonText: '扫码重试',
                            messageAlign:'center'
                        }).then( () => {
                            // 这里进行重试的逻辑，重新扫码的操作
                            this.scanMemberQRCode();
                        })
                        return;
                    }
                    
                    getMemberInfoByBindCode(memberBindCode).then(data=>{
                        this.currentMemberId = data.id;
    
                        this.form.memberName = data.userName;
                        // this.form.height = data.height;
                        // this.form.weight = data.weight;
                        // NULL：表示自己录入数据信息的；
                        // 有值：代表是给这个成员ID进行代入信息的。
                        this.form.memberId = data.id;
                    }).then(_=>{
                        // 加载最新的一份数据作为历史记录回显
                        this.loadNewestDeviceData();
                    }).catch(response => {
                        if(response.code === 400){
                            this.$dialog.alert({
                                message: response.msg,
                                confirmButtonText: '关闭',
                                messageAlign:'center'
                            }).then( () => {
                                // 显示代录入信息页面的初始页的操作
                                this.showAgencyEntryInitPage();
                            })
                        }else{
                            this.$dialog.alert({
                                message: '未知原因：' + response.msg || response,
                                confirmButtonText: '关闭',
                                messageAlign:'center'
                            }).then( () => {
                                // 显示代录入信息页面的初始页的操作
                                this.showAgencyEntryInitPage();
                            })
                        }
                    })
                }else{
                    // 显示代录入信息页面的初始页的操作
                    this.showAgencyEntryInitPage();
                }
            }else{
                this.$dialog.alert({
                    message: '未知Tab操作，Tab名称：' + name,
                    confirmButtonText: '返回',
                    messageAlign:'center'
                }).then( () => {
                    wx.miniProgram.navigateBack();
                })
            }
        },
        /**
         * 是否手动输入新的数据了。
         * @returns true已修改数据，false未修改数据。
         */
        hasManualEntryData(){
            /**
             * 比较属性前需要排除的属性列表
             */
            // "height", "weight",
            const excludeCompareProp = ["memberName",  "memberId"];

            const initDefault = {
                ...this.resetFormData(false)
            }
            const currentData = {
                ...this.form
            };

            excludeCompareProp.forEach(propName => {
                delete initDefault[propName]
                delete currentData[propName]
            })

            return !(JSON.stringify(initDefault) === JSON.stringify(currentData));
        },
        onTabsBeforeChange(name){
            // 判断是否已编辑过信息，编辑过信息需要重置后才能切换tab，防止误操作。
            // 还需要加个重置表单数按钮。
            console.log("onTabsBeforeChange", name);

            // 比较是否有修改内容
            if(!this.hasManualEntryData()){
                return true;
            }else{
                this.$dialog.confirm({
                    message: '您当前填写的数据还未保存，继续操作将会丢失当前已填写的信息。请确认是否继续操作？',
                    confirmButtonText: '确认继续',
                    messageAlign:'left'
                })
                .then(() => {
                    // on confirm
                    this.onTabsChange(name);
                    this.activeTab = name;
                })
                return false;
            }
        },
        /**
         * 显示带录入信息页面的初始页的操作
         */
        showAgencyEntryInitPage(){
            this.currentMemberId = null;
            this.tabsStateData.agencyEntry.showInitPage = true;
            this.tabsStateData.agencyEntry.memberBindCode = '';
        },
        /**
         * 代理录入信息关闭按钮操作
         */
        onAgencyEntryClose(){
            console.log("onAgencyEntryClose");
            if(!this.hasManualEntryData()){
                this.resetFormData();
                this.showAgencyEntryInitPage();
            }else{
                this.$dialog.confirm({
                    message: '您当前填写的数据还未保存，继续操作将会丢失当前已填写的信息。请确认是否继续操作？',
                    confirmButtonText: '确认关闭',
                    messageAlign:'left'
                })
                .then(() => {
                    // on confirm
                    this.resetFormData();
                    this.showAgencyEntryInitPage();
                })
            }
        },
        /**
         * 打开成员码
         */
        openMemberQRCode(){
            wx.miniProgram.navigateTo({
                url: '/pages/user/myQR/myQR',
            });
        },
        /**
         * 扫一扫成员码
         */
        scanMemberQRCode(){
            wx.miniProgram.redirectTo({
                url: `/pages/scanCode/ScanCode?operType=CollectDeviceData&activeTab=${this.activeTab}`,
            });
        },
        validatorFieldByBloodPressure(value, rule){
            console.log("validatorFieldByBloodPressure", value, rule);
            
            if(!this.form.bloodPressure.systolicBloodPressure && !this.form.bloodPressure.diastolicPressure){
                return null;
            }else if(rule.name === "systolicBloodPressure" && !this.form.bloodPressure.systolicBloodPressure){
                return "请填写收缩压";
            }else if(rule.name === "diastolicPressure" && !this.form.bloodPressure.diastolicPressure){
                return "请填写舒张压";
            }
            return null;
        },
        validatorFieldByBloodOxygen(value, rule){
            if(
                !this.form.bloodOxygen.SpO2 &&
                !this.form.bloodOxygen.PR && 
                !this.form.bloodOxygen.PI
            ){
                return null;
            }
            if(rule.name === "SpO2" && !this.form.bloodOxygen.SpO2){
                return "请填写SpO2（血氧饱和度）";
            }else if(rule.name === "PR" && !this.form.bloodOxygen.PR){
                return "请填写PR（脉率）";
            }else if(rule.name === "PI" && !this.form.bloodOxygen.PI){
                return "请填写PI（血流灌注指数）";
            }
            return null;
        },

    },
    created() {

        const {
            activeTab = this.defaultActiveTab,
            scanState,
            scanResult,
        } = this.$route.query

        this.activeTab = activeTab;
        
        if(scanState){
            console.log("扫码状态", scanState, "扫码结果", scanResult);

            if(scanState === "ok"){
                if(activeTab && "agencyEntry" !== activeTab){
                    this.$dialog.alert({
                                message: '当前操作Tab模式有误，恢复默认选项卡页面',
                                confirmButtonText: '重置默认',
                                messageAlign:'center'
                    }).then( () => {
                        // 因不可预知的原因，这里采取打开默认的tab页面
                        this.onTabsChange(this.defaultActiveTab)
                    })
                }else{
                    // 有传值，代表当前是代办操作，需要进行解析当前成员bindCode对应的成员信息
                    this.tabsStateData.agencyEntry.memberBindCode = scanResult;
                    this.onTabsChange(this.activeTab, null ,true)
                }
            }else if(scanState === "cancel") {
                // 取消扫码操作，初始化tab数据
                this.onTabsChange(this.activeTab)
            }else{
                // todo 扫码失败了
                this.$dialog.alert({
                            message: '扫码失败，原因：' + scanResult,
                            confirmButtonText: '关闭',
                            messageAlign:'center'
                }).then( () => {
                    // 初始化tab数据
                    this.onTabsChange(this.activeTab)
                })
            }
        }else{
            // 初始化tab数据
            this.onTabsChange(this.activeTab)
        }

    },
})
export default class CollectDeviceData extends Vue {

}

