<template>
  <NoData v-if="!wifitList.length"></NoData>
  <div class="wifi-list card-universal" v-else>
    <div class="wifi-item clearfix" v-for="item in wifitList" :key="item" @click="selectFn(item)">
      <van-image :src="require('@/assets/img/equipment/wifi.png')" class="wifi-img fl"></van-image>
      <div class="wifi-name fl">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/noData/NoData.vue'
import wx from "wx";

export default {
  components: { NoData },
  data() {
    return {
      wifitList: [],
      imei: '',
    };
  },
  created() {
    this.imei = this.$route.query.imei
    if (this.$route.query.wifiNameList) {
      this.wifitList = JSON.parse(this.$route.query.wifiNameList)
    }
  },
  methods: {
    selectFn(name) {
      wx.miniProgram.redirectTo({
        url: '/pages/member/equipment/wifiEdit/wifiEdit?name=' + name + '&imei=' + this.imei,
      })
    },
  },
};
</script>

<style scoped lang="stylus">

.wifi-list
  margin-top 30px
  margin-bottom 30px
  .wifi-item
    padding 20px 0
    font-size 30px
    line-height 50px
    border-bottom: 1px solid #eee
    &:nth-last-child(1)
      border-bottom: none
    .wifi-img
      width: 50px
      height: 50px
    .wifi-name
      margin-left: 16px
    .wifi-status
      color #999
    .del-btn
      margin-left: 10px
      background linear-gradient(to right, #ff6034, #ee0a24)
      color #fff
      font-size 27px
      padding: 0 16px
      border-radius 12px

</style>