<template>
  <div class="body">
    <van-nav-bar
      title="历史记录"
      left-arrow="true"
      @click-left="onClickLeft"
    ></van-nav-bar>
    <div class="historyList">
      <van-collapse v-model="activeNames">
        <van-collapse-item title="2021-07-21" name="1" value="1次 &nbsp">
          <van-cell is-link style="padding: 0">
            <van-icon style="fontSize: 30px; float: left" name="todo-list-o" />
            <div class="cellContentBox">
              <div class="cellTitle">5.1mml/L</div>
              <div class="cellContent">正常</div>
            </div>
            <div class="leftDate">16：38</div>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const activeNames = ref(["1"]);
    return { activeNames };
  },
  components: {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="stylus">
.body
    width 100%
    height 100%
    background-color #eee
    position: absolute;

.historyList {
  margin: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color #fff
  padding 10px
  border 0
}
.cellContentBox {
  margin-left: 20px;
  .cellContent {
    color: #4fc08d;
    
  }
}

</style>