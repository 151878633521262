<template> 
<van-pull-refresh  v-model="loading"  @refresh="onRefresh">
  

  <Loading :loadingState="loadingState.bannerLoading">
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item  v-for="(item,index) in imgList" :key="index">
          <img :src="item.bannerCoverUrl" class="banner-img"  style="width:90%" @click="goBanner(item)"/>
        </van-swipe-item>
      </van-swipe>     
    </div>
  </Loading>
  
<Loading :loadingState="loadingState.iconButtonLoading">

  <swiper 
    class="swiper"
    :slides-per-view="4"
    :space-between="25"
  >
    <swiper-slide  v-for="(item,index) in iconList" :key="index">
        <div class = "imgIcon">
          <img :src="item.iconCoverUrl" style="width: 40px;height: 40px;" @click="goIcon(item)"/>
          <br/>
          <div style="margin: 0 auto;width:100%;font-size:15px"><strong> {{ item.iconName }} </strong></div>  
        </div>
    </swiper-slide>
  </swiper>

</Loading>


<Loading :loadingState="loadingState.articleLoading">

<div style="margin:0 auto:wdith:100%">
 
  <!-- 测评报告标识 -->
  <!-- <div id = "report" style="margin: 0 auto;width:90%">
      <van-notice-bar mode="closeable" width="60%" color="#5B5B5B" background="#ecf9ff" left-icon="info-o">
        健康测评报告已生成，立即查看
      </van-notice-bar>
  </div> -->
  <!-- 文章列表 -->
  <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad">
        <van-cell v-for="item of wzList" :key="item" >
          <div class="article" @click="goArticle(item)">
            <div class="title"><strong> {{ item.title }} </strong></div>
            <div class="views"> {{ item.releaseTime }}  &#12288;&#12288;&#12288;<img src="../../assets\img\banner\look.jpg" style="width: 15px;"/> {{ item.viewNum }}</div>
            <div class="artImgs" :style="{'backgroundImage': `url(${item.cover})`}"></div>
          </div>
        </van-cell>
  </van-list>
</div>

</Loading>

</van-pull-refresh>
</template>

<script>
 import {getArticleList,getBannerList,getArticleInfo,getIconList} from "@/api/index"
import {getMySelfInfo} from '@/api/user'
import { Swiper, SwiperSlide } from 'swiper/vue';
import Loading from '@/components/loading/Loading'

  // Import Swiper styles
  import 'swiper/swiper-bundle.css';

  import wx from 'wx';

export default { 
  components: {
    Swiper,
    SwiperSlide,
    Loading,
  },
  data() {
    return {
      num: 10,
      loading: false,
      finished: false,
      titleName:"首页",
      imgList:[],
      iconList:[],
      wzList:[],
      loadingState: {
        bannerLoading: true,
        iconButtonLoading: true,
        articleLoading: true,
      }
    };
  },
  created(){  
    if (this.$route.query.token) {
      localStorage.setItem('token', this.$route.query.token)
    }
    this.getArticles();
    this.getBannerList();
    this.getIcons();
    // this.getBanners();
  },
  methods: {
     onRefresh  ()  {
      location.reload();
      this.loading = false;
    },
  goBanner(item){
        if(item.bannerType == 2){
            //文本
             wx.miniProgram.navigateTo({
              url:'/pages/index/text?bannerId='+item.id+'&id=0&iconId=0',
              success: function(){
                console.log('success')
              },
              fail: function(){
                console.log('fail');
              },
              complete:function(){
                console.log('complete');
              }
            });
          //   this.$router.push(
          //     {
          //       name: 'text',
          //       params:{"text":item.bannerText}
          //      }
          // );
        }else if(item.bannerType == 3){
          //外部链接
          window.location.href = item.bannerUrl;
        }else if(item.bannerType == 4){
          //文章
          // getArticleInfo(item.bannerArticleId).then(response =>{
             wx.miniProgram.navigateTo({
              url:'/pages/index/text?bannerId=0'+'&id='+item.bannerArticleId+'&iconId=0',
              success: function(){
                console.log('success')
              },
              fail: function(){
                console.log('fail');
              },
              complete:function(){
                console.log('complete');
              }
            });
            //  this.$router.push({name: 'text',params:{"text":response.content,"title":response.title,"video":response.fileUrl}})
          // })
        }
    },
    
    goIcon(item){
        if(item.iconType == 1){
            //外部链接
             window.location.href = item.iconUrl;
          
        }else if(item.iconType == 2){
          //文章

            if(item.iconArticleId === 64){
              // 定制方案
              wx.miniProgram.navigateTo({
                url:'/pages/user/health/scheme/customize/scheme-customize',
              })
              return 
            }
  

        //  getArticleInfo(item.iconArticleId).then(response =>{
  
             wx.miniProgram.navigateTo({
              url:'/pages/index/text?bannerId=0'+'&id='+item.iconArticleId+'&iconId=0',
              success: function(){
                console.log('success')
              },
              fail: function(){
                console.log('fail');
              },
              complete:function(){
                console.log('complete');
              }
            });
            //  this.$router.push({name: 'text',params:{"text":response.content,"title":response.title,"video":response.fileUrl}})
          // })
        }else if(item.iconType == 3){
          //文本
    
    
             wx.miniProgram.navigateTo({
              url:'/pages/index/text?bannerId=0&id=0'+'&iconId='+item.id,
              success: function(){
                console.log('success')
              },
              fail: function(){
                console.log('fail');
              },
              complete:function(){
                console.log('complete');
              }
            });
          // this.$router.push(
          //     {
          //       name: 'text',
          //       params:{"text":item.iconText}
          //      }
          // );
        }
    },
    //跳转文章
    goArticle(item){
          // getArticleInfo(item.id).then(response =>{
             wx.miniProgram.navigateTo({
             url:'/pages/index/text?bannerId=0'+'&id='+item.id+'&iconId=0',
              success: function(){
                console.log('success')
              },
              fail: function(){
                console.log('fail');
              },
              complete:function(){
                console.log('complete');
              }
            });
            //  this.$router.push({name: 'text',params:{"text":response.content,"title":response.title,"video":response.fileUrl}})
        
    },
     getIcons(){
      getIconList().then(res =>{
        this.iconList = res;
      }).finally(()=>{
        this.loadingState.iconButtonLoading = false;
      })
    },

    getBannerList(){
      getBannerList().then(response =>{
        this.imgList = response;
      }).finally(()=>{
        this.loadingState.bannerLoading = false;
      })
    },
    //获取banner列表
    getBanners(){
      getBannerList().then(response =>{
        this.images = response;
        this.imgList = response;
            console.log(this.imgList)
      })
    },
    //获取文章列表
    getArticles(){
      getArticleList(0).then(response => {
        this.wzList = response
        console.log(this.wzList)
        }).finally(()=>{
        this.loadingState.articleLoading = false;
      });
    },

    onLoad() {
      // 异步更新数
      getArticleList(this.num).then(response => {
          if (response == 0) {
          this.finished = true;
        }
        this.wz = response;
        response.forEach((item) => {
         this.wzList.push(item)
        })
      });
        // 加载状态结束
        this.loading = false;
        this.num = this.num +10;
      
    },
  },
};
</script>

<style  scoped lang="stylus">


   .imgIcon{
    margin: 0 auto;
    width:100%;
    height:200px;
    margin-top:30px;
    display: inline-block;
  }


.my-swipe .van-swipe-item {
    width 90%
    height 200px;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    margin-top 30px
  }
  .swiper {
    width 90%
    height 200px;
    text-align: center;
  }

.article{
  width :99%;
  height :250px;
  margin : 0 auto;
  margin-top :20px; 
  border : 1px solid #E0E0E0;
  display: inline-block;


  .title{
    width :96%;
    position: relative;
    text-align:left
    padding-left 25px;
    top: 20px;
    font-size:32px;
    line-height 1.4
  }

  .views{
    width :50%
    position:absolute;
    padding-left 25px;
    text-align :left
    bottom -20px;
    font-size : 25px;
    float bottom 
  }
  .artImgs{
    width :150px;
    height :150px;
    float right;
    position absolute;
    right 30px;
    top 100px;
    background-size cover;
    background-position center;

  }
}


:deep .van-cell__title {
  width: 130px;
  height: 120px;
  line-height: 120px;
  color: #111;
  margin: 0;
  font-size: 28px;
  font-weight 600
  padding-left 10px
}

:deep .van-cell__value {
  line-height: 120px;
}

</style>