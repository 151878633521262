
import { mapState } from 'vuex'
import {Options, Vue} from 'vue-class-component';
import { saveQuestionnaire } from "@/api/memberQuestionnaire";
import wx from "wx"

@Options({
    computed: mapState([
        'questionnaireList'
    ]),
    data(){
        return {
            list: [],
            memberId: null,
            form: {
                id: undefined,
                memberId: null,
                // 症状数据
                jsonData: [],
                // 问卷类型：1疾病信息采集，2睡眠问卷采集
                type: null,
            }
        }
    },
    methods: {
        submitSleepQuestionnaireSave(value: any){
            let memberId = this.memberId
            if(!memberId){
                this.$toast("会员ID不存在，非法操作哦")
                return
            }

            this.form.id = undefined

            this.form.memberId = memberId

            let list = this.list;

            // 删除不是需要组装数据的对象
            delete value['otherInfo']

            let keys = Object.keys(value)

            let result = [];


            for(let groupKey of keys) {
                let groupValue = value[groupKey]

                // 组下选中项的一级value值
                let selectedValueItems = Object.values(groupValue)

                // 组对象
                let group = list.find(v => v.name === groupKey)

                // 根据选中项进而获取组对象里的实际对象信息。
                let groupSelectedItems = group.selectItems.filter(v => selectedValueItems.includes(v.value));


                const groupResult = {
                    groupName: group.name,
                    selectedItems: []
                };

                // 处理子项的关系
                if(group._isChild){

                    let refConditionItemBelongRoot = group._refConditionItemBelongRoot
                    let refConditionItem = group._refConditionItem

                    // 添加对条件组的引用
                    // 前置条件组的组名，具有唯一值
                    groupResult['refGroupName'] = refConditionItemBelongRoot.name
                    // groupKey对象所属的前置条件组的可选项的执行项，便于回溯。
                    groupResult['refGroupValueItem'] = refConditionItem.value

                    // 增加标识字段，便于区分出顶级和子项的标识字段。
                    groupResult['isChild'] = true;
                }


                // 处理组下的选中值的对象
                groupSelectedItems.forEach(v => {
                    groupResult.selectedItems.push({
                        value: v.value
                    });
                })

                result.push(groupResult)

            }

            console.log("请求表单对象",result,JSON.stringify(result));
            
            this.form.jsonData = JSON.stringify(result);

            saveQuestionnaire(this.form).then(respData => {
                this.$toast.success({
                    message: "保存成功",
                    overlay: true,
                    forbidClick: true,
                    duration: 2500,
                    onClose: function(){
                        // 跳转到健康问卷页面
                        wx.miniProgram.redirectTo({
                            url: '/pages/user/health/scheme/improve/SleepImproveScheme?memberId=' + memberId,
                        });
                    }
                })

            }).catch(response => {
                this.$toast.fail(response.msg);
            })
            
        }

    },
    created(){
        console.log("Questionnaire",this.$route.query,this.questionnaireList);
        let {memberId} = this.$route.query;
        this.memberId = memberId;

        let questionnaireList = JSON.parse(JSON.stringify(this.questionnaireList || '[]'))
        questionnaireList.forEach(v => {
            v['selectedValueItems'] = []
        })
        this.list = questionnaireList

        // 问卷类型：1疾病信息采集，2睡眠问卷采集
        this.form.type = 2;

    },  
    beforeRouteEnter(to, from) {
        document.body.style.backgroundColor="#eeeff4";
    },
    beforeRouteLeave(to, from) {
        document.body.style.backgroundColor=''
    },
})
export default class Questionnaire extends Vue {
    list!: any[];
    memberId!: string;
    groupChecked!: any[];
    submitSleepQuestionnaireSave!: Function;
}


