<template>
  <div>
    <div class="top-box">
      <van-button icon="scan" type="default" round @click="scanBind"
        >扫码添加成员</van-button
      >
    </div>
    <template v-if="loadFinished">
      <template v-for="(item, index) in list" :key="index">
        <van-cell is-link @click="showMoreOperateActionSheet(item)">
          <template #title>
            <van-swipe-cell>
              <!-- @click="changeCurrentMember(item.id)" -->
              <div class="member clearfix">
                <div class="imgBox fl">
                  <van-image
                    class="avatar__wrapper"
                    round
                    fit="cover"
                    :src="item.avatar && item.avatar || defaultAvatar"
                  />
                  <div class="online" v-if="item.wearing == 1"></div>
                </div>
                <div class="username fl">
                  {{ item.userName }}
                  <span style="position: relative;top: -1px;">
                    <van-tag round color="#efefef" text-color="rgb(159, 102, 204)">UID： {{ item.uid }}</van-tag>
                  </span>
                </div>
                <!-- <div class="quesBtn fr purple">趋势报告</div> -->
                <!-- <div class="quesBtn fr blue" @click.stop="toReportList(item.id)">测评报告</div> -->
              </div>
              <!-- <template #right>
                <div class="del-btn" @click="delFn(item.id)">删除</div>
              </template> -->
            </van-swipe-cell>



            
          </template>
        </van-cell>
      </template>
    </template>
    <van-overlay v-else :show="true">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
        <van-loading  color="#1989fa" />
      </div>
    </van-overlay>

    <van-action-sheet 
      title="选择操作"
      cancel-text="关闭"
      close-on-click-action 
      v-model:show="activeSheetShow" 
      :actions="actionSheetButton" 
      @close="closeMoreOperateActionSheet">
    </van-action-sheet>
    
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import wx from "wx";
import { bind, bindUserList, untie, changeCurrentMember } from "@/api/member";

export default {
  data() {


    const changeCurrentMember = (action) => {
      console.log("changeCurrentMember", this, action);
      let activeSheetData = this.activeSheetData
      this.changeCurrentMember(activeSheetData.id)
    }

    const toReportList = (action) => {
      console.log("toReportList", this, action);
      let activeSheetData = this.activeSheetData
      this.toReportList(activeSheetData.id);
    }

    const toHealthDiary = (action) => {
      console.log("toHealthDiary", this, action);
      let activeSheetData = this.activeSheetData
      wx.miniProgram.navigateTo({
        url: '/pages/member/collectDeviceData/CollectDeviceData?_callMode=h5&_callEntry=SwitchMember&activeTab=agencyEntry&scanState=ok&scanResult=' + activeSheetData.bind_code,
      })
    }

    const toUserQuestionaireList = (action) => {
      console.log("toUserQuestionaireList", this, action);
      let activeSheetData = this.activeSheetData
      wx.miniProgram.navigateTo({
        url: '/pages/user/userQuestionnaireList/userQuestionnaireList?memberId=' + activeSheetData.id,
      })
    }

    
    const toMemberHealthQuestionaireList = (action) => {
      console.log("toMemberHealthQuestionaireList", this, action);
      let activeSheetData = this.activeSheetData
      wx.miniProgram.navigateTo({
        url: '/pages/healthQuestionnaire/healthQuestionnaireList?memberId=' + activeSheetData.id,
      })
    }

    return {
      loadFinished: false,
      titleName: "成员管理",
      list: [],
      defaultAvatar: require('@/assets/img/icon/user/默认头像@2x.png'),
      activeSheetData: null,
      activeSheetShow: false,
      actionSheetButton: [
        {
          name: '切换成员',
          callback: changeCurrentMember,
        },
        {
          name: '测评报告',
          callback: toReportList,
        },
        {
          name: '健康日记',
          subname: '为此成员代填写健康日记',
          callback: toHealthDiary,
        },
        {
          name: '调查问卷',
          subname: '为此成员填写调查问卷',
          callback: toUserQuestionaireList,
        },
        {
          name: '健康问卷',
          subname: null,
          callback: toMemberHealthQuestionaireList,
        },
      ],
    };
  },
  components: {},
  created() {
    if (this.$route.query.memberPageIsScanSkip === "true") {
      if (this.$route.query.memberPageScanCode === "err") {
        Toast("请扫描正确的二维码");
        bindUserList().then((resData) => {
          this.list = resData || [];
        }).finally(v=>{
          this.loadFinished = true;
        });
      } else {
        bind(this.$route.query.memberPageScanCode)
          .then(() => {
            Toast("绑定成功");
            bindUserList().then((resData) => {
              this.list = resData || [];
            }).finally(v=>{
              this.loadFinished = true;
            });
          })
          .catch((err) => {
            console.log("err///////", err);
            Toast(err?.msg || "绑定失败");
            bindUserList().then((resData) => {
              this.list = resData || [];
            }).finally(v=>{
              this.loadFinished = true;
            });
          });
      }
    } else {
      bindUserList().then((resData) => {
        this.list = resData || [];
      }).finally(v=>{
        this.loadFinished = true;
      });
    }
  },
  methods: {
    closeMoreOperateActionSheet(){
      console.log("关闭更多操作面板");
      this.activeSheetData = null;
    },
    showMoreOperateActionSheet(dataItem){
      console.log("打开更多操作面板");
      this.activeSheetData = dataItem;
      this.activeSheetShow = true;
    },
    scanBind() {
      wx.miniProgram.redirectTo({
        url: "/pages/member/bindMember/bindMember?url=switchMembers",
      });
    },
    delFn(id) {
      Dialog.confirm({
        title: "确定删除吗？",
      }).then((res) => {
        console.log(res);
        if (res === "confirm") {
          console.log(id);
          untie(id, this.deviceIdentity).then(res => {
            Toast('删除成功', res)
            bindUserList().then((resData) => {
              this.list = resData || [];
            });
          }).catch(err => {
            Toast(err?.msg || '删除失败')
          })
        }
      });
    },
    toReportList(id) {
      wx.miniProgram.navigateTo({
        url: "/pages/member/reportList/reportList?id=" + id,
      });
    },
    changeCurrentMember(id) {
      this.$toast.loading({
        message: "切换中",
        overlay: true,
        closeOnClickOverlay: false,
        forbidClick: true,
        duration: 0,
      })
      changeCurrentMember(id).then(res => {
        this.$toast.success({
          message: "切换成功",
          type: 'success',
          overlay: true,
          closeOnClickOverlay: false,
          forbidClick: true,
          onClose: () => {
            wx.miniProgram.navigateTo({
              url: "/pages/health/healthReload",
            });
          }
        })
      }).catch(err => {
        console.log(err)
        Toast('切换失败')
      })
    },
  },
};
</script>

<style scoped lang="stylus">
@import '../css/member.styl'

.member {
  width: auto;
  border-bottom: 0;
  margin: 0 0;
  padding: 0;
  box-sizing: border-box;
}

.avatar__wrapper {
  width: 80px;
  height: 80px;
}

.van-cell >>> .van-cell__right-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;  
}
</style>
