import request from "@/utils/request";

/**
 * 获取jsapi 签名
 * @returns {*}
 */
 export function getJsapiSign(url) {
  return request.get("/api/wx/getJsapiSign",{
     url
  }, {
      login: false
  });
}
