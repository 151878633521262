
import { Options, Vue } from 'vue-class-component';
import wx from 'wx'
import { getDetail } from "@/api/sleepSolution"
import moment from 'moment';

import html2canvas from 'html2canvas';
import { thisTypeAnnotation } from '@babel/types';

@Options({
    data() {
        return {
            saveAsImageLoading: false,
            // 详情数据
            info: {},
            // 会员ID
            memberId: null,
            // 睡眠方案ID
            sleepSolutionId: null,
        }
    },
    methods: {
        /**
         * 跳转到顾问页面
         */
        toAdvisory(){
            wx.miniProgram.navigateTo({
                url: '/pages/user/myAdviser/myAdviser',
            })
        },
        saveAsImage(){
            let that = this;
            try {

                this.saveAsImageLoading = true;
                html2canvas(document.body,{
                    scrollY: 0,
                    allowTaint: true,
                    useCORS: true,
                    backgroundColor: "#eeeff4",
                }).then(function(canvas) {
                    let dataUrl = canvas.toDataURL("image/jpeg", 1);
                    console.log("POST dataUrl",dataUrl);
                    
                    wx.miniProgram.postMessage({
                        data: {
                            documentDataUrl: dataUrl,
                            extendType: 'jpeg'
                        }
                    });
                    
                    // 关闭当前页面，以触发PostMessage的绑定消息处理
                    // wx.miniProgram.navigateBack({
                    //     delta: 1,
                    // })

                    // 刷新当前详情页面，相当于触发了PostMessage的绑定消息处理
                    wx.miniProgram.redirectTo({
                        url: '/pages/user/health/scheme/info/SchemeInfo?memberId=' + that.memberId + '&sleepSolutionId=' + that.sleepSolutionId,
                    });

                }).finally(()=>{
                    this.saveAsImageLoading = false;
                });

            } catch (e) {
                this.saveAsImageLoading = false;   
                this.$toast.fail("生成图片失败", e)
                console.error("保存为图片失败，异常信息：", e)
            }
        }
    },
    created() {
        console.log("方案详情",this.$route);
        
        const {memberId,sleepSolutionId} = this.$route.query;
        this.member = memberId;
        this.sleepSolutionId = sleepSolutionId;

        getDetail(sleepSolutionId).then(respData => {
            // respData.payTime = Date()
            // format('YYYY-MM-DD')

            if(respData.payTime){
                respData._schemeStartTimeMoment = moment(respData.payTime)
                respData._schemeEndTimeMoment = moment(respData._schemeStartTimeMoment).add(15,'d')
            }

            this.info = respData;
        }).catch(response => {
            this.$toast.fail("获取方案详情失败，原因：" + response.msg)
        })


    },  
    beforeRouteEnter(to, from) {
        document.body.style.backgroundColor="#eeeff4";
    },
    beforeRouteLeave(to, from) {
        document.body.style.backgroundColor=''
    },
})
export default class SchemeInfo extends Vue {
    toAdvisory!: Function;

    info: any[];

    saveAsImageLoading!: boolean;
    saveAsImage!: Function;

}

