<template>
  <div>
    <div class="SelectTimeCall">
      <div class="leftTitle">
        {{ title || "选择时间" }}
      </div>
      <div class="rightContent" @click="showPopup">
        <div class="time">{{ TodayDate || "2010-1-1 11:05" }}</div>
        <van-icon name="arrow" class="" />
      </div>
    </div>
    <van-popup
      v-model:show="show"
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="setTime"
        @cancel="cancelTime"
    /></van-popup>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props:['time'],
  data() {
    return {
      show: false,
      TodayDate: "",
    };
  },
  mounted(){
    this.TodayDate=this.time
  },
  setup() {
    const currentDate = ref(new Date());
    return {
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate,
    };
  },

  methods: {
    showPopup() {
      this.show = true;
    },
    setTime(e) {
      this.TodayDate = `${e.getFullYear()}-${
        e.getMonth() + 1
      }-${e.getDate()} ${e.getHours()}:${e.getMinutes()}`;
      this.$emit("TodayDate", e);
      this.show = false;
    },
    cancelTime() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="stylus">
.SelectTimeCall {
  box-sizing: border-box;
  margin: 20px;
  height: 100px;

  .leftTitle {
    width: 20%;
    height: 100px;
    line-height: 100px;
    font-size: 26px;
    float: left;
  }

  .rightContent {
    width: 78%;
    float: left;
    height: 100px;
    line-height: 100px;
    font-size: 26px;
    text-align: right;

    .time {
      height: 100px;
      line-height: 100px;
      font-size: 26px;
      color: #ccc;
      float: left;
      margin-left: 50%;
    }
  }
}
</style>