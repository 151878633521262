<template>

 <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"  v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component :is="Component"  v-if="!$route.meta.keepAlive"/>
  </router-view> 

  <!-- <router-view/> -->
</template>


<script>
import { Toast } from "vant";
import wx from 'wx';
import {getJsapiSign} from '@/api/weixin'
import VConsole from 'vconsole';
// const vConsole = new VConsole();

export default {
  created() {
    // this.getSign()
    // this.wxReady()
  },
  methods: {
    getSign() {
      const url = window.location.href
      getJsapiSign(url).then(item =>{
        this.wxConfig(item)
      })
    },
    wxConfig(item) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: item.appId, // 必填，公众号的唯一标识
        timestamp: item.timestamp, // 必填，生成签名的时间戳
        nonceStr: item.nonceStr, // 必填，生成签名的随机串
        signature: item.signature,// 必填，签名
        jsApiList: ['chooseImage','scanQRCode',], // 必填，需要使用的JS接口列表
      })

    },
    wxReady() {
      wx.ready(function() {
        wx.checkJsApi({
          jsApiList: ['chooseImage','scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          }
        });
      });

      wx.error(function(res){
        console.log(res);
      });
    },
  }
};
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color var(--txt-color)
  font-size 32px
</style>
