import axios from 'axios';
import wx from 'wx';

// import { VUE_APP_API_URL } from "@/utils/index";
// import qs from 'qs';

let authorizationEd = false

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  
  // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
  // 如果请求话费了超过 `timeout` 的时间，请求将被中断
  timeout: 30000
});

const defaultOpt = { login: true,
  nativeAxios: false,
};

function baseRequest(options: any) {
  const headers = options.headers || {};
  headers['Authorization'] = localStorage.getItem('token');
  options.headers = headers
  
  return instance(options).then(res => {
    let respData = res.data;

    let data = null;
    if(respData.data !== undefined && respData.data != null){
      data = respData.data;
    }else{
      // data = {};
    }
    
    if (respData.code === 200) {
      return Promise.resolve(options.nativeAxios ? respData : data);
    } else {
      return Promise.reject(respData);
    }
  }).catch(err => {
    
    if ((err === 'Error: Request failed with status code 401') || (err.response?.status === 401)) {

      // 判断请求url是否携带了token字符串，再判断是否一致，如果不一致，将新的token设置到localStorage中。
      let urlParams= new URLSearchParams(location.hash.substring(location.hash.indexOf("?")))
      if(urlParams.has("token")){
        console.log("请求url具备token参数");
        let oldToken = localStorage.getItem('token');
        let newToken = urlParams.get("token");
        if(newToken !== "" && oldToken !== newToken){
          console.log("刷新token，NewToken：", newToken);
          localStorage.setItem('token', newToken)
          window.location.reload();
          return new Promise(()=>null)
        }
      }

      if (!authorizationEd) {
        wx.miniProgram.reLaunch({ url:'/pages/authorization/authorization?' + `_callMode=h5` });
        authorizationEd = true
      }
    } else {
      return Promise.reject(err);
    }
  });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ["post", "put", "patch"].reduce((request: any, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url: any, data = {}, options = {}) => {
    // data = qs.stringify(data)
    return baseRequest(
      Object.assign({ url, data, method }, defaultOpt, options)
    );
  };
  return request;
}, {});

["get", "delete", "head"].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */

  request[method] = (url: any, params = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, params, method }, defaultOpt, options)
    );
  };
});

export default request;
