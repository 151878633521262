<template>
  <div>
    <van-nav-bar
      title="血压"
      left-arrow="true"
      @click-left="onClickLeft"
    ></van-nav-bar>
  </div>
 <SelectTimeCall @TodayDate='aaa' :time='"2021-11-22 10:22"' />
  <div class="inputBox">
    <div class="inputLeftBox">
      <div class="inputContent title">收缩压</div>
      <div class="inputContent">(高压 毫米汞柱)</div>
    </div>
    <input class="inputClass" placeholder="请输入收缩压" type="text" />
  </div>

  <div class="inputBox">
    <div class="inputLeftBox">
      <div class="inputContent title">舒张压</div>
      <div class="inputContent">(低压 毫米汞柱)</div>
    </div>
    <input class="inputClass" placeholder="请输入舒张压" type="text" />
  </div>
</template>

<script>
import SelectTimeCall from "@/components/SelectTimeCall";
export default {
  components: {
    SelectTimeCall,
  },
  methods: {
    goToUrl(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="stylus">
.inputBox {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 4px solid #eee;
  padding: 20px;
  height: 100px;

  .inputContent {
    height: 40px;
    width: 200px;
    line-height: 40px;
    font-size: 26px;
    text-align: left;
  }

  .title {
    font-size: 30px;
  }
}

.inputLeftBox {
  float: left;
}

.inputClass {
  float: left;
  border: 0;
  width: 70%;
  height: 70px;
  line-height: 70px;
}

.seletDate {
  float: left;
  text-align: right;
  width: 70%;
  height: 70px;
  line-height: 70px;
}

.date {
  padding-top: 18px;
}
</style>