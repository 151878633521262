
import { Options, Vue } from 'vue-class-component';
import { getSleepSolution } from "@/api/sleepSolution"
import wx from "wx"



@Options({
    data() {
        return {

            list: [
                {
                    title: null,
                    content: null,
                    isRichText: false,
                },
            ],
            
            memberId: null,
        }
    },
    methods: {
        toPayment(){
            let memberId = this.memberId

            getSleepSolution(memberId).then(respData => {
                console.log("获取改善提升产品的详情成功",respData);

                let {
                    productList, 
                    member_id:memberId,
                    tenant_id:tenantId,
                    id:sleepSolutionId
                } = respData

                let queryParams = {
                    sleepSolutionId,
                    tenantId,
                    memberId,
                    productList: productList.map(v => ({ productId: v.product_id,productNum: v.product_num, productName: v.product_name }))
                }

                if(queryParams.productList.length <= 0){
                    this.$dialog.alert({
                        confirmButtonText: '我知道了',
                        confirmButtonColor: 'rgb(116, 83, 213)',
                        message: `当前方案（${queryParams.sleepSolutionId}）没有产品信息噢`,
                    })
                    return
                }


                wx.miniProgram.navigateTo({
                    url: '/pages/mall/productPayment/productPayment?' + `dataJson=${JSON.stringify(queryParams)}`
                })


            }).catch(response => {
                this.$toast.fail(response.msg)
            })
        }
    },
    created() {
        console.log("SleepImproveScheme",this.$route.query,this.questionnaireList);
        let {memberId} = this.$route.query;
        this.memberId = memberId;


        this.list = [
            {
                title: "睡眠改善方案简介",
                content: `
                   <p>1、专属睡眠改善方案是在您测评报告基础上，根据具体测评指标反映的情况，对不同原因产生的失眠（或睡眠质量不佳）进行科学分型，结合具有丰富临床经验中医师评估论证 所形成的个性化专家调理方案。</p>
                <p>2、和市面其他调理类方案产品“盲测”不同，我们使用专业工具和科学的失眠辩证分型方法，找到用户失眠真正原因所在，通过专业算法配比出个性化方案，优选膏方产品帮助用户改善睡眠，拜托失眠困扰。</p>
                <p>3、调理方案中的产品均选用同仁堂系列优选膏方，原料品质好，功效成分含量高，可放心食用。</p>
                <p style="text-indent: 2em;"><span style="font-weight: 600;">特别提示：</span>产品含有蜂蜜，糖尿病患者、反酸烧心人群 不建议使用。</p>
                <p style="color:#7453D5;text-indent: 2em;">您的专属睡眠改善方案已生成，点击下方一键支付按钮，获取专属方案。</p>
                `,
                isRichText: true,
            },
            {
                title: "为什么选用膏方产品",
                content: `
                <p>汤方和膏方功效不同，做成汤剂和做成膏方也是不同的。从临床经验来看，膏方的滋补作用更温和、更持久，更容易避免上火，并有较好的滋补效果。膏方是反复煎煮，浓缩凝炼药中的补虚精华，而成为膏滋的，膏滋主要适合于补虚。而汤药煎煮时间比膏方短，相差甚大，煎出来的成分也会有明显区别。</p>
                <p>归纳起来，膏方有以下几大好处，一是药味多，可多方兼顾；二是效果温和而持久；三是每日服用量小，能节省药材；四是口感好，不易伤胃；五是体积小，携带方便，而且可以长时间保存。</p>
                `,
                isRichText: true,
            },
        ]

    },  
    beforeRouteEnter(to, from) {
        document.body.style.backgroundColor="#eeeff4";
    },
    beforeRouteLeave(to, from) {
        document.body.style.backgroundColor=''
    },
})
export default class SleepImproveScheme extends Vue {
    toPayment!: Function;
    list!: any[];
    member!: string;
}

