
import { Options, Vue } from "vue-class-component";
import { getCurrentUserInfo } from "@/api/index";
import TrendItem from "./components/Item7.0.vue";

@Options({
  components: {
    TrendItem,
  },
  data: () => {
    return {
      active: 0,
      defaultAvatar: require("@/assets/img/icon/user/默认头像@2x.png"),
      userInfo: {},
    };
  },
  created() {
    getCurrentUserInfo().then((res) => {
      if (res.sex == '1') {
        res.sex = '男'
      }
      if (res.sex == '2') {
        res.sex = '女'
      }
      this.userInfo.userName = res.userName;
      this.userInfo.sex = res.sex;
      this.userInfo.avatarUrl = res.avatar;
      this.userInfo.age = res.age;
    });
  },
})
export default class Trend extends Vue {}
