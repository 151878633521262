import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import ExpertReport from '../views/report/ExpertReport.vue'
import Report from '../views/report/Report.vue'
import index from '../views/index/index.vue'
import Login from '../views/login.vue'
import Home from '../views/home/Home.vue'
import Health from '../views/home/Health.vue'
import HealthReport from '../views/user/healthReport/index.vue'
import Members from '../views/user/members/index.vue'
import feeback from '../views/user/feeback/index.vue'
import User from '../views/user/User.vue'
import text from '../views/index/text.vue'
import news from '../views/index/news.vue'
import EquipmentSet from '../views/home/EquipmentSet/index.vue'
import WIFISet from '../views/home/EquipmentSet/WIFISet/index.vue'
import wifiEdit from '../views/home/EquipmentSet/wifiEdit/wifiEdit.vue'
import searchWifi from '../views/home/EquipmentSet/searchWifi/searchWifi.vue'
import AboutDevice from '../views/home/EquipmentSet/AboutDevice/index.vue'
import HeartRate from '../views/home/HealthIndex/HeartRate.vue'
import BloodPressure from '../views/home/BloodPressure/BloodPressure.vue'
import XyParticulars from '../views/home/BloodPressure/XyParticulars.vue'
import Weight from '../views/home/Weight/Weight.vue'
import WeightInput from '../views/home/Weight/WeightInput.vue'
import WeightList from '../views/home/Weight/WeightList.vue'
import BloodGlucose from '../views/home/BloodGlucose/BloodGlucose.vue'
import BloodGlucoseWrite from '../views/home/BloodGlucose/bloodGlucoseWrite.vue'
import BloodGlucoseHistory from '../views/home/BloodGlucose/BloodGlucoseHistory.vue'
import Trend from '../views/trend/Trend.vue'
import evaluationReportList from '../views/home/evaluation/evaluationReportList.vue'
import userInfo from '../views/user/userInfo.vue'
import newUserInfo from '../views/user/newUserInfo.vue'
import ShareReport from '../views/share/report/ShareReport.vue'
import MyQR from '../views/user/myQR/MyQR.vue'
import MyAdviser from '../views/user/myAdviser/MyAdviser.vue'
import MemberList from '../views/member/memberList/MemberList.vue'
import SwitchMembers from '../views/member/switchMembers/SwitchMembers.vue'
import AddMember from '../views/member/addMember/AddMember.vue'
import MemberReportList from '../views/member/reportList/ReportList.vue'
import MemberEquipment from '../views/member/equipment/Equipment.vue'
import MemberAboutDevice from '../views/member/equipment/aboutDevice/AboutDevice.vue'
import MemberSearchWifi from '../views/member/equipment/searchWifi/SearchWifi.vue'
import MemberWifiEdit from '../views/member/equipment/wifiEdit/WifiEdit.vue'
import MemberWifiSet from '../views/member/equipment/wifiSet/WifiSet.vue'
import MemberInfo from '../views/member/memberInfo/MemberInfo.vue'
import Report71 from '../views/share/report7_1/Report7_1.vue'
import Report80 from '../views/share/report8_0/Report8_0.vue'
import Trend7 from '../views/trend/Trend7.0.vue'
import Trend8 from '../views/trend/Trend8.0.vue'
import Questionnaire from '../views/questionnaire/Questionnaire.vue'
import SchemeCustomize from '../views/scheme/customize/SchemeCustomize.vue'
import SleepImproveScheme from '../views/scheme/improve/SleepImproveScheme.vue'
import SchemeList from '../views/scheme/list/SchemeList.vue'
import SchemeInfo from '../views/scheme/info/SchemeInfo.vue'
import SubscribeManageList from '../views/user/subscribeManage/SubscribeManageList.vue'
import UserQuestionnaire from '@/views/user/UserQuestionnaire.vue'
import UserQuestionnaireList from '@/views/user/UserQuestionnaire/UserQuestionnaireList.vue'
import CollectDeviceData from '@/views/member/collectDeviceData/CollectDeviceData.vue'
import healthQuestionnaire from '@/views/healthQuestionnaire/healthQuestionnaire.vue'
import expertSuggest from '@/views/healthQuestionnaire/expertSuggest/expertSuggest.vue'
import ConsultationRecord from '@/views/healthQuestionnaire/consultationRecord/consultationRecord.vue'
import HealthQuestionnaireList from '@/views/healthQuestionnaire/healthQuestionnaireList.vue'
import ExpertSuggestList from '@/views/healthQuestionnaire/expertSuggest/expertSuggestList.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // name: 'Home',
    // component: Home,
    // meta:{keepAlive:true}
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta:{keepAlive:true}
  },
  {
    path:"/health",
    name:'heath',
    component:Health,
  },
  {
    path: '/user',
    name: 'user',
    component: User,
  },
  {
    path: '/expertReport/:reportId',
    name: 'ExpertReport',
    component: ExpertReport
  },
  {
    path: '/report/:reportId/:memberId',
    name: 'Report',
    component: Report
  },
  {
    // 健康报告
    path: '/healthReport',
    name: 'HealthReport',
    component: HealthReport
  },
  {
    // 意见反馈
    path: '/feeback',
    name: 'feeback',
    component: feeback
  },
  {
    // 文章
    path: '/text/:bannerId/:id/:iconId',
    name: 'text',
    component: text,
  },
  {
    // 消息
    path: '/news',
    name: 'news',
    component: news
  },
  {
    // 成员管理
    path: '/members',
    name: 'Members',
    component: Members
  },
  {//设备设置页
    path: '/equipmentSet',
    name: 'EquipmentSet',
    component: EquipmentSet
  },
    {//设备设置页
      path: '/equipmentSet/aboutDevice',
      name: 'AboutDevice',
      component: AboutDevice
    },
    {//心率页
      path: '/heartRate',
      name: 'HeartRate',
      component: HeartRate
    },
    {//血压页
      path: '/bloodPressure',
      name: 'BloodPressure',
      component: BloodPressure
    },
    {//血压详情页
      path: '/bloodPressure/xyParticulars',
      name: 'XyParticulars',
      component: XyParticulars
    },
    {//体重页
      path: '/weight',
      name: 'Weight',
      component: Weight
    },
    {//体重页
      path: '/weight',
      name: 'Weight',
      component: Weight
    },
    {//体重列表页
      path: '/weightList',
      name: 'WeightList',
      component: WeightList
    },
    {//体重手动输入页
      path: '/weightInput',
      name: 'WeightInput',
      component: WeightInput
    },
    {//血糖页
      path: '/bloodGlucose',
      name: 'BloodGlucose',
      component:BloodGlucose
    },
    {//血糖手动填写页
      path: '/bloodGlucoseWrite',
      name: 'BloodGlucoseWrite',
      component:BloodGlucoseWrite
    },
    {//血糖手动填写页
      path: '/bloodGlucoseHistory',
      name: 'BloodGlucoseHistory',
      component:BloodGlucoseHistory
    },
    {//血糖手动填写页
      path: '/wIFISet',
      name: 'WIFISet',
      component:WIFISet
    },
    {  // 趋势报告
      path: '/trend',
      name: 'Trend',
      component:Trend
    },
    {
      path: '/evaluationReportList',
      name: 'evaluationReportList',
      component: evaluationReportList
    },
    {
      path: '/userInfo',
      name: 'userInfo',
      component: userInfo
    },
    {
      path: '/newUserInfo',
      name: 'newUserInfo',
      component: newUserInfo
    },
    {
      path: '/wifiEdit',
      name: 'wifiEdit',
      component: wifiEdit
    },
    {
      path: '/searchWifi',
      name: 'searchWifi',
      component: searchWifi
    },
  {
    // 登录
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/share/report/:reportId/:memberId',
    name: 'ShareReport',
    component: ShareReport
  },
  {
    path: '/myQR',
    name: 'MyQR',
    component: MyQR
  },
  {
    path: '/myAdviser',
    name: 'MyAdviser',
    component: MyAdviser
  },
  {
    path: '/memberList',
    name: 'MemberList',
    component: MemberList
  },
  {
    path: '/switchMembers',
    name: 'SwitchMembers',
    component: SwitchMembers
  },
  {
    path: '/addMember',
    name: 'AddMember',
    component: AddMember
  },
  {
    path: '/memberReportList',
    name: 'MemberReportList',
    component: MemberReportList
  },
  {
    path: '/memberEquipment',
    name: 'MemberEquipment',
    component: MemberEquipment
  },
  {
    path: '/memberAboutDevice',
    name: 'MemberAboutDevice',
    component: MemberAboutDevice
  },
  {
    path: '/memberSearchWifi',
    name: 'MemberSearchWifi',
    component: MemberSearchWifi
  },
  {
    path: '/memberWifiEdit',
    name: 'MemberWifiEdit',
    component: MemberWifiEdit
  },
  {
    path: '/memberWifiSet',
    name: 'MemberWifiSet',
    component: MemberWifiSet
  },
  {
    path: '/memberInfo',
    name: 'MemberInfo',
    component: MemberInfo
  },
  {
    path: '/report7_1/:reportId/:memberId',
    name: 'Report7_1',
    component: Report71
  },
  {
    path: '/report8_0/:reportId/:memberId',
    name: 'Report8_0',
    component: Report80
  },
  {
    path: '/trend7',
    name: 'Trend7',
    component: Trend7
  },
  {
    path: '/trend8',
    name: 'Trend8',
    component: Trend8
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: Questionnaire
  },
  {
    path: '/schemeCustomize',
    name: 'schemeCustomize',
    component: SchemeCustomize
  },
  {
    path: '/sleepImproveScheme',
    name: 'sleepImproveScheme',
    component: SleepImproveScheme
  },
  {
    path: '/schemeList',
    name: 'schemeList',
    component: SchemeList
  },
  {
    path: '/schemeInfo',
    name: 'schemeInfo',
    component: SchemeInfo
  },
  {
    path: '/subscribeManageList',
    name: 'subscribeManageList',
    component: SubscribeManageList
  },
  {
    path: '/userQuestionnaire',
    name: 'userQuestionnaire',
    component: UserQuestionnaire
  },
  {
    path: '/userQuestionnaireList',
    name: 'userQuestionnaireList',
    component: UserQuestionnaireList
  },
  {
    path: '/collectDeviceData',
    name: 'collectDeviceData',
    component: CollectDeviceData
  },
  {
    path: '/healthQuestionnaire',
    name: 'healthQuestionnaire',
    component: healthQuestionnaire
  },
  {
    path: '/healthQuestionnaireList',
    name: 'healthQuestionnaireList',
    component: HealthQuestionnaireList
  },
  {
    path: '/expertSuggest',
    name: 'expertSuggest',
    component: expertSuggest
  },
  {
    path: '/expertSuggestList',
    name: 'expertSuggestList',
    component: ExpertSuggestList
  },
  {
    path: '/consultationRecord',
    name: 'consultationRecord',
    component: ConsultationRecord
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
