import request from "@/utils/request";


export function findHealthQuestionList(memberId: number) {
    return request.get("/api/member/health/question/list",{
        memberId: memberId
    });
}


export function findHealthQuestionListById(id: number) {
    return request.get(`/api/member/health/question/${id}/list`);
}


export function saveHealthQuestion(data: any) {
    return request.post("/api/member/health/question/save",data);
}


export function getMemberHealthQuestion(id: number) {
    return request.get("/api/member/health/question/memberQuestion",{
        id: id
    });
}


export function getLastMemberHealthQuestion(memberId: number) {
    return request.get("/api/member/health/question/lastMemberQuestion",{
        memberId: memberId
    });
}


export function saveHealthQuestionSuggest(data: any) {
    return request.post("/api/member/health/question/suggest/save",data);
}


export function findMemberHealthQuestionRecord(memberId: string) {
    return request.get("/api/member/health/question/recordList",{
        memberId: memberId,
    });
}

export function findMemberHealthQuestionRecordById(id: string) {
    return request.get(`/api/member/health/question/${id}/recordList`);
}

/**
 * 查询问卷记录列表
 * @param memberId 
 * @param pageNo 
 * @returns 
 */
export function questionRecordList(memberId: number, pageNo: number) {
    return request.get("/api/member/health/question/questionRecordList",{
        memberId: memberId,
        pageNo: pageNo,
    });
}

/**
 * 待处理列表
 * @param pageNo 
 * @returns 
 */
export function waitingSuggestList(pageNo: number) {
    return request.get("/api/member/health/question/waitingSuggestList",{
        pageNo: pageNo,
    });
}