
import { Options, Vue } from 'vue-class-component';      
import ScoreNum from '@/components/report/ScoreNum.vue';                   
const dataTypeNemeList = ['精神状态测评', '睡眠测评', '生命节律测评', '心血管功能测评', '心理状态测评', '生命活力测评', '寒热状态测评']
const xarList: Array<number> = [];
const xstList: Array<number> = [2];
const xvlaList: Array<number> = [2];
const xasrdList: Array<number> = [3];
const xbarList: Array<number> = [4];
const xaarList: Array<number> = [4];
const gsaList: Array<number> = [];
const glaList: Array<number> = [4];
const gsdaList: Array<number> = [4];
const svlList: Array<number> = [6];
const smrList: Array<number> = [7];
const xmaList: Array<number> = [1];
const xsbList: Array<number> = [2];
const gthList: Array<number> = [5];
const gbrList: Array<number> = [5];
const garList: Array<number> = [5];

@Options({
  props: {
    data: [],
    codeList: [],
    ecgCurveUrl: '',
    pulseCurveUrl: '',
    poincareUrl: '',
  },
  components: {
    ScoreNum,
  },
  data() {
    return {
      dataTypeNemeList,
    }
  },
  methods: {
    getHasCode(dataType: number) {
      return {
        hasXarList: xarList.indexOf(dataType) != -1,
        hasXstList: xstList.indexOf(dataType) != -1,
        hasXvlaList: xvlaList.indexOf(dataType) != -1,
        hasXasrdList: xasrdList.indexOf(dataType) != -1,
        hasXbarList: xbarList.indexOf(dataType) != -1,
        hasXaarList: xaarList.indexOf(dataType) != -1,
        hasGasList: gsaList.indexOf(dataType) != -1,
        hasGlaList: glaList.indexOf(dataType) != -1,
        hasGsdaList: gsdaList.indexOf(dataType) != -1,
        hasSvlList: svlList.indexOf(dataType) != -1,
        hasSmrList: smrList.indexOf(dataType) != -1,
        hasXmaList: xmaList.indexOf(dataType) != -1,
        hasXsbList: xsbList.indexOf(dataType) != -1,
        hasGthList: gthList.indexOf(dataType) != -1,
        hasGbrList: gbrList.indexOf(dataType) != -1,
        hasGarList: garList.indexOf(dataType) != -1,
      }
    },
  },
})
export default class ReportSubTitleItem extends Vue {
  data!: any
  codeList!: any
}
