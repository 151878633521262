
import { Options, Vue } from "vue-class-component";
import * as ecarts from "echarts";
import moment from "moment";
import ScoreNum from "@/components/report/ScoreNum.vue";

import { findReportTrend } from "@/api/report";

const dataTypeNemeList = [
  "精神状态测评",
  "睡眠测评",
  "生命节律测评",
  "心血管功能测评",
  "心理状态测评",
  "生命活力测评",
  "寒热状态测评",
];
const xarList = [1];
const xstList = [2];
const xvlaList = [2];
const xasrdList = [3, 4];
const xbarList = [4];
const xaarList = [4];
const gsaList = [5];
const glaList = [5];
const gsdaList = [5, 4];
const svlList = [6];
const smrList = [7];

@Options({
  props: {
    data: [],
    codeList: [],
    dayLength: 0,
  },
  components: {
    ScoreNum,
  },
  data() {
    return {
      dataTypeNemeList,
      dataList: [],
      showList: [
        {
          name: "中医心藏功能态势趋势测评报告",
          val: ["精神状态", "心血管功能"],
        },
        {
          name: "中医肝藏功能态势趋势测评报告",
          val: ["内脏神经协调性", "睡眠质量", "生命节律"],
        },
        {
          name: "中医肾藏功能态势趋势测评报告",
          val: ["生命活力", "寒热状态"],
        },
        {
          name: "中医脾藏功能态势趋势测评报告",
          val: ["体重指数"],
        },
      ],
    };
  },
  // created() {
  //
  // },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      findReportTrend(this.dayLength).then((item) => {
        this.dataList = item;
        this.draw(item);
      });
    },
    draw(item) {
      item.forEach((data, i) => {
        data.resultList.map((m) => {
          m["value"] = m.percentage.replaceAll("%", "");
        });
        this.echartsInit(data, i);
      });
    },
    getHasCode(dataType: number) {
      return {
        hasXarList: xarList.indexOf(dataType) != -1,
        hasXstList: xstList.indexOf(dataType) != -1,
        hasXvlaList: xvlaList.indexOf(dataType) != -1,
        hasXasrdList: xasrdList.indexOf(dataType) != -1,
        hasXbarList: xbarList.indexOf(dataType) != -1,
        hasXaarList: xaarList.indexOf(dataType) != -1,
        hasGasList: gsaList.indexOf(dataType) != -1,
        hasGlaList: glaList.indexOf(dataType) != -1,
        hasGsdaList: gsdaList.indexOf(dataType) != -1,
        hasSvlList: svlList.indexOf(dataType) != -1,
        hasSmrList: smrList.indexOf(dataType) != -1,
      };
    },
    echartsInit(data, i) {
      // 画图会出现获取不到dom信息,尝试延迟100毫秒加载，并设置最大重试为五次
      this.pieInit(data, i, 5);
    },
    pieInit(data, i, retry) {
      let pieDom = document.getElementById(
        "echart-pie-box" + data.name + this.dayLength
      );
      if (pieDom == null && retry > 0) {
        setTimeout(() => {
          console.log("retry pie");
          this.pieInit(data, i, retry - 1);
        }, 100);
        return;
      }
      let myLineChart = ecarts.init(pieDom);
      // 绘制图表
      myLineChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.dataList.map((m) => moment(m.time).format("YYYY-MM-DD")),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data.dataList.map((m) => m.score),
            type: "line",
            // 线条样式
            itemStyle: {
              color: "#3A84FF",
            },
            // 区域填充样式
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(58,132,255, 0.5)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(58,132,255, 0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            legend: {
              left: "center",
              bottom: -10,
            },
            symbolSize: 2,
            lineStyle: {
              width: 1.5,
            },
            emphasis: {
              lineStyle: {
                width: 1.5,
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
      });
    },
  },
})
export default class TrendItem extends Vue {
  data!: any;
  codeList!: any;
  dataList!: any;
}
