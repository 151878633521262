<template>
  <!-- 使用时长 -->
  <div class="health-report card-universal">
    <div class="tbox clearfix">
      <div class="t fl">
        使用时长<van-icon
          name="info-o"
          class="nameicon"
          @click="showNameInfo = true"
        />
      </div>
      <div class="battery fr" v-if="battery != null">
        <div class="fl">手表电量：{{ battery }}%</div>
        <div class="val-line fl" :style="{ backgroundImage: `url(${battery > 80 ? greenBattery : battery > 20 ? orangeBattery : redBattery})` }">
          <div class="val-bg">
            <div class="val" :style="{ width: `${battery}%`, background: battery > 80 ? '#16a839' : battery > 20 ? '#fa8904' : '#e21755' }"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="health-report-cont chart-box">
      <!-- <div class="tip">
        <van-loading v-show="loadIng && !loadEnd" size="30px" vertical
          >加载中...</van-loading
        >
        <van-empty
          v-show="!loadIng && loadEnd && watchUseData && !watchUseData.sum"
          image="network"
          description="无数据"
        />
        <van-empty
          v-show="!loadIng && loadEnd && !watchUseData"
          image="error"
          description="加载失败"
        />
      </div>
      <div class="useTimeBox" v-show="!loadIng && loadEnd && watchUseData && watchUseData.sum"> -->
      <div class="useTimeBox" v-if="watchUseData != null">
        <div class="circle-box">
          <van-circle
            v-model:current-rate="currentRate"
            :rate="watchUseData.sum / (24 * 60 * 60) * 100"
            :speed="100"
            color="#553ac7"
            layer-color="#e6e9f2"
            stroke-width="80"
            size="3.33333rem"
          />
          <div class="circle-cont">
            <div class="num">{{ text || '0%' }}</div>
            <div class="txt">报告完成度</div>
          </div>
        </div>
        <div class="progressBox">
          <div class="name-box">
            <span class="name">有效时长：</span>
            <span class="num">{{ watchUseData.usefulTxt }}</span>
          </div>
          <van-progress
            stroke-width="0.2rem"
            color="#aafaf7"
            :show-pivot="false"
            :percentage="watchUseData.useful / (20 * 60 * 60) * 100"
          />
        </div>
        <div class="progressBox">
          <div class="name-box">
            <span class="name">无效时长：</span>
            <span class="num">{{ watchUseData.uselessTxt }}</span>
          </div>
          <van-progress
            stroke-width="0.2rem"
            color="#fed57d"
            :show-pivot="false"
            :percentage="watchUseData.useless / (4 * 60 * 60) * 100"
          />
        </div>
        <div class="progressBox">
          <div class="name-box">
            <span class="name">佩戴时长：</span>
            <span class="num">{{ watchUseData.sumTxt }}</span>
          </div>
          <van-progress
            stroke-width="0.2rem"
            color="#c7bef3"
            :show-pivot="false"
            :percentage="watchUseData.sum / (24 * 60 * 60) * 100"
          />
        </div>
        <div class="progressBox">
          <div class="name-box">
            <span class="name">最近推送时间：</span>
            <span class="num">{{ watchUseData.endTimeTxt }}</span>
          </div>
        </div>
        
        <div v-if="(watchUseData.useful / 60 / 60) >= 20" style="margin: 20px 0;">
          <van-row>
            <van-col span="20" offset="2" v-if="!manualGenReportState">
                <van-button loading-size="25px" :icon="leafImg" @click="createReport" type="primary" color="rgb(159, 102, 204)" size="normal" block>提前生成报告</van-button>
            </van-col>
            
            <van-col span="24" style="text-align: left;" v-else>
                <p class="hint">请等待5~6分钟后，在报告列表查询最新生成的报告噢！</p>
            </van-col>
          </van-row>
        </div>

        <!-- <div class="hint">
          *佩戴满24小时可生成报告，无效时长累计满4小时无法生成报告哦~
        </div> -->
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="showNameInfo"
    :round="true"
    :closeable="true"
    close-icon="clear"
  >
    <div class="nameinfopopup">
      <div class="txtline">
        有效时长：正确佩戴手表时采集的有效数据。
      </div>
      <div class="txtline">
        <div style="display: flex;">
          <span style="white-space: nowrap;">无效时长：</span>
          <span>因手表充电或洗澡等未佩戴手表产生的时长。</span>
        </div>
      </div>
      <div class="txtline">佩戴时长：有效时长 + 无效时长。</div>
      <div class="txtline">
        <p><span style="font-weight: bold;">温馨提示：</span></p>
        <p class="content">建议连续佩戴20小时以上，测评报告结果会更贴近您的健康情况；无效时长超过4小时，报告准确度会降低或导致无法生成报告；手表充电或不使用的时候，建议将表盘翻转向下放置避免产生无效数据。</p>
        <h3>为什么要一次连续佩戴24小时？</h3>
        <p class="content">自然界有昼夜节律，我们人体亦具有同样的昼夜节律。如血压白天高晚上低；胆汁分泌白天少晚上多。只有连续不间断地佩戴24小时，才能真实有效地反映一个小生命周期的变化，才具有充分的代表性。</p>
      </div>
      <!-- <div class="txtline">
        报告完成度：报告完成度显示85%以上展示生成报告按钮，可以点击生成报告手动生成。但测评的准确度会降低，建议还是以24小时自动生成报告为准。
      </div> -->
    </div>
  </van-popup>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { newestRecord, findWatchStatus } from "@/api/device";
import moment from "moment";
import { getDeviceValidDuration, boundDeviceList } from "@/api/equipmentSet";
import {createReport} from '@/api/report'

@Options({
  props: ["memberId"],
  data() {
    return {
      loadIng: true,
      loadEnd: false,
      watchUseData: null,
      showNameInfo: false,
      currentRate: 0,
      battery: null,
      greenBattery: require('@/components/functionCard/watchUse/green.png'),
      orangeBattery: require('@/components/functionCard/watchUse/orange.png'),
      redBattery: require('@/components/functionCard/watchUse/red.png'),

      // 未点击手动生成报告按钮，true已点击
      manualGenReportState: false,
    };
  },
  computed: {
    text() {
      return this.currentRate ? (this.currentRate.toFixed(0) + "%") : '0%';
    },
  },
  created() {
    this.getData();
    getDeviceValidDuration(3, 3, 3).then((res) => {
      console.log(res);
    });

    boundDeviceList().then(resData => {
      findWatchStatus(resData[0].deviceIdentity).then((res) => {
        console.log(res.battery);
        this.battery = res.battery
      });
    })

    top.testthat = this
    
  },
  methods: {
    
    /**
     * 手动生成报告
     */
     createReport(){
      let memberId = this.memberId


      this.$dialog.confirm({
        message: '提前生成报告会导致报告有效数据率偏低，造成评估结果不可靠，确定要提前生成报告吗？',
      }).then(() => {
        createReport(memberId).then(data => {
          // 生成成功。
          this.$toast.success("提交生成报告成功！")
          console.log("提交生成报告成功",this, data);
        }).catch(resp => {
          this.$toast(resp.msg)
          console.log("提交生成报告失败",this, resp);
        }).finally(()=>{
          this.manualGenReportState = true
        })
      })
      .catch(() => {
        // on cancel
      });

    },
    getData() {
      this.loadIng = true;
      newestRecord()
        .then((data) => {
          this.loadIng = false;
          this.loadEnd = true;

          data = data || {
            useful: 0,
            useless: 0,
            sum: 0,
            endTime: null,
            beginTime: null,
          }

          const useful = moment.duration(data.useful, "seconds");
          data.usefulTxt = moment({
            h: useful.hours(),
            m: useful.minutes(),
          }).format("HH小时mm分钟");

          const useless = moment.duration(data.useless, "seconds");
          data.uselessTxt = moment({
            h: useless.hours(),
            m: useless.minutes(),
          }).format("HH小时mm分钟");

          const sum = moment.duration(data.sum, "seconds");
          data.sumTxt = moment({ h: sum.hours(), m: sum.minutes() }).format(
            "HH小时mm分钟"
          );

          data.endTimeTxt = data.endTime && moment.unix(data.endTime).format(
            "YYYY年MM月DD日 HH:mm"
          );

          data.beginTimeTxt = data.beginTime && moment.unix(data.beginTime).format(
            "YYYY年MM月DD日 HH:mm"
          );

          this.watchUseData = data;
        })
        .catch(() => {
          this.loadIng = false;
          this.loadEnd = true;
        });
    },
  },
})
export default class WatchUse extends Vue {}
</script>

<style scoped lang="stylus">
.useTimeBox
  padding 20px 20px 0

.hint {
  padding: 10px 0 20px;
  text-align: left;
  color: #9c9c9c;
  font-size: 26px;
}

.hint-r {
  font-size: 26px;
  float right;
  margin: 10px 10px
}


.btn-img {
  width: 150px;
  height: 150px;
}

.btn-text {
  margin-top 18px
}

.nameinfopopup
  margin-top 90px
  padding 0 20px 30px
  width 80vw
  max-height 80vh
  overflow-y scroll
  .txtline
    text-align left
    // text-indent 56px
    font-size 30px
    padding 5px 0
    text-shadow: 1PX 1PX 1PX var(--text-shadow-color);
    p
      margin 0
    .content
      text-indent 56px

.tbox
  .t
    position relative
    .nameicon
      position absolute
      top -2px
      right -41px
      font-size 34px
      color #a5a3c4
  .battery
    display: inline-block
    font-size 25px
    .val-line
      position relative
      top 3px
      box-sizing border-box
      width 46px
      height 23px
      margin-left 6px
      background-size cover
      .val-bg
        position absolute
        top 5px
        left 6px
        width 34px
        height: 13px
        border-radius 2px
        .val
          height 100%

.circle-box
  position relative
  .circle-cont
    position absolute
    top calc(50% - 6px)
    left 50%
    transform translate(-50%, -50%)
    width 176px
    height 176px
    background #e6e8f4
    border-radius 50%
    z-index -1
    color #3b345f
    .num
      margin-top 30px
      font-size 50px
    .txt
      font-size 24px

.progressBox
  display flex
  margin-bottom 6px

.name-box
  color #323232
  .name
    font-size 28px
    font-weight 600
  .num
    font-size 24px

.van-progress
  flex 1
  margin 17px 0 0 20px
  overflow hidden

</style>
