<script setup>

import { ref, onMounted, reactive, computed, watch } from 'vue'

import { getMemberBaseInfo, saveUsernameRemark , getMemberBaseInfoByHealthQuestionId} from '@/api/MemberUsernameRemark'
import { saveHealthQuestionSuggest, getMemberHealthQuestion } from '@/api/MemberHealthQuestion'
import { getImgCodetVersion7 } from "@/api/index";
import { getReportBaseInfo } from "@/api/report";
import { uploadImage } from '@/api/uploadFile'
import { Toast,Notify  } from 'vant';
import wx from 'wx';
import { useRouter, useRoute } from 'vue-router'
import { Dialog } from 'vant';
import { useNavigateTo } from '@/composables/WxXCX'

import ImgCodeVersion7 from '@/views/share/components/ImgCodeVersion7_1.vue';
import ImageMemberVip from '@/assets/img/user/member-vip.png'

// 请求传递过来的参数
const params = reactive({
    memberId: null,
})

const data = reactive({
    // 成员的基本信息
    memberBaseInfo: null,
    // 成员的最新一份健康问卷信息
    memberHealthQuestionInfo: null,
    // 正在展示报告信息的报告ID
    showReportBaseInfoById: null,
    // 默认展示的是最新数据，最新的报告信息数据在0位置。
    showReportBaseInfoActiveIndex: 0,
    // 报告信息
    reportInfo: {
        imgList: [],
        codeList: [],
        // 报告信息，0索引指的是健康问卷数据中，最新的一份报告数据
        reportBaseInfos: []
    },
    // 缓存报告信息
    cacheReportInfo: {},

    // 建议的属性数据
    suggestInfo: {
        resourceImages: [],
    }
})

/**
 * 随手记控制参数
 */
const otherInfoNoteConfig = reactive({
    // 输入的内容
    otherInfo: "",
    // 面板的展开/关闭
    show: false,
})


/**
 * 获取van-uoloader组件所需的数据结构。
 */
const getSuggestResourceImages = ref([])

const getShowReportBaseInfo = computed(()=>{
    if(!data.showReportBaseInfoById){
        return {};
    }
    return data.reportInfo.reportBaseInfos.find(v => v.id == data.showReportBaseInfoById)
})

const selectReportActionSheet = reactive({
    show: false,
    actions: [],
    onSelect(action, index){
        // 
        console.log("selectReportActionSheet", action, index);
        data.showReportBaseInfoActiveIndex = index
    }
})



function suggestReourceImageOversize(file) {
    console.log(file);
    Toast('图片大小不能超过20MB');
}

function uploadSuggestResourceImage(fileInfo, detail){
    console.log("uploadSuggestResourceImage", fileInfo, detail);
    fileInfo.status = "uploading"
    fileInfo.message = "上传中..."
    uploadImage('HealthQuestionSuggest', fileInfo.file).then(imgPath => {
        console.log("uploadSuggestResourceImage", imgPath)
        fileInfo.uploadPath = imgPath
        Toast.success('上传成功');
        fileInfo.status = ""
        fileInfo.message = ""
    }).catch(e => {
        Toast.fail('上传失败');
        fileInfo.status = "failed"
        fileInfo.message = "上传失败"
    });
}



function switchHealthPage(){
    wx.miniProgram.switchTab({
        url: '/pages/health/health',
    });
}


/**
 * 保存建议
 */
function saveSuggest(){

    const doSave = () => {

        data.suggestInfo.resourceImages = getSuggestResourceImages.value.map(v=>{
            if('uploadPath' in v){
                return v.uploadPath
            }else{
                return v.url
            }
        }).filter(v=>v)

        saveHealthQuestionSuggest({
            id: null,
            memberHealthQuestionId: data.memberHealthQuestionInfo.id,
            resourceImages: data.suggestInfo.resourceImages.filter(v=>v).join(','),
            otherInfo: otherInfoNoteConfig.otherInfo,
        }).then(respData => {
            Toast.success({
                message: "保存成功",
                overlay: true,
                forbidClick: true,
                onClose(){
                    switchHealthPage()
                },
            })
        }).catch(e => {
            Toast.fail('保存失败');
        });
    }

    /*
        检测的代码
    */
    if(getSuggestResourceImages.value.some(v=>v.status === "uploading")){
        Toast("图片正在上传中，请稍后重新保存")
        return;
    }

    if(getSuggestResourceImages.value.some(v=>v.status === "failed")){
        Dialog.confirm({
            title: '警告',
            message: '检测到存在图片上传失败，是否继续保存？',
            confirmButtonText: '继续保存',
        })
        .then(() => {
            // on confirm
            doSave();
        })
        .catch(() => {
            // on cancel
        });
    }else{
        doSave();
    }

}


function loadMemberBaseInfo(){
    getMemberBaseInfoByHealthQuestionId(data.memberHealthQuestionInfo.id).then(respData => {
        data.memberBaseInfo = respData
    })
}

onMounted(()=>{

    const id = useRoute().query.id
    if(!id){
        Dialog.alert({
            title: '错误',
            message: '未传递有效唯一标识',
        }).then(() => {
        // on close
        });
        return;
    }

    void async function () {
        await getMemberHealthQuestion(id).then(respData => {
            respData._reportIds = respData.reportIds.split(',').filter(v=>v)

            respData._resourceImages = respData.resourceImages.split(',').filter(v=>v).map(v => ({url : v}) )

            respData._selectedItemJson = respData.selectedItemJson && JSON.parse(respData.selectedItemJson) || {}
            respData._selectedItemString = Object.values(respData._selectedItemJson).map(vs => vs.join('，')).join('；')

            data.memberHealthQuestionInfo = respData;

            params.memberId = respData.memberId
        })

        loadMemberBaseInfo()
    }()

    

    /**
     * 拿到报告Ids，去请求报告Ids的相关信息
     */
    watch(
        () => data.memberHealthQuestionInfo?._reportIds, 
        (newVal,oldVal) => {
            if(newVal){
                console.log("请求报告基本信息数据", newVal);
                // 获取心率图和指标项数据
                getReportBaseInfo(data.memberHealthQuestionInfo.reportIds).then((respData) => {
                    console.log("getReportBaseInfo", respData);
                    data.reportInfo.reportBaseInfos = respData
                    selectReportActionSheet.actions = respData.map(v=> ({name: v.reportDate}))
                    data.showReportBaseInfoById = respData[data.showReportBaseInfoActiveIndex]?.id || null
                })
            }
        },{
        immediate: true,
    })


    /**
     * 根据设置的显示报告Id的信息，进行查询报告的相关信息并进行展示。
     */
    watch(
        () => data.showReportBaseInfoById, 
        (newVal) => {
            if(newVal){
                console.log("请求展示的报告信息数据", newVal);
                if(newVal in data.cacheReportInfo){
                    console.log("命中报告信息缓存，更新显示数据");
                    let res = data.cacheReportInfo[newVal]
                    data.reportInfo.imgList = res.imgList;
                    data.reportInfo.codeList = res.codeList;
                    return;
                }

                // 获取心率图和指标项数据
                getImgCodetVersion7(newVal).then((res) => {
                    data.reportInfo.imgList = res?.imgList || [];
                    data.reportInfo.codeList = res?.codeList || [];

                    data.cacheReportInfo[newVal] = {
                        imgList: data.reportInfo.imgList,
                        codeList: data.reportInfo.codeList
                    }
                })
            }
        },{
        immediate: true,
    })

    /**
     * 更改显示的索引，进行切换要展示的数据
     */
    watch(()=>data.showReportBaseInfoActiveIndex,newVal => {
        console.log("watch showReportBaseInfoActiveIndex", newVal);
        if(data.reportInfo.reportBaseInfos && data.showReportBaseInfoById){
            data.showReportBaseInfoById = data.reportInfo.reportBaseInfos[newVal].id
            console.log("data.showReportBaseInfoById  ==>", data.showReportBaseInfoById);
        }
    })


})

const showRemarkUserNamePopupOperate = ref(false)
const remarkUserNamePopupOperateWrapper = ref(false)
const newRemarkName = ref('');

function closedRemarkUserName(){
    remarkUserNamePopupOperateWrapper.value = false
    newRemarkName.value = ''
}

function openRemarkUserName(){
    newRemarkName.value = data.memberBaseInfo?.remarkName || data.memberBaseInfo?.userName
    remarkUserNamePopupOperateWrapper.value = true
}

// 更新备注名称
function updateRemarkUserName(){

    const currentRemarkName = data.memberBaseInfo?.remarkName || data.memberBaseInfo?.userName

    if(currentRemarkName.trim() === newRemarkName.value.trim()){
        Notify({ type: 'warning', message: '备注名与用户名一致，无需更新备注' });
        return;
    }

    saveUsernameRemark(params.memberId, newRemarkName.value).then(respData => {
        showRemarkUserNamePopupOperate.value = false;
        Notify({ type: 'success', message: '备注名称成功' });
        // 刷新内容
        loadMemberBaseInfo();

        wx.miniProgram.postMessage({
            data: { 
                trigger: 'updateRemarkUserName',
                refresh: true
            }
        })
    }).catch(err => {
        Dialog.alert({
            message: '备注用户名失败，' + err.message || err.msg || err,
        }).then(() => {
        // on close
        });
    })
}

</script>

<template>


<div class="expert-answer">

    <!-- 备注用户名 -->
    <van-popup :close-on-click-overlay="false" round v-model:show="showRemarkUserNamePopupOperate"  position="top" :style="{ height: '20%' }" class="remark-user-name-wrapper" @open="openRemarkUserName" @closed="closedRemarkUserName" >
        
        <div style="display: flex;align-items: center;height: 100%;">
            <div style="width: 100%;">
                <van-cell-group inset class="van-hairline--top-bottom">
                    <van-field
                        label-width="5em"
                        size="large"
                        v-model="newRemarkName"
                        name="备注名称"
                        label="备注名称"
                        placeholder="设置专家预览时的成员备注名称"
                        :maxlength="15"
                        />
                </van-cell-group>
            </div>
        </div>
        <div class="operate-wrapper" :class="{ 'operate-wrapper--show': remarkUserNamePopupOperateWrapper }" >
            <van-row>
                <van-col span="8" offset="1">
                    <van-button block round type="default" @click="showRemarkUserNamePopupOperate=false">取消</van-button>
                </van-col>
                <van-col span="8" offset="6">
                    <van-button block round type="primary" @click="updateRemarkUserName">更新备注</van-button>
                </van-col>
            </van-row>
        </div>
    
    </van-popup>

    <!-- 报告日期选择 -->
    <van-action-sheet close-on-click-action v-model:show="selectReportActionSheet.show" :actions="selectReportActionSheet.actions" @select="selectReportActionSheet.onSelect" />

    <!-- 随手记的面板 -->
    <van-popup round :lock-scroll="false" :overlay="false" :close-on-click-overlay="false" v-model:show="otherInfoNoteConfig.show" position="bottom" :style="{ height: '30%',minHeight: '250px', boxShadow: '0 -4px 10px 1px #ececec' }">
        <div style="height: 100%;display: flex;flex-direction: column;">
            <header>
                <div style="overflow: hidden;text-align: center;">
                    <span style="margin-left: 3px;;font-size: 20px;line-height: 40px;">随手记</span>
                    <van-icon name="edit" color="#7761c6" size="22px" style="font-weight: bold;" />
                    <van-icon name="cross" color="#c8c9cc" size="26px" style="float: right;margin-top: 8px;margin-right: 10px;" @click="otherInfoNoteConfig.show = false" />
                </div>
            </header>
            <van-field
                class="other-info-note"
                style="height: 100%;"
                v-model="otherInfoNoteConfig.otherInfo"
                type="textarea"
                maxlength="1000"
                placeholder="在这里，您可以记录补充的额外说明信息"
                show-word-limit
            />
        </div>
    </van-popup>


    <div class="main-header" v-if="data.memberBaseInfo">
        <van-row class="base-info">
            <van-col span="5">
                <van-image
                    round
                    width="50px"
                    height="50px"
                    fit="cover"
                    :src="data.memberBaseInfo?.avatar"
                />
            </van-col>
            <van-col span="14">
                <div class="vertical-center">
                    <div style="display: inline;" @click="showRemarkUserNamePopupOperate = true">
                        <span class="username" v-text="data.memberBaseInfo?.remarkName || data.memberBaseInfo?.userName"></span>
                        <van-icon name="edit" />
                    </div>

                    <span style="width: 10px;"></span>
                    <span class="sex" v-text="data.memberBaseInfo?.sex"></span>
                </div>
            </van-col>
            <van-col span="3">
                <div class="vertical-center" v-if="data.memberBaseInfo?.isVip == 1">
                    <van-image
                        width="100%"
                        height="auto"
                        style="aspect-ratio: 384/128;"
                        fit="cover"
                        :src="ImageMemberVip"
                    />
                </div>
            </van-col>
        </van-row>
        
        <van-row class="base-info">
            <van-col span="8">
                <div class="f-fd_c-ta_c base-info__prop-wrapper" >
                    <span>年龄</span>
                    <span v-text="data.memberBaseInfo?.age"></span>
                </div>
            </van-col>
            <van-col span="8">
                <div class="f-fd_c-ta_c base-info__prop-wrapper" >
                    <span>身高 cm</span>
                    <span v-text="data.memberBaseInfo?.height"></span>
                </div>
            </van-col>
            <van-col span="8">
                <div class="f-fd_c-ta_c base-info__prop-wrapper" >
                    <span>体重 kg</span>
                    <span v-text="data.memberBaseInfo?.weight"></span>
                </div>
            </van-col>
        </van-row>
    </div>

    <van-divider />


    <!-- 成员反馈的内容展示 -->
    <div class="main-body">

        <template v-if="data.memberHealthQuestionInfo" >

            <div class="info-item health-questionnaire" v-if="data.memberHealthQuestionInfo._selectedItemString">
                <div class="header-title">健康问卷</div>
                <div inset class="van-hairline--surround content-wrapper " >
                    <div class="content-area">
                        <div class="content" v-text="data.memberHealthQuestionInfo._selectedItemString">
                        </div>
                        <span class="submit-time" v-text="data.memberHealthQuestionInfo.submitTime"> 
                        </span>
                    </div>
                </div>
            </div>
            <div class="info-item" v-if="data.memberHealthQuestionInfo._resourceImages.length > 0">
                <div class="header-title">舌苔或其他资料（点击看大图）</div>
                <div inset style="padding: 10px;margin: 10px;border-radius: 3px;">
                    <van-uploader v-model="data.memberHealthQuestionInfo._resourceImages" 
                                accept="image/*" 
                                :show-upload="false"
                                :deletable="false"
                                image-fit="cover"
                                preview-size="95.33px"
                             />
                </div>
            </div>
            <div class="info-item" v-if="data.memberHealthQuestionInfo.otherInfo">
                <div class="header-title">用户自我反馈</div>
                <div inset class="van-hairline--surround" style="padding: 10px;margin: 10px;border-radius: 3px;">
                    <div class="content" v-text="data.memberHealthQuestionInfo.otherInfo">
                    </div>
                </div>
            </div>
        </template>


        <div class="info-item">
            <div class="header-title">近期测评报告</div>
            <div inset class="report-body-area">
                
                <template v-if="data.reportInfo.reportBaseInfos.length > 0">

                    <van-row>
                        <van-col span="4">
                            <van-button :disabled="data.showReportBaseInfoActiveIndex === data.reportInfo.reportBaseInfos.length - 1" icon="arrow-left" type="primary" block @click="data.showReportBaseInfoActiveIndex++" />
                        </van-col>
                        <van-col span="16" class="van-hairline--top van-hairline--bottom">
                            <van-field
                                v-model="getShowReportBaseInfo.reportDate"
                                readonly
                                right-icon="arrow-down"
                                placeholder="请选择报告"
                                @click="selectReportActionSheet.show = true"
                                class="show-report-date-wrapper"
                            />
                        </van-col>
                        <van-col span="4">
                            <van-button :disabled="data.showReportBaseInfoActiveIndex === 0" icon="arrow" type="primary" block @click="data.showReportBaseInfoActiveIndex--" />
                        </van-col>
                    </van-row>
    
                    <!-- 报告信息 -->
                    <section class="report-body-wrapper">

                        <ImgCodeVersion7 v-if="data.reportInfo.imgList.length > 0" :imgList="data.reportInfo.imgList" :codeList="data.reportInfo.codeList" :styleView="1"></ImgCodeVersion7>
                        <van-empty
                            v-else
                            :image="require('@/assets/img/empty-data.png')"
                            image-size="80"
                            :description="`未查到当前报告信息(${data.showReportBaseInfoById})！`"
                        />

                    </section>

                </template>

                <van-empty
                    v-else
                    :image="require('@/assets/img/empty-data.png')"
                    image-size="80"
                    description="还未生成过报告噢~"
                />


            </div>
        </div>

        <div class="info-item">
            <div class="header-title">建议及注意事项</div>
            <div inset style="padding: 10px;margin: 10px;border-radius: 3px;">
                <van-uploader v-model="getSuggestResourceImages" 
                    accept="image/*" 
                    :show-upload="true" 
                    preview-size="95.33px"
                    :max-count="3" 
                    :max-size="20 * 1024 * 1024"
                    upload-text="上传图片" 
                    image-fit="cover"
                    upload-icon="plus"
                    @oversize="suggestReourceImageOversize"
                    :after-read="uploadSuggestResourceImage"
                />
            </div>
        </div>


        <van-row class="button-round-radius">
            <van-col span="11" offset="1">
                <van-button type="primary" color="#7453d5" block round @click="saveSuggest" >提交</van-button>
            </van-col>
            <van-col span="10" offset="1">
                <van-button type="primary" color="#008080" block round @click="useNavigateTo('/pages/healthQuestionnaire/consultationRecord/consultationRecord', { memberId: params.memberId, excludeBtns: ['ConsultationRecord'] })" >查看过往</van-button>
            </van-col>
        </van-row>


    </div>

</div>

<div style="position: fixed;bottom: 95px;right: 30px;">
    <van-button icon="notes-o" type="default" style="width: var(--van-button-default-height);--van-button-icon-size: 1.8em;" round @click="otherInfoNoteConfig.show = true" />
</div>


</template>


<style scoped lang="stylus">

.expert-answer {
    min-height: 100vh;
    margin: 25px;
    text-align: left;

    &:after {
            content: '';
            display: block;
            height: 30px;
    }


    >>> .remark-user-name-wrapper {
        
        overflow-y: visible;

        .operate-wrapper {
            position: relative;
            top: -25px;
            opacity: 0;
            transition: top 0.3s ease, opacity 0.4s ease-in;

            &--show {
                top: 25px;
                opacity: 1;
            }
        }
    }

}

.vertical-center {
    display: flex;align-items: center;height: 100%;
}

.f-fd_c-ta_c {
    display: flex;flex-direction: column;text-align: center;
}

.main-header {
    margin: 10px;

    .base-info {
        margin: 50px 10px 20px;


        .username {
            max-width: calc(100% - 30px);
            overflow: hidden;
            white-space: wrap;
            text-overflow: ellipsis;
        }

        .sex {
            color: rgb(51, 51, 51);
        }

    }
    
    .base-info__prop-wrapper {
        & > span:first-of-type {
            margin-bottom: 15px;
            font-size: 24px;
        }
    }


}


.main-body {

    .header-title {
        position: relative;
        padding-left: 26px;
        color: #333;

        &:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 100%;
            max-height: 42px;
            position: absolute;
            background-color: #7453d5;
            left: 0;
            top: 0;
        }
    }

    .info-item {
        margin: 45px 0 15px;

        .content {
            font-size: 28px;
            line-height: 56px;
        }

    }

    .content-wrapper {
        padding: 10px;margin: 10px;border-radius: 3px;
    }

    .health-questionnaire {

        .content-area {
            margin: 10px;
            padding-bottom: 45px;
            position: relative;
        }

        .submit-time {    
            position: absolute;
            bottom: 0px;
            right: 0;
            font-style: italic;
        }

    }


    .report-body-area {
        padding: 20px;margin: 20px;border-radius: 6px;


        .show-report-date-wrapper >>> .van-field__control:read-only {
            text-align: center;
        }
    }

    .report-body-wrapper {
        margin: 0 -65px;
    }


}

.button-round-radius {
    --van-button-round-border-radius: 16px;

    margin: 20px 0;
}


.other-info-note {
    height: 100%;

    & >>> .van-field__body {
        height: 90%;

        & .van-field__control {
            height: 100% !important;
        }
    }
}

</style>