import request from "@/utils/request";

/**
 * 查询当前登录人信息
 * @param memberId
 * @returns {*}
 */
 export function getMySelfInfo() {
  return request.get("/api/member/findMySelf", {}, {
    login: true
  });
}

/**
 * 保存当前登录人信息
 * @param memberId
 * @returns {*}
 */
 export function improveUserInfo(data: JSON) {
  return request.post("/api/member/improveUserInfo", data, {
    login: true
  });
}

/**
 * 顾问
 * @param 
 * @returns {*}
 */
 export function consultant() {
  return request.get("/api/tenant/consultant", {}, {
    login: true
  });
}
