import request from "@/utils/request";


export function uploadAvatarImage(file) {
    let param = new FormData(); //创建form对象
    param.append('file',file)
    return request.post("/api/file/upload/relative/avatar", param);
}


export function uploadImage(type, file) {
    let param = new FormData(); //创建form对象
    param.append('file',file)
    return request.post("/api/file/upload/relative/" + type, param);
}