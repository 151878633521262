import { createStore } from 'vuex'

export default createStore({
  state: {
    dataTypeList: [
      {type: '1',name:'精神测评',healthBackgroundImgUrl: require('@/assets/img/icon/精神状态.png')},
      {type: '2',name:'睡眠测评',healthBackgroundImgUrl: require('@/assets/img/icon/睡眠状态呢.png')},
      {type: '3',name:'生命节律',healthBackgroundImgUrl: require('@/assets/img/icon/生命力.png')},
      {type: '4',name:'心血管功能',healthBackgroundImgUrl: require('@/assets/img/icon/心血管功能.png')},
      {type: '5',name:'心理状态',healthBackgroundImgUrl: require('@/assets/img/icon/心理状态.png')},
      {type: '6',name:'生命活力',healthBackgroundImgUrl: require('@/assets/img/icon/生命力.png')},
      {type: '7',name:'寒热状态',healthBackgroundImgUrl: require('@/assets/img/icon/寒热状态.png')},
      {type: '8',name:'心血管病',healthBackgroundImgUrl: require('@/assets/img/icon/心血管功能.png')},
      {type: '9',name:'脑血管功能',healthBackgroundImgUrl: require('@/assets/img/icon/脑血管功能.png')},
      {type: '10',name:'老年性痴呆',healthBackgroundImgUrl: require('@/assets/img/icon/老年痴呆.png')},
      // {type: '11',name:'失眠',healthBackgroundImgUrl: require('@/assets/img/icon/失眠.png')},
      {type: '12',name:'糖尿病',healthBackgroundImgUrl: require('@/assets/img/icon/糖尿病.png')},
    ],
    cachedHealthTypeList: [],

    // 健康问卷字典项
    questionnaireList: [
      {
        title: '失眠相关症状',
        appendTitle: '（可多选）',
        name: 'insomniaSymptoms',
        selectItems: [
          {label: '难以入睡',value: '难以入睡',disabled: false,},
          {label: '睡中易醒，醒后难以入睡',value: '睡中易醒，醒后难以入睡',disabled: false,},
          {label: '早醒',value: '早醒',disabled: false,},
          {label: '睡眠轻浅',value: '睡眠轻浅',disabled: false,},
          {label: '多梦',value: '多梦',disabled: false,},
          {label: '精神不振',value: '精神不振',disabled: false,},
          {label: '其他',value: '其他',disabled: false,},
        ],
      },
      {
        title: '心血管相关症状',
        appendTitle: '（可多选）',
        name: 'cardiovascularSymptoms',
        selectItems: [
          {label: '心慌',value: '心慌',disabled: false,},
          {label: '心前区刺痛（心口疼）',value: '心前区刺痛（心口疼）',disabled: false,},
          {label: '胸闷',value: '胸闷',disabled: false,},
          {label: '体倦乏力',value: '体倦乏力',disabled: false,},
          {label: '畏寒怕冷',value: '畏寒怕冷',disabled: false,},
          {label: '不易出汗',value: '不易出汗',disabled: false,},
          {label: '精神不振',value: '精神不振',disabled: false,},
          {label: '其他',value: '其他',disabled: false,},
        ],
      },
    ],
    // 用户健康问卷字典项
    userQuestionnaireList: [
      {
        title: '既往病史',
        appendTitle: '（可多选）',
        name: 'jwbs',
        selectItems: [
          {
            label: '心血管',value: '心血管',disabled: false,
            selectedShowChild: false,
            childs: [
              {
                enabled: true,
                title: '心血管相关症状',
                appendTitle: '（可多选）',
                name: 'cardiovascularSymptoms',
                selectItems: [
                  {label: '心慌',value: '心慌',disabled: false,},
                  {label: '心前区刺痛（心口疼）',value: '心前区刺痛（心口疼）',disabled: false,},
                  {label: '胸闷',value: '胸闷',disabled: false,},
                  {label: '体倦乏力',value: '体倦乏力',disabled: false,},
                  {label: '畏寒怕冷',value: '畏寒怕冷',disabled: false,},
                  {label: '不易出汗',value: '不易出汗',disabled: false,},
                  {label: '精神不振',value: '精神不振',disabled: false,},
                ],
              },
            ]
          },
          {label: '高血压',value: '高血压',disabled: false,},
          {label: '糖尿病',value: '糖尿病',disabled: false,},
          {label: '呼吸疾病',value: '呼吸疾病',disabled: false,},
          {label: '消化疾病',value: '消化疾病',disabled: false,},
          {label: '冠心病',value: '冠心病',disabled: false,},
          {label: '老年痴呆',value: '老年痴呆',disabled: false,},
          {label: '中风',value: '中风',disabled: false,},
          {label: '肾病',value: '肾病',disabled: false,},
          {label: '以上全无',value: '以上全无',disabled: false,},
        ],
      },
      {
        title: '当前症状',
        appendTitle: '（可多选）',
        name: 'dqzz',
        selectItems: [
          {
            label: '失眠',value: '失眠',disabled: false,
            selectedShowChild: false,
            childs: [
              {
                enabled: true,
                title: '失眠相关症状',
                appendTitle: '（可多选）',
                name: 'smxgzz',
                selectItems: [
                  {label: '难以入睡',value: '难以入睡',disabled: false,},
                  {label: '睡中易醒，醒后难以入睡',value: '睡中易醒，醒后难以入睡',disabled: false,},
                  {label: '早醒',value: '早醒',disabled: false,},
                  {label: '睡眠清浅',value: '睡眠清浅',disabled: false,},
                  {label: '多梦',value: '多梦',disabled: false,},
                  {label: '精神不振',value: '精神不振',disabled: false,},
                ],
              },
            ]
          },
          {label: '眩晕',value: '眩晕',disabled: false,},
          {label: '乏力',value: '乏力',disabled: false,},
          {label: '焦虑抑郁',value: '焦虑抑郁',disabled: false,},
          {label: '以上全无',value: '以上全无',disabled: false,},
        ],
      },
    ],
  },
  mutations: {
    addHealthTypeList(state,list) {
      state.cachedHealthTypeList  = list
    }
  },
  actions: {
  },
  modules: {
  }
})
